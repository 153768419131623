import React from 'react';
import { Page, Text, View, Link, Document, StyleSheet, Image,Font, PDFDownloadLink, PDFViewer, usePDF, pdf } from '@react-pdf/renderer'
import benefi from '../../assets/images/ri_1.png'
import finanImg from '../../assets/images/ri_2.png'
import inssImg from '../../assets/images/ri_5.png'
import qrAutentica from '../../assets/images/ri_14.jpeg'
import logoMConsulta from '../../assets/images/logo-mconsulta250x250.png'
//import {responsePdf} from './JsonExtrato'
import fontHelvetica from '../../assets/fonte/helvetica-bold.otf'
import { columnsEmprestimos, columnsCartoes } from './ColumsEmprestimosCartoes';
import { css } from './css';

Font.register({
  family: 'Helvetica',
  src: fontHelvetica,
});


const styles = StyleSheet.create(css)


export const ExtratoOfflinePdf = async (data) => {

  console.log(data)
  const responsePdf = data;
  let widthEmprestimo = 0;

  columnsEmprestimos.forEach((i)=>{
    widthEmprestimo += +i.width.replace('in','')    
  }) 
  
 

  const PdfDataTags = (
   <Document>  
      <Page size='A3' orientation='portrait' style={styles.page}>
          
          {/*<View style={styles.viewTopPdf} fixed>          
            <Text style={styles.topPdf}>Instituto Nacional do Seguro Social</Text>        
            </View>*/}
     
          <View style={styles.bodyPdf}>

          <View style={styles.title}>
            <Image style={{display:'flex', width:'10%', marginRight:'10px', alt:"Logo M-Consultas"}} src={logoMConsulta}/> 
            <Text style={styles.titleH3}>EXTRATO OFFLINE</Text>           
          </View>

          <View style={styles.lineWidth} />
            
          <View style={styles.boxInfo}>
          
              {/*Beneficiario*/}
              <View style={styles.bodyBoxInfo}>

                  <View style={styles.headBoxInfo}>
                    <View>
                      <Image style={{display:'flex', width:'0.56in',height:'0.56in', marginRight:'10px'}} src={benefi}/>                                   
                    </View> 
                    <View>
                      <Text >Dados Pessoais</Text>
                    </View>
                  </View>

                {/** Benefício */}
                <View style={styles.containerBody}>
                <View style={styles.boxInfoBeneficio}>
                    <View style={styles.columnInfoBenef1}>
                      <Text style={styles.fontSizeBoxInfo}>N° Benefício: {responsePdf.Beneficiario.Beneficio}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Nome: {responsePdf.Beneficiario.Nome}</Text>
                      <Text style={styles.fontSizeBoxInfo}>CPF: {responsePdf.Beneficiario.Cpf} </Text>
                      <Text style={styles.fontSizeBoxInfo}>Data de Nascimento: {responsePdf.Beneficiario.DataNascimento}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Idade: {responsePdf.Beneficiario?.Idade} </Text>
                      <Text style={styles.fontSizeBoxInfo}>UF: {responsePdf.Beneficiario?.UF} </Text>                      
                      <Text style={styles.fontSizeBoxInfo}>Situação: {responsePdf.Beneficiario.Situacao}</Text>
                      <Text style={styles.fontSizeBoxInfo}>DIB: {responsePdf.Beneficiario.DIB}</Text>
                      <Text style={styles.fontSizeBoxInfo}>DDB: {responsePdf.Beneficiario.DDB}</Text>
       
                    </View>

                    <View style={styles.columnInfoBenef2}>
                      <Text style={styles.fontSizeBoxInfo}>{ responsePdf.Beneficiario.PossuiProcurador ? "Possui procurador":"Não Possui Procurador"}</Text>
                      <Text style={styles.fontSizeBoxInfo}>{ responsePdf.Beneficiario.RepresentanteLegal ? "Possui Representante legal":"Não Possui Representante legal" } </Text>
                      <Text style={styles.fontSizeBoxInfo}>{ responsePdf.Beneficiario.PensaoAlimenticia ? "E Pensão alimenticía":"Não e pensão alimenticía" } </Text>
                      <Text style={styles.fontSizeBoxInfo}>{ responsePdf.Beneficiario.BloqueioEmprestimo ? "Bloqueado para emprestimo":"Não bloqueado para emprestimo"} </Text>
                      <Text style={styles.fontSizeBoxInfo}>{ responsePdf.Beneficiario?.ElegivelEmprestimo ? "Elegível para emprestimo":"Não elegível para emprestimo"} </Text>
                    </View>
                  </View>                   
                </View>
                                

              </View>    
              
              {/*Representante Legal*/}
              <View style={styles.bodyBoxInfo}>

                  <View style={styles.headBoxInfo}>
                    <View>
                      <Image style={{display:'flex', width:'0.56in',height:'0.56in', marginRight:'10px'}} src={benefi}/>                                   
                    </View> 
                    <View>
                      <Text >Dados Representante Legal</Text>
                    </View>
                  </View>

                {/** Benefício */}
                <View style={styles.containerBody}>
                <View style={styles.boxInfoBeneficio}>
                    <View style={styles.columnInfoBenef1}>                      
                      <Text style={styles.fontSizeBoxInfo}>Nome: {responsePdf.DadosRepresentante.Nome}</Text>
                      <Text style={styles.fontSizeBoxInfo}>CPF: {responsePdf.DadosRepresentante.CPF} </Text>
                      
                    </View>
                    
                  </View>                   
                </View>
                                

              </View>                      

              {/*Dados Bancarios*/}
              <View style={styles.bodyBoxInfo} >

                <View style={styles.headBoxInfo}>
                      <View>
                        <Image style={{display:'flex', width:'0.56in',height:'0.56in', marginRight:'10px'}} src={finanImg}/>                                   
                      </View> 
                      <View>
                        <Text>DADOS BANCÁRIOS</Text>
                      </View>
                    </View>

                  <View style={styles.containerBody}>
                      <View style={styles.boxInfoBeneficio}>
                      <View style={styles.columnInfoBenef1}>
                        <Text style={styles.fontSizeBoxInfo}>Número Agência: {responsePdf.DadosBancarios.Agencia}</Text>
                        <Text style={styles.fontSizeBoxInfo}>Nome Agência: {responsePdf.DadosBancarios.Banco}</Text>
                        <Text style={styles.fontSizeBoxInfo}>Número Conta Corrente: {responsePdf.DadosBancarios.ContaPagto}</Text>
                        <Text style={styles.fontSizeBoxInfo}>Meio Pagamento: {responsePdf.DadosBancarios.DescMeioPagamento}</Text>
                        <Text style={styles.fontSizeBoxInfo}>UF Conta: {responsePdf.DadosBancarios?.UF}</Text>
                      </View>

                      </View>
                  </View>
              </View>

              {/*Resumo Financeiro*/}
              <View style={styles.bodyBoxInfo} >

                <View style={styles.headBoxInfo}>
                    <View>
                      <Image style={{display:'flex', width:'0.56in',height:'0.56in', marginRight:'10px'}} src={finanImg}/>                                   
                    </View> 
                    <View>
                      <Text>RESUMO FINANCEIRO</Text>
                    </View>
                </View>

                <View style={styles.containerBody}>
                    <View style={styles.boxInfoBeneficio}>
                    <View style={styles.columnInfoBenef1}>
                      <Text style={styles.fontSizeBoxInfo}>Margem disponivel para emprestimo: {responsePdf.ResumoFinanceiro.MargemDisponivelEmprestimo}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Margem disponivel Rcc: {responsePdf.ResumoFinanceiro.MargemDisponivelRcc}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Margem disponivel Rmc: {responsePdf.ResumoFinanceiro.MargemDisponivelRmc}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Possui Cartão: {responsePdf.ResumoFinanceiro.PossuiCartao}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Salário base: {responsePdf.ResumoFinanceiro.SalarioBase}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Salário bruto: {responsePdf.ResumoFinanceiro.SalarioBruto}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Total contrato: {responsePdf.ResumoFinanceiro.TotalContrato}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Total Emprestimos: {responsePdf.ResumoFinanceiro.TotalEmprestimos}</Text>
                    
                    </View>

                    </View>
                </View>
              </View> 
            
              {/*Emprestimos*/}
              <View style={styles.bodyBoxInfo} >

                <View style={styles.headBoxInfo}>
                    <View>
                      <Image style={{display:'flex', width:'0.56in',height:'0.56in', marginRight:'10px'}} src={finanImg}/>                                   
                    </View> 
                    <View>
                      <Text>Emprestimos</Text>
                    </View>
                </View>

                <View style={ [styles.table, {width: widthEmprestimo+"in", } ]}>

                  <View style={styles.containerBody}>

                      <View style={styles.boxInfoBeneficio}>

                            <View style={[styles.tableCol, {width:columnsEmprestimos[0].width,} ]}>                  
                              <Text style={[styles.tableCell,{fontSize:'6pt', }]}>Contrato/Banco</Text>                  
                            </View>

                            <View style={[styles.tableCol, {width:columnsEmprestimos[1].width,} ]}>                  
                              <Text style={[styles.tableCell,{fontSize:'6pt', }]}>Desc. Empr.</Text>                  
                            </View>

                            <View style={[styles.tableCol, {width:columnsEmprestimos[2].width,} ]}>                  
                              <Text style={[styles.tableCell,{fontSize:'6pt', }]}>Parcela</Text>                  
                            </View>

                            <View style={[styles.tableCol, {width:columnsEmprestimos[3].width,} ]}>                  
                              <Text style={[styles.tableCell,{fontSize:'6pt', }]}>Pagas</Text>                  
                            </View>

                            <View style={[styles.tableCol, {width:columnsEmprestimos[4].width,} ]}>                  
                              <Text style={[styles.tableCell,{fontSize:'6pt', }]}>Prazo</Text>                  
                            </View>

                            <View style={[styles.tableCol, {width:columnsEmprestimos[5].width,} ]}>                  
                              <Text style={[styles.tableCell,{fontSize:'6pt', }]}>Inclusão</Text>                  
                            </View>

                            <View style={[styles.tableCol, {width:columnsEmprestimos[6].width,} ]}>                  
                              <Text style={[styles.tableCell,{fontSize:'6pt', }]}>Início desconto</Text>                  
                            </View>

                            <View style={[styles.tableCol, {width:columnsEmprestimos[7].width,} ]}>                  
                              <Text style={[styles.tableCell,{fontSize:'6pt', }]}>Fim desconto</Text>                  
                            </View>

                            <View style={[styles.tableCol, {width:columnsEmprestimos[8].width,} ]}>                  
                              <Text style={[styles.tableCell,{fontSize:'6pt', }]}>Empréstimo</Text>                  
                            </View>


                            <View style={[styles.tableCol, {width:columnsEmprestimos[9].width,} ]}>                  
                              <Text style={[styles.tableCell,{fontSize:'6pt', }]}>Saldo</Text>                  
                            </View>

                            <View style={[styles.tableCol, {width:columnsEmprestimos[10].width,} ]}>                  
                              <Text style={[styles.tableCell,{fontSize:'6pt', }]}>Taxa</Text>                  
                            </View>

                      </View>


                      { responsePdf.Emprestimos.map((emprestimo,index) => (
                            <View style={[styles.tableRow,  ]} key={index}> 

                              <View style={[styles.tableCol, {width:columnsEmprestimos[0].width, display:"flex", flexWrap:"wrap"} ]}>
                                <Text style={[styles.tableCell,{fontSize:'6pt', padding:"4px" }]}>{emprestimo?.Contrato}</Text> 
                                <Text style={[styles.tableCell,{fontSize:'6pt', padding:"4px" }]}>{emprestimo?.NomeBanco}</Text>                                 
                              </View>                                
                              <View style={[styles.tableCol, {width:columnsEmprestimos[1].width, display:"flex", flexWrap:"wrap"} ]}>
                                <Text style={[styles.tableCell,{fontSize:'6pt', padding:"4px" }]}>{emprestimo?.DescricaoTipoEmprestimo}</Text>  
                              </View> 
                              <View style={[styles.tableCol, {width:columnsEmprestimos[2].width, display:"flex", flexWrap:"wrap"} ]}>
                                <Text style={[styles.tableCell,{fontSize:'6pt', padding:"4px" }]}>{emprestimo?.ValorParcela}</Text>  
                              </View>  
                              <View style={[styles.tableCol, {width:columnsEmprestimos[3].width, display:"flex", flexWrap:"wrap"} ]}>
                                <Text style={[styles.tableCell,{fontSize:'6pt', padding:"4px" }]}>{emprestimo?.ParcelasPagas}</Text>  
                              </View> 
                              <View style={[styles.tableCol, {width:columnsEmprestimos[4].width, display:"flex", flexWrap:"wrap"} ]}>
                                <Text style={[styles.tableCell,{fontSize:'6pt', padding:"4px" }]}>{emprestimo?.Prazo}</Text>  
                              </View> 
                              <View style={[styles.tableCol, {width:columnsEmprestimos[5].width, display:"flex", flexWrap:"wrap"} ]}>
                                <Text style={[styles.tableCell,{fontSize:'6pt', padding:"4px" }]}>{emprestimo?.DataAverbacao}</Text>  
                              </View> 
                              <View style={[styles.tableCol, {width:columnsEmprestimos[6].width, display:"flex", flexWrap:"wrap"} ]}>
                                <Text style={[styles.tableCell,{fontSize:'6pt', padding:"4px" }]}>{emprestimo?.InicioDesconto}</Text>  
                              </View> 
                              <View style={[styles.tableCol, {width:columnsEmprestimos[7].width, display:"flex", flexWrap:"wrap"} ]}>
                                <Text style={[styles.tableCell,{fontSize:'6pt', padding:"4px" }]}>{emprestimo?.FinalDesconto}</Text>  
                              </View> 
                              <View style={[styles.tableCol, {width:columnsEmprestimos[8].width, display:"flex", flexWrap:"wrap"} ]}>
                                <Text style={[styles.tableCell,{fontSize:'6pt', padding:"4px" }]}>{emprestimo?.ValorEmprestimo}</Text>  
                              </View> 
                              <View style={[styles.tableCol, {width:columnsEmprestimos[9].width, display:"flex", flexWrap:"wrap"} ]}>
                                <Text style={[styles.tableCell,{fontSize:'6pt', padding:"4px" }]}>{emprestimo?.Saldo}</Text>  
                              </View> 
                              <View style={[styles.tableCol, {width:columnsEmprestimos[10].width, display:"flex", flexWrap:"wrap"} ]}>
                                <Text style={[styles.tableCell,{fontSize:'6pt', padding:"4px" }]}>{emprestimo?.Taxa}</Text>  
                              </View> 

                            </View >
                    ))
                  }
                  </View>

                </View>
              </View> 
                  

                  
          </View>
          
          </View>

      


          {/**Rodapé */}
          <View style={styles.footer} fixed>
      
            <View style={styles.lineWidth} />

              <View style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignContent:'center',}}>
                  
                  <View style={{display:'flex', flexDirection:'row',alignItems:'center'}}>                   

                  </View> 

                  <View style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                    <View>                    
                      
                      <Text style={{fontSize:'10pt', marginTop:'10px'}}>{new Date().toLocaleString() }</Text>

                    </View>

                  </View> 
               
            </View>

            
                
          </View>
          
      </Page> 
  </Document> 
  
  ) 


  const blob =  await pdf(PdfDataTags).toBlob()

  const url = window.URL.createObjectURL(blob)
  window.open(url, '_blank')
};


