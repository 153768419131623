import React from "react"
import styles from "./Paginacao.module.css"

const Paginacao = ({ pagination, onPageChange }) => {
  const { currentPage, totalPages, totalItems, itemsPerPage } = pagination

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1)
    }
  }

  const renderPageNumbers = () => {
    const pageNumbers = []
    const maxPageButtons = itemsPerPage
    let startPage =
      Math.floor((currentPage - 1) / maxPageButtons) * maxPageButtons + 1
    let endPage = Math.min(startPage + maxPageButtons - 1, totalPages)

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => onPageChange(i)}
          className={currentPage === i ? styles.active : ""}
        >
          {i}
        </button>,
      )
    }
    return pageNumbers
  }

  return (
    <div className={styles.paginacao}>
      <button onClick={handlePreviousPage} disabled={currentPage === 1}>
        Anterior
      </button>

      {renderPageNumbers()}
      <button onClick={handleNextPage} disabled={currentPage === totalPages}>
        Próximo
      </button>
      <div className={styles.info}>
        Página {currentPage} de {totalPages} - Total de itens: {totalItems}
      </div>
    </div>
  )
}

export default Paginacao
