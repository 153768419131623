import pdfMaker from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import { useEffect, useState } from "react"
import { Buttons } from "../../components/Button"
import { InputSelect, InputText } from "../../components/Inputs"
import { Loading } from "../../components/Loading"
import { ApiConsultaOffline } from "../../functions/consulta_offline"
import {
  getBeneficios,
  postBeneficio,
  revisar,
} from "../../functions/desbloqueio_solicitar"
import { getListSituacoes } from "../../functions/situacoes"
import styles from "./DesbloqueioSolicitar.module.css"

import comprovantePDF from "../../Reports/Comprovante/comprovante"
import { Alert, AlertDialog } from "../../components/AlertDialog"
import { FormModal } from "../../components/FormModal"
import Paginacao from "../../components/Paginacao"
import { IAlert } from "../../interfaces/Alert"
import { IDesbloqueio } from "../../interfaces/Desbloqueio"
const { Protocolo } = require("../../utils/Protocolo")
const { FormatDateTime } = require("../../utils/date")

pdfMaker.vfs = pdfFonts.pdfMake.vfs

export const DesbloqueioSolicitar = () => {
  const [formoffline, setFormOffline] = useState(false)
  const [number, setNumber] = useState(1)
  const [visibled, setVisibled] = useState(false)
  const [onModalUnlock, setOnModalUnlock] = useState(false)

  const [listBeneficios, setListBeneficios] = useState([])
  const [beneficio, setBeneficio] = useState({})
  const [situacoes, setSituacoes] = useState([])
  const [erro, setErro] = useState({ visibled: false, message: "" })
  const [onAlert, setOnAlert] = useState(IAlert)

  const [search, setSearch] = useState("")

  const [onAlertDialog, setOnAlertDialog] = useState({
    visibled: false,
    message: "",
  })

  const [comprovante, setComprovante] = useState(IDesbloqueio)
  const [currentPage, setCurrentPage] = useState(1)
  const [showLoading, setShowLoading] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 10,
  })

  useEffect(() => {
    ;(async () => {
      setVisibled(true)
      await getBeneficios(currentPage, search)
        .then(res => {
          if (res.data.erro) {
            setVisibled(false)
            return setOnAlert({
              visibled: true,
              message: res.data.message,
            })
          }

          setListBeneficios(res.data.items)
          setPagination(res.data.pagination)
          setVisibled(false)
        })
        .catch(error => {
          setVisibled(false)
          console.log(error)
          setOnAlert({
            visibled: true,
            message: error.message,
          })
        })
    })()
  }, [currentPage, number, search])

  useEffect(() => {
    ;(async () => {
      await getListSituacoes(2)
        .then(sit => {
          setSituacoes(sit?.data.situacao)
        })
        .catch(error => {
          console.error(error)
        })
    })()
  }, [])

  const handlePageChange = newPage => {
    setVisibled(true)
    setCurrentPage(newPage)
  }

  const postDados = async desbloqueio => {
    try {
      await postBeneficio(desbloqueio)

      setComprovante(IDesbloqueio)
      setVisibled(false)
      setNumber(number + 1)
    } catch (error) {
      setComprovante({ beneficio: "" })
      setVisibled(false)
      setOnAlert({
        visibled: true,
        message: error.response?.data.message,
      })
    }
  }

  const handleSumitOff = e => {
    e.preventDefault()
    setVisibled(true)
    comprovante.datahora = FormatDateTime(new Date(), "yyyy-mm-dd hh:mm:ss")
    comprovante.protocolo = Protocolo()
    postDados(comprovante)
    setFormOffline(false)
    setComprovante({})
  }

  const handleChange = e => {
    setComprovante({ ...comprovante, [e.target.name]: e.target.value })
  }

  const handleChangeBeneficio = e => {
    setBeneficio({ ...beneficio, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setVisibled(true)

    ApiConsultaOffline({ origem: 2, beneficio: comprovante.beneficio })
      .then(res => {
        comprovante.requerente = res.data[0].Beneficiario.Nome
        comprovante.cpf = res.data[0].Beneficiario.CPF
        comprovante.ddb = res.data[0].Beneficiario.DDB
        comprovante.datahora = FormatDateTime(new Date(), "yyyy-mm-dd hh:mm:ss")
        comprovante.protocolo = Protocolo()

        postDados(comprovante)
      })
      .catch(error => {
        console.error(error)
        setVisibled(false)
        if (error.code === "ERR_NETWORK") {
          return setOnAlert({
            visibled: true,
            message: error.message,
          })
        }

        setVisibled(false)

        if (Number(error.response?.status) === 401) {
          setOnAlert({
            visibled: true,
            message: error.response?.data.message,
          })
        } else {
          setOnAlertDialog({
            visibled: true,
            message: `${error.response?.data.message}\n\n\n\n Deseja inserir manualmente? `,
          })
        }
      })
  }

  const visualizarImpressao = b => {
    comprovantePDF(b)
  }

  const handlePrintPdf = nb => {
    visualizarImpressao(nb)
  }

  const handleAlertDialog = d => {
    setOnAlertDialog({ visibled: false, message: "" })
    setFormOffline(d)

    if (!d) {
      setComprovante({ beneficio: "" })
    }
  }

  const handleEdit = bn => {
    setOnModalUnlock(true)
    setBeneficio(bn)
  }

  const putRevisar = async () => {
    try {
      await revisar(beneficio)
      setNumber(number + 1)
      setOnModalUnlock(false)
    } catch (error) {
      setOnAlert({
        visibled: true,
        message: error.response?.data.message,
      })
    }
  }

  return (
    <div className={styles.pppcontainerBeneficio}>
      {onAlert.visibled && (
        <Alert text={onAlert.message} onDialog={() => setOnAlert({})} />
      )}

      {visibled && <Loading />}

      {onAlertDialog.visibled && (
        <AlertDialog
          text={onAlertDialog.message}
          onDialog={decision => handleAlertDialog(decision)}
        />
      )}

      <div className={styles.containerform}>
        <form
          id="frm-comprovante"
          onSubmit={handleSubmit}
          className="form card card-fluid "
        >
          <div className={styles.inputText}>
            <div className={styles.inputTextMd}>
              <InputText
                type="number"
                text="Nº Beneficio (NB)"
                name="beneficio"
                value={comprovante.beneficio}
                handleonChange={handleChange}
                placeholder="Digite o numero do beneficio..."
                required={true}
              />
            </div>

            <div className={styles.inputTextMd}>
              <Buttons text="Adicionar" />
            </div>
          </div>
        </form>

        <div className={styles.input_search}>
          <InputText
            type="search"
            handleonChange={e => setSearch(e.target.value)}
            placeholder="Digite um número de beneficio ou cpf para consultar..."
          />
        </div>
      </div>

      <div className={styles.table_beneficio}>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-white-700 uppercase bg-dark-50 dark:text-white">
            <tr>
              <th scope="col" className="px-3 py-3">
                Beneficio
              </th>
              <th scope="col" className="px-3 py-3">
                Cpf
              </th>
              <th scope="col" className="px-3 py-3">
                Requerente
              </th>
              <th scope="col" className="px-3 py-3">
                Data Solicitação
              </th>
              <th scope="col" className="px-3 py-3">
                Verificar
              </th>
              <th scope="col" className="px-3 py-3">
                Comprovante
              </th>
            </tr>
          </thead>
          <tbody>
            {listBeneficios.map((bn, index) => (
              <tr
                key={index}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <td className="px-3 py-2">{bn.beneficio}</td>
                <td className="px-3 py-2">{bn.cpf}</td>
                <td className="px-3 py-2">{bn.requerente}</td>

                <td className="px-3 py-2">
                  {new Date(bn.datahora).toLocaleDateString("pt-br", {
                    timeZone: "UTC",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  })}
                </td>
                <td
                  onClick={() => handleEdit(bn)}
                  className={`${styles.status} px-3 py-2`}
                >
                  {
                    <div style={{ color: bn.cor }}>
                      <i className={bn.icone}></i> <label>{bn.descricao}</label>
                    </div>
                  }
                </td>

                <td
                  className={`${styles.printPdf} px-3 py-2`}
                  onClick={() => handlePrintPdf(bn)}
                >
                  <i className="fa-sharp fa-solid fa-file-pdf " />
                  <span>Baixar pdf</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {formoffline && (
        <FormModal
          title="Inserir Beneficio manualmente"
          closeForm={() => setFormOffline(false)}
          form={
            <form onSubmit={handleSumitOff}>
              <InputText
                type="number"
                text="Nº Beneficio (NB)"
                name="beneficio"
                value={comprovante.beneficio}
                handleonChange={handleChange}
                placeholder="Digite o numero do beneficio..."
                required={true}
              />

              <InputText
                type="text"
                text="Nº CPF"
                name="cpf"
                value={comprovante.cpf}
                handleonChange={handleChange}
                placeholder="Digite o numero do cpf..."
                required={true}
              />

              <InputText
                type="text"
                text="Nome"
                name="requerente"
                value={comprovante.requerente}
                handleonChange={handleChange}
                placeholder="Digite o nome do beneficiciario..."
                required={true}
              />

              <Buttons text="Adicionar" />
            </form>
          }
        />
      )}

      {onModalUnlock && (
        <FormModal
          title={beneficio.beneficio}
          closeForm={() => setOnModalUnlock(false)}
          form={
            <div>
              <InputSelect
                text="Situação"
                handleOnChange={handleChangeBeneficio}
                onValue={beneficio.status}
                name="status"
                options={situacoes}
              />
              <Buttons text="Enviar" onClick={putRevisar} />

              {erro.visibled && (
                <div className={styles.section_erro}>
                  <span>{erro.message}</span>
                </div>
              )}
            </div>
          }
        />
      )}

      <Paginacao pagination={pagination} onPageChange={handlePageChange} />
    </div>
  )
}
