// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_container_loading__LLGMu{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #3f3f3f80;
    z-index: 10005;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;    
}

.Loading_container_loading__LLGMu span{
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
}

`, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,2BAA2B;IAC3B,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;AAChB","sourcesContent":[".container_loading{\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100vw;\r\n    height: 100vh;\r\n    background-color: #3f3f3f80;\r\n    z-index: 10005;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-direction: column;    \r\n}\r\n\r\n.container_loading span{\r\n    font-size: 1.5rem;\r\n    font-weight: bold;\r\n    color: white;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_loading": `Loading_container_loading__LLGMu`
};
export default ___CSS_LOADER_EXPORT___;
