import { api } from "../services/api"

export const getPerfil = async page => {
  const options = {
    params: {
      page,
    },
  }

  return api.get("/perfil/listar", options)
}

export const postPerfil = async dadosPerfil => {
  if (dadosPerfil.id > 0) {
    return api.put("/perfil/alterar", dadosPerfil)
  } else {
    return api.post("/perfil/cadastrar", dadosPerfil)
  }
}

export const putPerfil = async () => {
  return api.get("/perfil/alterar")
}

export const delPerfil = async id => {
  let options = {
    params: {
      id,
    },
  }
  return api.delete("/perfil/deletar", options)
}
