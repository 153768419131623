import { api } from "../services/api"

export const getExtratoConsultas = async (page, filtros) => {
  let options = {
    params: {
      page,
      filtros,
    },
  }
  return await api.get("/credito/extratoconsulta", options)
}

export const listar_transacoes = async (page = 1, filtros) => {
  const options = {
    params: {
      page,
      filtros,
    },
  }

  return await api.get("/api/transacoes", options)
}
