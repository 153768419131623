export const Protocolo = ()=>{

    const currentDate = new Date()
    const randonNumber = parseInt(Math.random() * 20);
    const currentDay = currentDate.getDate()
    const currentMonth = currentDate.getMonth() + 1
    const currentYear = currentDate.getFullYear()
    const currentHour = currentDate.getHours()
    const currentMin = currentDate.getMinutes()
    const currentSeconds = currentDate.getSeconds()                
   
    return `100${currentDay + currentMonth + currentYear + currentHour + currentMin + currentSeconds + randonNumber}`;
    
}