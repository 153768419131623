// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination_container_pagination__-GUHH{
  margin-top: 5px;  
  border:1px solid grey ;
  padding: 5px;  
}

.Pagination_paginations__NFVcR {
    display: flex;
    list-style: none;
}

.Pagination_paginations__NFVcR button{
    border: 1px solid;
    padding: 5px;
}

.Pagination_paginations__NFVcR li + li {
    margin-left: 0.8rem;
}


.Pagination_paginations__item--active__VYFUb{
    background: none;
    font-weight: bold;
    border: none;
}

.Pagination_paginations__item--active__VYFUb:focus{
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,sBAAsB;EACtB,YAAY;AACd;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;;AAGA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".container_pagination{\r\n  margin-top: 5px;  \r\n  border:1px solid grey ;\r\n  padding: 5px;  \r\n}\r\n\r\n.paginations {\r\n    display: flex;\r\n    list-style: none;\r\n}\r\n\r\n.paginations button{\r\n    border: 1px solid;\r\n    padding: 5px;\r\n}\r\n\r\n.paginations li + li {\r\n    margin-left: 0.8rem;\r\n}\r\n\r\n\r\n.paginations__item--active{\r\n    background: none;\r\n    font-weight: bold;\r\n    border: none;\r\n}\r\n\r\n.paginations__item--active:focus{\r\n    outline: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_pagination": `Pagination_container_pagination__-GUHH`,
	"paginations": `Pagination_paginations__NFVcR`,
	"paginations__item--active": `Pagination_paginations__item--active__VYFUb`
};
export default ___CSS_LOADER_EXPORT___;
