import { useEffect, useState } from 'react';
import { Buttons } from '../../components/Button';
import { FormModal } from '../../components/FormModal'
import { InputCheckBox, InputSelect, InputText } from '../../components/Inputs'
import { getListTipoServico } from '../../functions/servicos';
import { getConfigPeriodoAtend } from '../../functions/configuracoes'
import styles from './Configuracao.module.css'

export  const ConfiguracaoSistema = ()=>{
    
    const [jsConfig, setJsConfig] = useState({})
    const [lstConfigHrAt, setLstConfigHrAt] = useState({id: 0, descricao:"", config:[]})
    const [lstTipoServ, setLstTipoServ] = useState([]) 
    const [formConfigs, setFormConfigs] = useState(false)
    
    useEffect(()=>{
        (
           
            async ()=>{
                await getConfigPeriodoAtend().then(resp=>{
                    console.log(resp.data.dados[0])
                    setLstConfigHrAt(resp.data.dados[0]);
                }).catch(error=>{
                    console.log(error)
                })
                
             
            }
        )()
        
    },[])

    useEffect(()=>{
        (
           
            async ()=>{
                const lstTpServ = await getListTipoServico()   
               
                setLstTipoServ(lstTpServ.data.dados)
                
            }
        )()
        
    },[])



    const handleConfigs = (data)=>{
        setJsConfig(data)
        setFormConfigs(true)
    }

    const handleChange = (e)=>{
        setJsConfig({...jsConfig, [e.target.name]:e.target.value})        
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        jsConfig.id = lstConfigHrAt.id
        console.log(jsConfig )
    }

    return(
        <div className={styles.container_configuraca}>
            <InputText 
                text="Descrição"
                name="descricao"
                value={lstConfigHrAt.descricao}
            />

            
            {formConfigs && <FormModal 
               title="Periodo de atendimento"
               closeForm={()=>setFormConfigs(false)}
               form={ 
               <fieldset className={styles.section_fieldset}>
                    <legend>Periodo de atendimento</legend>
                    <form onSubmit={handleSubmit}>
                        <InputCheckBox 
                            text="Ativo"
                            name="ativo"
                            handleonChange={(e)=>handleChange(e)}
                            value={jsConfig.ativo}
                        />

                        <InputSelect 
                            text="Serviço"
                            name="tiposervico"
                            options={lstTipoServ}
                            handleOnChange={(e)=>handleChange(e)}
                            onValue={jsConfig.tiposervico}
                        />

                        <InputText 
                            text="Hora Inicial"
                            name="inicio"
                            handleonChange={(e)=>handleChange(e)}
                            value={jsConfig.inicio}
                        />

                        <InputText 
                            text="Hora Final"
                            name="fim"
                            handleonChange={(e)=>handleChange(e)}
                            value={jsConfig.fim}
                        />

                        <InputText 
                            text="Mensagem"
                            name="msg"
                            handleonChange={(e)=>handleChange(e)}
                            value={jsConfig.msg}
                        />

                        <Buttons text="Salvar"/>
                    </form>    
                </fieldset>}
            /> }

            <fieldset className={styles.section_fieldset}>
                <legend>Lista de periodo de atendimento</legend>
             
                <table className={styles.table_configuracoes}>
                    <thead>
                        <tr>
                            <th>Ativo</th>
                            <th>Serviço</th>
                            <th>Hora Inicial</th>
                            <th>Hora Final</th>
                            <th>Mensagem</th>

                        </tr>                                                
                    </thead>
                    <tbody>
                        {
                                
                           lstConfigHrAt.config.map(conf=>(
                                <tr onClick={()=>handleConfigs(conf)} >
                                    <td>{ 
                                        <div className={conf.ativo?styles.ativo:styles.inativo} > 
                                        </div>
                                    }
                                    </td>
                                    <td>{conf.tiposervico}</td>
                                    <td>{conf.inicio}</td>
                                    <td>{conf.fim}</td>
                                    <td>{conf.msg}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </fieldset>
        </div>
    )
}