// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Configuracao_container_configuraca__aLpQW{
    width: 100%;
    padding: 10px;
}

.Configuracao_section_fieldset__s54d5{
    border: 1px solid #333;
    padding: 10px;
    margin-bottom: 10px;
}

.Configuracao_section_fieldset__s54d5 legend{
    font-weight: 600;
}

.Configuracao_table_configuracoes__WkHEK{
    width: 100%;    
    border-collapse: collapse;
}

.Configuracao_table_configuracoes__WkHEK thead{
    background-color: #333;
    color: white;
    text-align: left;    
}

.Configuracao_table_configuracoes__WkHEK thead th{
    padding: 10px 5px 10px 5px;
}

.Configuracao_table_configuracoes__WkHEK tbody td{
    padding: 10px 5px 10px 5px;
    border-bottom: 1px solid #333;
}

.Configuracao_table_configuracoes__WkHEK tbody tr:hover{
    cursor: pointer;
    background-color: #333;
    color: white;
}


.Configuracao_ativo__MyWOv,.Configuracao_inativo__kXIlP{
    width: 15px;
    height: 15px;
    border-radius: 100%;
    
}
.Configuracao_ativo__MyWOv{
    background-color: green;
}
.Configuracao_inativo__kXIlP{
    background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/pages/Configuracao/Configuracao.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;IAC1B,6BAA6B;AACjC;;AAEA;IACI,eAAe;IACf,sBAAsB;IACtB,YAAY;AAChB;;;AAGA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;;AAEvB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,qBAAqB;AACzB","sourcesContent":[".container_configuraca{\r\n    width: 100%;\r\n    padding: 10px;\r\n}\r\n\r\n.section_fieldset{\r\n    border: 1px solid #333;\r\n    padding: 10px;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.section_fieldset legend{\r\n    font-weight: 600;\r\n}\r\n\r\n.table_configuracoes{\r\n    width: 100%;    \r\n    border-collapse: collapse;\r\n}\r\n\r\n.table_configuracoes thead{\r\n    background-color: #333;\r\n    color: white;\r\n    text-align: left;    \r\n}\r\n\r\n.table_configuracoes thead th{\r\n    padding: 10px 5px 10px 5px;\r\n}\r\n\r\n.table_configuracoes tbody td{\r\n    padding: 10px 5px 10px 5px;\r\n    border-bottom: 1px solid #333;\r\n}\r\n\r\n.table_configuracoes tbody tr:hover{\r\n    cursor: pointer;\r\n    background-color: #333;\r\n    color: white;\r\n}\r\n\r\n\r\n.ativo,.inativo{\r\n    width: 15px;\r\n    height: 15px;\r\n    border-radius: 100%;\r\n    \r\n}\r\n.ativo{\r\n    background-color: green;\r\n}\r\n.inativo{\r\n    background-color: red;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_configuraca": `Configuracao_container_configuraca__aLpQW`,
	"section_fieldset": `Configuracao_section_fieldset__s54d5`,
	"table_configuracoes": `Configuracao_table_configuracoes__WkHEK`,
	"ativo": `Configuracao_ativo__MyWOv`,
	"inativo": `Configuracao_inativo__kXIlP`
};
export default ___CSS_LOADER_EXPORT___;
