import gifLoading from "../../assets/images/Spinner200px.gif"
import styles from "./Loading.module.css"

export const Loading = () =>{
  
    return (
        <div className={styles.container_loading }>               
             <img src={gifLoading} alt="Carregando" /> 
             <span>Aguarde...</span>         
        </div>
    )
}