export const columnsEmprestimos = [
    { 
      name:'CONTRATO', 
      width:'1in',
    },
    {
      name:'BANCO', 
      width:'0.90in',
    },
    {
      name:'SITUAÇÃO', 
      width:'0.54in',
    },
    {
      name:'ORIGEM DA\nAVERBAÇÃO', 
      width:'0.66in',
    },
    {
      name:'DATA\nINCLUSÃO', 
      width:'0.54in',
    },
    {
      name:'INÍCIO\nDE DESCONTO', 
      width:'0.65in',
    },
    {
      name:'FIM DE\nDESCONTO', 
      width:'0.65in',
    },
    {
      name:'QUANTIDADE\nDE PARCELAS ', 
      width:'0.70in',
    },
    {
      name:'PARCELA', 
      width:'0.70in',
    },
    {
      name:'IOF', 
      width:'0.45in',
    },
    {
      name:'EMPRESTADO', 
      width:'0.65in',
    },
    {
      name:'LIBERADO', 
      width:'0.65in',
    },
    {
      name:'SUSPENS.\nBANCO', 
      width:'0.65in',
    },
    {
      name:'SUSPENS.\nINSS', 
      width:'0.65in',
    },
    {
      name:'REATIV.\nBANCO', 
      width:'0.65in',
    },
    {
      name:'REATIV.\nINSS', 
      width:'0.65in',
    },
    
]  

export const columnsCartoes = [
  { 
    name:'CONTRATO', 
    width:'0.68in',
  },
  {
    name:'BANCO', 
    width:'1.19in',
  },
  {
    name:'SITUAÇÃO', 
    width:'0.54in',
  },
  {
    name:'ORIGEM DA\nAVERBAÇÃO', 
    width:'0.66in',
  },
  {
    name:'DATA\nINCLUSÃO', 
    width:'0.54in',
  },
  {
    name:'LIMITE DE\nCARTÃO', 
    width:'0.52in',
  },
  {
    name:'RESERVADO', 
    width:'0.65in',
  },
  {
    name:'SUSPENSÃO\nBANCO', 
    width:'0.65in',
  },
  {
    name:'SUSPENSÃO\nINSS', 
    width:'0.65in',
  },
  {
    name:'REATIVAÇÃO\nBANCO', 
    width:'0.68in',
  },
  {
    name:'REATIVAÇÃO\nINSS', 
    width:'0.68in',
  }, 
]


  