import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context/auth"
import { Buttons } from "../Button"
import { Menu } from "../Menu"
import styles from "./Navbar.module.css"

export const NavBar = () => {
  const [nameUser, setNameUser] = useState("")
  const { logout } = useContext(AuthContext)
  const handleLogout = () => {
    logout()
  }

  const [menuModal, setMenuModal] = useState(false)
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    setNameUser(user.name)
  }, [])

  const handleOpenCloseMenuResponsive = () => {
    setMenuModal(prev => !prev)
  }

  return (
    <>
      <nav className={styles.container_navbar}>
        {/*<div className={styles.img_navbar}>
                    <Logo w="30%" />
                </div>*/}

        <div className={styles.navbar_right}>
          <div className={styles.bemvindo_navbar}>
            <h4>Seja bem vindo, {nameUser}</h4>
          </div>

          <div className={styles.area_btn_logout}>
            <Buttons text="Logout" onClick={handleLogout} />
          </div>
        </div>

        <div className={styles.button_burguer}>
          <i
            className="fa-solid fa-bars"
            onClick={handleOpenCloseMenuResponsive}
          ></i>
        </div>
      </nav>

      {menuModal && (
        <div className={styles.modalmenu}>
          <div className={styles.containe_modal}>
            <Menu onMenuItemClick={handleOpenCloseMenuResponsive} />
          </div>
          <div className={styles.button_logout}>
            <Buttons text="Logout" onClick={handleLogout} />
          </div>
        </div>
      )}
    </>
  )
}
