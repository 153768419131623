// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_inputText__a9\\+1j,
.Input_inputSelect__0ygJ- {
  margin-bottom: 8px;
}
.Input_inputText__a9\\+1j,
.Input_inputSelect__0ygJ- label {
  display: flex;
  flex-direction: column;
}
.Input_inputText__a9\\+1j,
.Input_inputSelect__0ygJ- label span {
  font-weight: 600;
}
.Input_inputText__a9\\+1j input,
.Input_inputSelect__0ygJ- select {
  width: 100%;
  padding: 10px 0 10px 0;
  font-size: 0.9rem;
  border: transparent;
  border-bottom: 1px double rgb(1, 53, 28);
}

.Input_inputText__a9\\+1j input:focus,
.Input_inputSelect__0ygJ- select:focus {
  outline: none;
  border-bottom: 2px double rgb(1, 53, 28);
  transition: 0.3s;
}

.Input_inputCheckBox__NNMx7 {
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #333;
  padding: 3px;
  margin-bottom: 5px;
}

.Input_inputCheckBox__NNMx7 label,
.Input_inputCheckBox__NNMx7 input {
  cursor: pointer;
}

.Input_inputCheckBox__NNMx7 input {
  margin-right: 3px;
}

.Input_inputCheckBox__NNMx7 span {
  font-weight: 600;
}

.Input_container_radios__mom9k {
  display: flex;
  flex-direction: column;
  padding: 0.3rem;
}

.Input_container_radios__mom9k label {
  font-weight: bold;
  cursor: pointer;
  padding: 0.3rem;
}

.Input_container_radios__mom9k input {
  margin-right: 5px;
}

@media screen and (max-width: 700px) {
  .Input_inputText__a9\\+1j,
  .Input_inputSelect__0ygJ- label {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Inputs/Input.module.css"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB;AACpB;AACA;;EAEE,aAAa;EACb,sBAAsB;AACxB;AACA;;EAEE,gBAAgB;AAClB;AACA;;EAEE,WAAW;EACX,sBAAsB;EACtB,iBAAiB;EACjB,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;;EAEE,aAAa;EACb,wCAAwC;EACxC,gBAAgB;AAClB;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;;IAEE,WAAW;EACb;AACF","sourcesContent":[".inputText,\r\n.inputSelect {\r\n  margin-bottom: 8px;\r\n}\r\n.inputText,\r\n.inputSelect label {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n.inputText,\r\n.inputSelect label span {\r\n  font-weight: 600;\r\n}\r\n.inputText input,\r\n.inputSelect select {\r\n  width: 100%;\r\n  padding: 10px 0 10px 0;\r\n  font-size: 0.9rem;\r\n  border: transparent;\r\n  border-bottom: 1px double rgb(1, 53, 28);\r\n}\r\n\r\n.inputText input:focus,\r\n.inputSelect select:focus {\r\n  outline: none;\r\n  border-bottom: 2px double rgb(1, 53, 28);\r\n  transition: 0.3s;\r\n}\r\n\r\n.inputCheckBox {\r\n  width: fit-content;\r\n  border: 1px solid #333;\r\n  padding: 3px;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.inputCheckBox label,\r\n.inputCheckBox input {\r\n  cursor: pointer;\r\n}\r\n\r\n.inputCheckBox input {\r\n  margin-right: 3px;\r\n}\r\n\r\n.inputCheckBox span {\r\n  font-weight: 600;\r\n}\r\n\r\n.container_radios {\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 0.3rem;\r\n}\r\n\r\n.container_radios label {\r\n  font-weight: bold;\r\n  cursor: pointer;\r\n  padding: 0.3rem;\r\n}\r\n\r\n.container_radios input {\r\n  margin-right: 5px;\r\n}\r\n\r\n@media screen and (max-width: 700px) {\r\n  .inputText,\r\n  .inputSelect label {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputText": `Input_inputText__a9+1j`,
	"inputSelect": `Input_inputSelect__0ygJ-`,
	"inputCheckBox": `Input_inputCheckBox__NNMx7`,
	"container_radios": `Input_container_radios__mom9k`
};
export default ___CSS_LOADER_EXPORT___;
