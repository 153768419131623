// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PerfilUsuario_container_perfil__N-8Cm{
    width: 100%;
    min-height: 50%;
    border-radius: 10px;
    padding: 10px;

}

.PerfilUsuario_modulos_sistema__w6d9Q{
    width: 100%;
}
.PerfilUsuario_modulos_sistema__w6d9Q h4{
    text-align: center;
    font-size: 1.3rem;
    padding: 5px 0 10px 0;
}

.PerfilUsuario_table_perfil__z\\+tsO{
    margin-bottom: 10px;
}

.PerfilUsuario_table_perfil__z\\+tsO table{
    width: 100%;    
    border-spacing: 0;    
}

.PerfilUsuario_table_perfil__z\\+tsO table thead {    
    background-color: rgb(20, 20, 20);
    color: white;
    
}

.PerfilUsuario_table_perfil__z\\+tsO table thead tr th {    
    font-size: 1.1rem;
    padding: 8px 2px 8px 2px; 
    text-align: left;
}

.PerfilUsuario_table_perfil__z\\+tsO table tbody tr td {    
    font-size: 1.0rem;
    padding: 7px 2px 7px 2px; 
    border-bottom: 1px solid gray;       
}

.PerfilUsuario_table_perfil__z\\+tsO table tbody tr:hover {    
    background-color: #1b1b1b;
    cursor: unset;
    color: white;
}


.PerfilUsuario_section_btn__LJyA7 i{
    cursor: pointer;
    padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/PerfilUsuario/PerfilUsuario.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,aAAa;;AAEjB;;AAEA;IACI,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,iCAAiC;IACjC,YAAY;;AAEhB;;AAEA;IACI,iBAAiB;IACjB,wBAAwB;IACxB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,wBAAwB;IACxB,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,YAAY;AAChB;;;AAGA;IACI,eAAe;IACf,YAAY;AAChB","sourcesContent":[".container_perfil{\r\n    width: 100%;\r\n    min-height: 50%;\r\n    border-radius: 10px;\r\n    padding: 10px;\r\n\r\n}\r\n\r\n.modulos_sistema{\r\n    width: 100%;\r\n}\r\n.modulos_sistema h4{\r\n    text-align: center;\r\n    font-size: 1.3rem;\r\n    padding: 5px 0 10px 0;\r\n}\r\n\r\n.table_perfil{\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.table_perfil table{\r\n    width: 100%;    \r\n    border-spacing: 0;    \r\n}\r\n\r\n.table_perfil table thead {    \r\n    background-color: rgb(20, 20, 20);\r\n    color: white;\r\n    \r\n}\r\n\r\n.table_perfil table thead tr th {    \r\n    font-size: 1.1rem;\r\n    padding: 8px 2px 8px 2px; \r\n    text-align: left;\r\n}\r\n\r\n.table_perfil table tbody tr td {    \r\n    font-size: 1.0rem;\r\n    padding: 7px 2px 7px 2px; \r\n    border-bottom: 1px solid gray;       \r\n}\r\n\r\n.table_perfil table tbody tr:hover {    \r\n    background-color: #1b1b1b;\r\n    cursor: unset;\r\n    color: white;\r\n}\r\n\r\n\r\n.section_btn i{\r\n    cursor: pointer;\r\n    padding: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_perfil": `PerfilUsuario_container_perfil__N-8Cm`,
	"modulos_sistema": `PerfilUsuario_modulos_sistema__w6d9Q`,
	"table_perfil": `PerfilUsuario_table_perfil__z+tsO`,
	"section_btn": `PerfilUsuario_section_btn__LJyA7`
};
export default ___CSS_LOADER_EXPORT___;
