import {saveAs} from 'file-saver'
import XLSX from 'sheetjs-style'
import { FormatDateTime } from './date';


const ExportExcel = async(excelData, fileName)=>{    
    
    let data_hora = FormatDateTime(new Date(),"yyyymmddhhmmss")
    
    const fileType = 'application/vnd.openxmlformats-officedocumet.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    //const exportToExcel = async()=>{
        const ws =XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets:{ 'data':ws }, SheetNames:['data'] };
        const excelBuffer = XLSX.write(wb, {bookType:'xlsx',type:'array'});
        const data = new Blob([excelBuffer],{type:fileType});

        saveAs(data, fileName + data_hora + fileExtension);
    //}

}

export default ExportExcel;