import { api } from "../services/api"

export const getListTipoServico = async () => {
  return await api.get("/api/tiposervico/listar")
}

export const Cadastrar = async data => {
  return await api.post("/api/tiposervico/cadastrar", data)
}

export const Alterar = async data => {
  return await api.put("/api/tiposervico/alterar", data)
}

export const Excluir = async () => {
  return await api.delete("/api/tiposervico/exluir")
}
