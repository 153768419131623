// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dashboard_section_cards__YP4gf{
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
}

.Dashboard_card__CO5al{
    background-color: white;
    width: 230px;
    min-width: 100px;
    max-width: 300px;   
    border-radius: 5px;    
    margin: 5px;
    padding: 20px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);  
}

.Dashboard_card__CO5al:hover{
    transform: scale(1.05);
   
}

.Dashboard_header_card__DDKoB{
    text-align: left; 
    border-bottom: 1px groove;
    margin-bottom: 3px;
}

.Dashboard_header_card__DDKoB h2{
    font-weight: 600;
    font-size: 1.2rem;
}

.Dashboard_body_card__NLqv4{        
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    
}

.Dashboard_body_card__NLqv4 p{
    font-size: 1.0rem;
    font-weight: 550;
    color: rgb(92, 91, 91);
}

.Dashboard_titulo_dashboard__oC7ai {
    padding: 5px;
    text-align: center;
}
.Dashboard_titulo_dashboard__oC7ai h2{
    text-transform: uppercase;
}

.Dashboard_titulo_dashboard__oC7ai span{    
    font-size: 0.8rem;
}


@media screen and (max-width:768px) {

    .Dashboard_section_cards__YP4gf{
        
        align-items: center;
        justify-content: center;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Dashboard.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,8CAA8C;AAClD;;AAEA;IACI,sBAAsB;;AAE1B;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,uBAAuB;;AAE3B;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB;;;AAGA;;IAEI;;QAEI,mBAAmB;QACnB,uBAAuB;IAC3B;AACJ","sourcesContent":[".section_cards{\r\n    padding: 10px;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.card{\r\n    background-color: white;\r\n    width: 230px;\r\n    min-width: 100px;\r\n    max-width: 300px;   \r\n    border-radius: 5px;    \r\n    margin: 5px;\r\n    padding: 20px;\r\n    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);  \r\n}\r\n\r\n.card:hover{\r\n    transform: scale(1.05);\r\n   \r\n}\r\n\r\n.header_card{\r\n    text-align: left; \r\n    border-bottom: 1px groove;\r\n    margin-bottom: 3px;\r\n}\r\n\r\n.header_card h2{\r\n    font-weight: 600;\r\n    font-size: 1.2rem;\r\n}\r\n\r\n.body_card{        \r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    \r\n}\r\n\r\n.body_card p{\r\n    font-size: 1.0rem;\r\n    font-weight: 550;\r\n    color: rgb(92, 91, 91);\r\n}\r\n\r\n.titulo_dashboard {\r\n    padding: 5px;\r\n    text-align: center;\r\n}\r\n.titulo_dashboard h2{\r\n    text-transform: uppercase;\r\n}\r\n\r\n.titulo_dashboard span{    \r\n    font-size: 0.8rem;\r\n}\r\n\r\n\r\n@media screen and (max-width:768px) {\r\n\r\n    .section_cards{\r\n        \r\n        align-items: center;\r\n        justify-content: center;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section_cards": `Dashboard_section_cards__YP4gf`,
	"card": `Dashboard_card__CO5al`,
	"header_card": `Dashboard_header_card__DDKoB`,
	"body_card": `Dashboard_body_card__NLqv4`,
	"titulo_dashboard": `Dashboard_titulo_dashboard__oC7ai`
};
export default ___CSS_LOADER_EXPORT___;
