// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_login__W8fyY {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.Login_form_container__cFHUN{
    background-color: rgb(248, 248, 248);
    width: 40%;    
    border-radius: 5px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.315);
    padding: 10px;
}

.Login_form__Z3NCp {
    margin-top: 10px;
    padding: 10px;
}

.Login_header_form__HwVHI {   
    
    text-align: center;
    padding: 5px;
}

.Login_retorno__e4tLz{
    margin: 0 10px 0 10px;
    background-color: rgb(161, 1, 1);
}
.Login_retorno__e4tLz p{

    font-weight: 700;
    padding: 5px;
    color: rgb(255, 255, 255);
}

.Login_btn_login__7F3aH button{
    margin-top: 10px;
    width: 100%;
}

@media screen and (max-width:700px) {
    .Login_form_container__cFHUN{
      
        width: 90vw;    
        height: 45vh;
    }
    
    
}`, "",{"version":3,"sources":["webpack://./src/pages/LoginPage/Login.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;IACpC,UAAU;IACV,kBAAkB;IAClB,4CAA4C;IAC5C,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;;IAEI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,gCAAgC;AACpC;AACA;;IAEI,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI;;QAEI,WAAW;QACX,YAAY;IAChB;;;AAGJ","sourcesContent":[".login {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    min-height: 100vh;\r\n}\r\n\r\n.form_container{\r\n    background-color: rgb(248, 248, 248);\r\n    width: 40%;    \r\n    border-radius: 5px;\r\n    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.315);\r\n    padding: 10px;\r\n}\r\n\r\n.form {\r\n    margin-top: 10px;\r\n    padding: 10px;\r\n}\r\n\r\n.header_form {   \r\n    \r\n    text-align: center;\r\n    padding: 5px;\r\n}\r\n\r\n.retorno{\r\n    margin: 0 10px 0 10px;\r\n    background-color: rgb(161, 1, 1);\r\n}\r\n.retorno p{\r\n\r\n    font-weight: 700;\r\n    padding: 5px;\r\n    color: rgb(255, 255, 255);\r\n}\r\n\r\n.btn_login button{\r\n    margin-top: 10px;\r\n    width: 100%;\r\n}\r\n\r\n@media screen and (max-width:700px) {\r\n    .form_container{\r\n      \r\n        width: 90vw;    \r\n        height: 45vh;\r\n    }\r\n    \r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `Login_login__W8fyY`,
	"form_container": `Login_form_container__cFHUN`,
	"form": `Login_form__Z3NCp`,
	"header_form": `Login_header_form__HwVHI`,
	"retorno": `Login_retorno__e4tLz`,
	"btn_login": `Login_btn_login__7F3aH`
};
export default ___CSS_LOADER_EXPORT___;
