import { useEffect, useState } from "react"
import Select from "react-select"
import { Alert } from "../../components/AlertDialog"
import { Buttons } from "../../components/Button"
import { InputSelect, InputText } from "../../components/Inputs"
import { Loading } from "../../components/Loading"
import Paginacao from "../../components/Paginacao"
import { Table } from "../../components/Table"
import { getlistAll_logins } from "../../functions/login"
import { listar_transacoes } from "../../functions/relatorio_consultas"
import { getListTipoServico } from "../../functions/servicos"
import styles from "./RelatorioTransacoes.module.css"

const paramsFiltro = {
  startDate: "",
  endDate: "",
  userId: 0,
  serviceType: 0,
}

export const RelatorioTransacoes = () => {
  const [page, setPage] = useState(1)
  const [qPages, setQPages] = useState(0)
  const [filtros, setFiltros] = useState(paramsFiltro)
  const [transacoes, setTransacoes] = useState([])
  const [transacoesSintetico, setTransacoesSintetico] = useState([])
  const [loading, setLoading] = useState(false)
  const [lstServico, setLstServico] = useState([])
  const [arrayPages, setArrayPages] = useState([])
  const [dialog, setDialog] = useState({ visibled: false, message: "" })
  const [totalReg, setTotalReg] = useState(0)
  const [usuarios, setUsuarios] = useState([])
  const [userChange, setUserChange] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 10,
  })

  useEffect(() => {
    handleListTransactions()

    getListTipoServico().then(resp => {
      setLstServico(resp.data.items)
    })
  }, [currentPage])

  useEffect(() => {
    try {
      getlistAll_logins(userChange).then(res => {
        let users = []
        res.data.items.forEach(element => {
          users.push({
            value: element.id,
            label: element.name,
          })
        })
        setUsuarios(users)
      })
    } catch (error) {}
  }, [userChange])

  const handleListTransactions = async () => {
    setLoading(true)
    try {
      const res = await listar_transacoes(currentPage, filtros)

      setTransacoes(res.data.analitico.items)
      setTransacoesSintetico(res.data.sintetico)
      setPagination(res.data.analitico.pagination)

      setLoading(false)
    } catch (erro) {
      console.error(erro)
      setLoading(false)
      setDialog({
        visibled: true,
        message: erro.data.message,
      })
    }
  }

  const handleSetarFiltro = e => {
    if (e) {
      if (e.value) {
        setFiltros({ ...filtros, userId: e.value })
      } else {
        if (e.target.name === "tiposervico") {
          setFiltros({ ...filtros, serviceType: e.target.value })
        } else if (e.target.name === "dataIni") {
          setFiltros({ ...filtros, startDate: e.target.value })
        } else if (e.target.name === "dataFim") {
          setFiltros({ ...filtros, endDate: e.target.value })
        }
      }
    }

    //dataIni
    //dataFim

    /*if (e.value) {
      setFiltros({ ...filtros, ["usuario"]: e.value })
    } else {
      setFiltros({ ...filtros, [e.target.name]: e.target.value })
    }*/
  }

  const handleFilter = async () => {
    setCurrentPage(1)
    await handleListTransactions()
  }

  const handleInputChange = newValue => {
    setUserChange(newValue)
  }

  const handlePageChange = newPage => {
    setLoading(true)
    setCurrentPage(newPage)
  }

  return (
    <div className={styles.container_rel_transacoes}>
      {loading && <Loading />}
      {dialog.visibled && (
        <Alert
          text={dialog.message}
          onDialog={() => setDialog({ visibled: false, message: "" })}
        />
      )}

      <div className={styles.container_search_transacoes}>
        <fieldset>
          <legend>Filtros para consulta</legend>

          <div className={styles.region_filters}>
            <div className={styles.filter_data}>
              <div className={styles.input}>
                <InputText
                  text="Data Inicial"
                  name="dataIni"
                  type="date"
                  handleonChange={handleSetarFiltro}
                />
              </div>

              <div className={styles.input}>
                <InputText
                  text="Data Final"
                  name="dataFim"
                  type="date"
                  handleonChange={handleSetarFiltro}
                />
              </div>

              <div className={styles.input}>
                <InputSelect
                  name="tiposervico"
                  text="Tipo de Serviço"
                  options={lstServico}
                  handleOnChange={handleSetarFiltro}
                />
              </div>

              <div className={styles.input}>
                <label>Usuários</label>
                <Select
                  options={usuarios}
                  onChange={handleSetarFiltro}
                  isClearable={true}
                  SelectOption={() => console.log("teste")}
                  onInputChange={handleInputChange}
                  placeholder="Digite para buscar..."
                />
              </div>
            </div>

            <div>
              <Buttons text="Pesquisar" onClick={handleFilter} />
            </div>
          </div>
        </fieldset>
      </div>

      <fieldset className={styles.rel_sintetico}>
        <legend>Sintético</legend>
        <Table
          headers={["Qtde", "Serviço"]}
          body={transacoesSintetico.map((transacao, index) => (
            <tr key={index}>
              <td>{transacao.qtde}</td>
              <td>{transacao.descricao}</td>
            </tr>
          ))}
        />
      </fieldset>

      <fieldset className={styles.rel_detalhado}>
        <legend>Detalhado</legend>

        <table className={styles.table_detalhado}>
          <thead>
            <tr>
              <th>Data</th>
              <th>Qtde</th>
              <th>Serviço</th>
              <th>Usuario</th>
              <th>Nome Usuario</th>
            </tr>
          </thead>
          <tbody>
            {transacoes.map(transacao => (
              <tr key={transacao.id}>
                <td>
                  {new Date(transacao.datahora).toLocaleDateString("pt-BR")}
                </td>
                <td>{transacao.qtde}</td>
                <td>{transacao.descricao}</td>

                <td>{transacao.name}</td>
                <td>{transacao.user_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </fieldset>

      <Paginacao pagination={pagination} onPageChange={handlePageChange} />
    </div>
  )
}
