import { Buttons } from "../../components/Button"
import { FormModal } from "../../components/FormModal"
import { InputCheckBox, InputSelect, InputText } from "../../components/Inputs"
import {
  Alterar,
  Cadastrar,
  getListTipoServico,
} from "../../functions/servicos"
import styles from "./ManutencaoServicos.module.css"

import { useEffect, useState } from "react"
import { Listar } from "../../functions/fornecedor"
import { IFornecedor } from "../../interfaces/Fornecedor"
import { ITipoServico } from "../../interfaces/TipoServico"

export const ManutencaoServicos = () => {
  const [modalCad, setModalCad] = useState(false)
  const [number, setNumber] = useState(0)
  const [servico, setServico] = useState(ITipoServico)
  const [listServico, setListServico] = useState([])
  const [styleMoeda, setStyleMoeda] = useState({
    style: "currency",
    currency: "BRL",
  })
  const [lstFornecedor, setLstFornecedor] = useState([IFornecedor])

  useEffect(() => {
    Listar()
      .then(resp => {
        setLstFornecedor(resp.data.fornecedores)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  useEffect(() => {
    getListTipoServico()
      .then(resp => {
        console.log(resp.data)
        setListServico(resp.data.items)
      })
      .catch(error => {
        console.error(error)
      })
  }, [number])

  const handleSaveClick = async () => {
    if (servico.id) {
      const respEdit = await Alterar(servico)
      console.log(respEdit)
    } else {
      const response = await Cadastrar(servico)
      console.log(response)
    }

    setNumber(number + 1)
    setServico({})
    setModalCad(false)
  }

  const handleEdit = data => {
    setServico(data)
    setModalCad(true)
  }

  const handleChange = e => {
    console.log(e.target.value)
    setServico({
      ...servico,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    })
  }

  return (
    <div className={styles.CCCcontainer_manut_servs}>
      <div style={{ marginBottom: "10px" }}>
        <Buttons text="Novo" onClick={() => setModalCad(true)} />
      </div>

      <table>
        <thead>
          <tr>
            <th>Descricao</th>
            <th>Preço Custo</th>
            <th>Preço Venda</th>
            <th>...</th>
          </tr>
        </thead>
        <tbody>
          {listServico.map(serv => (
            <tr key={serv.id}>
              <td>{serv.descricao}</td>
              <td>
                {new Intl.NumberFormat("pt-BR", styleMoeda).format(
                  serv.valorCusto,
                )}
              </td>
              <td>
                {new Intl.NumberFormat("pt-BR", styleMoeda).format(
                  serv.valorVenda,
                )}
              </td>

              <td className={styles.btnEdit} onClick={() => handleEdit(serv)}>
                <i className="fa-sharp fa-solid fa-pen-to-square"></i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {modalCad && (
        <FormModal
          closeForm={() => {
            setServico({})
            setModalCad(false)
          }}
          form={
            <div>
              <InputCheckBox
                name="ativo"
                text="Ativo"
                handleonChange={e => handleChange(e)}
                value={servico.ativo}
              />

              <InputText
                name="descricao"
                text="Descrição"
                value={servico.descricao}
                handleonChange={e => handleChange(e)}
              />

              <InputSelect
                name="idfornecedor"
                text={"Fornecedor"}
                handleOnChange={e => handleChange(e)}
                onValue={servico.idfornecedor}
                options={lstFornecedor}
              />

              <div className={styles.campos_linha}>
                <div className={styles.valCusto}>
                  <InputText
                    name="valorCusto"
                    text="Preço Custo"
                    type="number"
                    handleonChange={e => handleChange(e)}
                    value={servico.valorCusto}
                  />
                </div>

                <div className={styles.valVenda}>
                  <InputText
                    name="valorVenda"
                    text="Preço Venda"
                    type="number"
                    value={servico.valorVenda}
                    handleonChange={e => handleChange(e)}
                  />
                </div>
              </div>

              <div style={{ marginBottom: "10px" }}>
                <InputText
                  name="observacao"
                  text="Observação"
                  value={servico.observacao}
                  handleonChange={e => handleChange(e)}
                />
              </div>

              <div>
                <Buttons text="Salvar" onClick={() => handleSaveClick()} />
              </div>
            </div>
          }
        />
      )}
    </div>
  )
}
