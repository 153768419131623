
export const IDesbloqueio = {
    id:0,
    protocolo: "",
    requerente:"",
    unidade:"INSS",
    datahora:"",
    cpf:"",
    beneficio:"",
    status:0,
    id_usuario: 0,
    id_usu_desbloqueio:0,
    ddb:""
  }