import { api } from "../services/api"

export const getMenu = async () => {
  return api.get("/menu")
}

export const getAllMenu = async page => {
  const options = {
    params: {
      page,
    },
  }
  return api.get("/admin/menu", options)
}

export const salvarMenu = async menu => {
  if (menu.id > 0) {
    return api.put("/admin/menu", menu)
  } else {
    return api.post("/admin/menu", menu)
  }
}

export const deleteMenu = async id => {
  const options = {
    params: {
      id,
    },
  }
  return api.delete("/admin/menu", options)
}
