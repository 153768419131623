import React, { createContext, useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { createSession } from "../functions/login"
import { api } from "../services/api"

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const queryClient = useQueryClient()

  useEffect(() => {
    const recoverdUser = localStorage.getItem("user")

    if (recoverdUser) {
      setUser(JSON.parse(recoverdUser))
    }

    setLoading(false)
  }, [])

  const loginUser = async (user, password) => {
    try {
      const resp = await createSession(user, password)

      const loggedUser = resp.data
      const token = resp.data.token

      localStorage.setItem("user", JSON.stringify(loggedUser))
      localStorage.setItem("token", token)

      api.defaults.headers.Authorization = `Bearer: ${token}`

      setUser(loggedUser)
      navigate("/home")
    } catch (error) {
      console.error(error)
      return {
        message:
          error.response.data.message || "Ocorreu um erro ao realizar login",
      }
    }
  }

  const logout = () => {
    setUser(null)
    localStorage.removeItem("user")
    localStorage.removeItem("token")
    api.defaults.headers.Authorization = null
    queryClient.invalidateQueries("repoData")
    navigate("/")
  }
  return (
    <AuthContext.Provider
      value={{ authenticated: !!user, user, loading, loginUser, logout }}
    >
      {children}
    </AuthContext.Provider>
  )
}
