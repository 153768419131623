import { useEffect, useState } from "react"
import Select from "react-select"
import { Alert } from "../../components/AlertDialog"
import { Buttons } from "../../components/Button"
import { FormModal } from "../../components/FormModal"
import { InputSelect, InputText } from "../../components/Inputs"
import { Loading } from "../../components/Loading"
import Paginacao from "../../components/Paginacao"
import {
  cancelarSolicitacao,
  getBeneficiosDesbloqueio,
  liberar,
} from "../../functions/desbloqueio_liberar"
import { getlistAll_logins } from "../../functions/login"
import { getListSituacoes } from "../../functions/situacoes"
import ExportExcel from "../../utils/ExcelExport"
import styles from "./DesbloqueioLiberar.module.css"

export const DesbloqueioLiberar = () => {
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(1)

  const [qPages, setQPages] = useState(0)
  const [lstBeneficios, setLstBeneficios] = useState([])
  const [beneficio, setBeneficio] = useState({})
  const [loading, setLoading] = useState(false)
  const [onModalUnlock, setOnModalUnlock] = useState(false)
  const [codigo, setCodigo] = useState(0)
  const [filtro, setFiltro] = useState({
    txtinput: "",
    datainicial: "",
    datafinal: "",
    ddbdataini: "",
    ddbdatafim: "",
    id_usuario: 0,
  })
  const [usuarios, setUsuarios] = useState([])
  const [userChange, setUserChange] = useState("")

  const [onAlert, setOnAlert] = useState({
    visibled: false,
    message: "",
  })

  const [situacoes, setSituacoes] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 10,
  })

  useEffect(() => {
    handleSearchDate()
  }, [currentPage, codigo, filtro.txtinput, filtro.id_usuario])

  useEffect(() => {
    ;(async () => {
      await getListSituacoes(3)
        .then(sit => {
          setSituacoes(sit.data.situacao)
        })
        .catch(error => {
          console.error(error)
        })
    })()
  }, [])

  useEffect(() => {
    getlistAll_logins(userChange)
      .then(res => {
        let users = []
        res.data.items.forEach(element => {
          users.push({
            value: element.id,
            label: element.name,
          })
        })
        setUsuarios(users)
      })
      .catch(error => {
        setOnAlert(true, error.message)
        console.error(error)
      })
  }, [userChange])

  const ExportForXLSX = async () => {
    try {
      setLoading(true)

      const { data } = await getBeneficiosDesbloqueio(0, filtro, 1)

      let lstDesbLiberar = []
      data.items.map(lst => {
        lstDesbLiberar.push({
          beneficio: lst.beneficio,
          cpf: lst.cpf,
          requerente: lst.requerente,
          datahora: new Date(lst.datahora).toLocaleDateString("pt-br", {
            timeZone: "UTC",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          }),
          solicitante: lst.solicitante,
          verificar: lst.descricao,
          ddb:
            lst.ddb &&
            new Date(lst.ddb).toLocaleDateString("pt-br", {
              timeZone: "UTC",
            }),
        })
      })

      await ExportExcel(lstDesbLiberar, "Desbloqueios Liberar")

      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
      setOnAlert({
        visibled: true,
        message: "Ocorreu um erro ao exportar arquivo.",
      })
    }
  }

  const handlePageChange = newPage => {
    setLoading(true)
    setCurrentPage(newPage)
  }

  const handleDesbloquear = benefic => {
    setOnModalUnlock(true)
    setBeneficio(benefic)
  }

  const handleEdit = e => {
    setBeneficio({ ...beneficio, ["status"]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      if (Number(beneficio.status) === 7) {
        const resp = await cancelarSolicitacao(beneficio, 3)
        setCodigo(codigo + 1)
        setOnModalUnlock(false)
      } else {
        const resp = await liberar(beneficio, 3)
        setCodigo(codigo + 1)
        setOnModalUnlock(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
      setOnAlert({
        visibled: true,
        message:
          error.code === "ERR_NETWORK"
            ? error.message
            : error.response?.data.message,
      })
    }
  }

  const handleSearchDate = async () => {
    setLoading(true)

    await getBeneficiosDesbloqueio(currentPage, filtro)
      .then(resp => {
        if (resp.data.erro) {
          return setOnAlert({
            visibled: true,
            message: resp.data.message,
          })
        }

        setPagination(resp.data.pagination)

        setLstBeneficios(resp?.data.items)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.error(error)
        setOnAlert({
          visibled: true,
          message:
            error.code === "ERR_NETWORK"
              ? error.message
              : error.response?.data.message,
        })
      })
  }

  const handleSearch = async e => {
    if (e) {
      if (e.value) {
        setFiltro({ ...filtro, ["id_usuario"]: e.value })
      } else {
        setFiltro({ ...filtro, [e.target.name]: e.target.value })
      }
    } else {
      setFiltro({ ...filtro, ["id_usuario"]: 0 })
    }
  }

  const handleInputChange = newValue => {
    setUserChange(newValue)
  }

  return (
    <div className={styles.container_desbloqueio}>
      {loading && <Loading />}
      {onAlert.visibled && (
        <Alert text={onAlert.message} onDialog={() => setOnAlert({})} />
      )}

      <div className={styles.table_desbloqueio}>
        <div className={styles.search}>
          <fieldset>
            <legend>Consultar</legend>

            <div className={styles.input_search}>
              <InputText
                type="search"
                name="txtinput"
                handleonChange={handleSearch}
                placeholder="Digite um Nº de Beneficio, CPF ou nome do Requerente para consultar..."
              />
            </div>

            <div className={styles.search_date}>
              <fieldset>
                <legend>Data da solicitação</legend>
                <div className={styles.inputs}>
                  <InputText
                    type="date"
                    text="Data Inicial"
                    name="datainicial"
                    handleonChange={handleSearch}
                  />
                </div>

                <div className={styles.inputs}>
                  <InputText
                    type="date"
                    text="Data Final"
                    name="datafinal"
                    handleonChange={handleSearch}
                  />
                </div>
              </fieldset>
              <fieldset>
                <legend>Data do DDB</legend>
                <div className={styles.inputs}>
                  <InputText
                    type="date"
                    text="DDB - Data Inicial"
                    name="ddbdataini"
                    handleonChange={handleSearch}
                  />
                </div>

                <div className={styles.inputs}>
                  <InputText
                    type="date"
                    text="DDB - Data Final"
                    name="ddbdatafim"
                    handleonChange={handleSearch}
                  />
                </div>
              </fieldset>

              <fieldset>
                <legend>Selecione o solicitante</legend>
                <div className={styles.inputs}>
                  <label></label>
                  <Select
                    options={usuarios}
                    onChange={handleSearch}
                    isClearable={true}
                    SelectOption={() => console.log("teste")}
                    onInputChange={handleInputChange}
                    placeholder="Digite para buscar..."
                  />
                </div>
              </fieldset>
              <div className={styles.btn_search}>
                <Buttons text="Pesquisar" onClick={handleSearchDate} />

                <i
                  className="fa-solid fa-file-export"
                  onClick={ExportForXLSX}
                  title="Exportar dado em excel"
                ></i>
              </div>
            </div>
          </fieldset>
        </div>

        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-white-700 uppercase bg-dark-50 dark:text-white">
            <tr>
              <th scope="col" class="px-3 py-3">
                NB
              </th>
              <th scope="col" class="px-3 py-3">
                CPF
              </th>
              <th scope="col" class="px-3 py-3">
                Requerente
              </th>
              <th scope="col" class="px-3 py-3">
                DDB
              </th>
              <th scope="col" class="px-3 py-3">
                Data Solicitação
              </th>
              <th scope="col" class="px-3 py-3">
                Solicitante
              </th>
              <th scope="col" class="px-3 py-3">
                Verificar
              </th>
            </tr>
          </thead>
          <tbody>
            {lstBeneficios?.map((bene, index) => (
              <tr
                key={index}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <td className="px-3 py-2">{bene.beneficio}</td>
                <td className="px-3 py-2">{bene.cpf}</td>
                <td className="px-3 py-2">{bene.requerente}</td>
                <td className="px-3 py-2">
                  {bene.ddb &&
                    new Date(bene.ddb).toLocaleDateString("pt-br", {
                      timeZone: "UTC",
                    })}
                </td>
                <td className="px-3 py-2">
                  {new Date(bene.datahora).toLocaleDateString("pt-br", {
                    timeZone: "UTC",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  })}
                </td>
                <td className="px-3 py-2">{bene.solicitante}</td>

                <td
                  className={styles.td_unlock}
                  onClick={() => handleDesbloquear(bene)}
                >
                  {
                    <div style={{ color: bene.cor }}>
                      <i className={bene.icone}></i>{" "}
                      <label>{bene.descricao}</label>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Paginacao pagination={pagination} onPageChange={handlePageChange} />
      </div>

      {onModalUnlock && (
        <FormModal
          title={beneficio.beneficio}
          closeForm={() => setOnModalUnlock(false)}
          form={
            <div>
              <form onSubmit={handleSubmit}>
                <InputSelect
                  text="Situação"
                  handleOnChange={handleEdit}
                  onValue={beneficio.status}
                  name="status"
                  options={situacoes}
                />
                <Buttons text="Enviar" />
              </form>
            </div>
          }
        />
      )}
    </div>
  )
}
