import { api } from "../services/api"

export class ApiSolicitacaoSenha {
  async solicitar(data) {
    return api.post("/api/solicitar-senha", data)
  }

  async alterar(data) {
    return api.put("/api/atualizar-senha", data)
  }

  async listarSolicitacoes(filtro, currentPage) {
    const opcoes = {
      params: {
        filtro,
        page: currentPage,
        limit: 10,
      },
    }
    console.log(filtro)
    return api.get("/api/listar-solicitacoes-senha", opcoes)
  }

  async listarSituacaoSolicitacoes(idmodulo) {
    const opcoes = {
      params: {
        idmodulo,
      },
    }
    return api.get("/api/listar-situacao-solic-senha", opcoes)
  }

  async exportarSolicitacoesCsv(filtro) {
    const opcoes = {
      params: {
        filtro,
      },
    }
    return await api.get("/api/exportar-solicitacoes-csv", opcoes)
  }
}
