import { useEffect, useState } from "react"
import { Buttons } from "../../components/Button"
import { InputSelect } from "../../components/Inputs"
import { Loading } from "../../components/Loading"
import {
  delPerfilModulos,
  getModulos,
  getPerfilModulos,
  postPerfilModulos,
} from "../../functions/modulos_sistema"
import { getPerfil } from "../../functions/perfil_sistema"

import { Alert } from "../../components/AlertDialog"
import styles from "./PerfilModulos.module.css"

export const PerfilModulos = () => {
  const [retornoPost, setRetornoPost] = useState("")
  const [modalModulos, setModalModulos] = useState(false)
  const [lstPerfil, setLstPerfil] = useState([])
  const [modulosSis, setModulosSIS] = useState([])
  const [lstPerfilModulos, setLstPerfilModulos] = useState([])
  const [loading, setLoading] = useState(false)
  const [number, setNumber] = useState(0)
  const [perfilModulo, setPerfiModulo] = useState({})
  const [onAlert, setOnAlert] = useState({
    visibled: false,
    message: "",
  })

  useEffect(() => {
    setLoading(true)
    getPerfil()
      .then(resp => {
        setLstPerfil(resp.data.items)
        setLoading(false)
      })
      .catch(erro => {
        setLoading(false)
        console.log(erro)
      })

    getModulos()
      .then(resp => {
        let lstModulos = []
        resp.data.items.map(mod => {
          lstModulos.push({ id: mod.id, descricao: mod.desc_modulo })
        })

        setModulosSIS(lstModulos)
        setLoading(false)
      })
      .catch(erro => {
        setLoading(false)
        console.log(erro)
      })
  }, [number])

  const handleChangeModulo = async e => {
    setPerfiModulo({ ...perfilModulo, [e.target.name]: e.target.value })

    if (e.target.name === "idperfil") {
      GetPerfilModulos(e.target.value)
    }
  }

  const GetPerfilModulos = async id => {
    setLoading(true)
    await getPerfilModulos(id)
      .then(resp => {
        setLstPerfilModulos(resp.data.items)
        setLoading(false)
      })
      .catch(erro => {
        setLoading(false)
        console.log(erro)
      })
  }

  const handleValidAddModulo = () => {
    setRetornoPost("")
    if (perfilModulo.idperfil === undefined) {
      alert("Selecione um perfil")
    } else {
      setModalModulos(true)
    }
  }

  const handleAddModulo = async () => {
    setLoading(true)
    await postPerfilModulos(perfilModulo)
      .then(resp => {
        GetPerfilModulos(perfilModulo.idperfil)
        setRetornoPost(resp.data.mensagem)
        setLoading(false)
      })
      .catch(error => {
        console.log(error.response)
        setOnAlert(true, error.response.data.message)
      })
  }

  const handleDelete = async dados => {
    setLoading(true)
    await delPerfilModulos(dados.idperfil, dados.idmodulo)
      .then(resp => {
        GetPerfilModulos(dados.idperfil)
        setLoading(false)
      })
      .catch(erro => {
        console.log(erro)
        setLoading(false)
      })
  }

  return (
    <div className={styles.container_perfil_modulos}>
      {loading && <Loading />}
      {onAlert.visibled && (
        <Alert text={onAlert.message} onDialog={() => setOnAlert({})} />
      )}
      <div className={styles.seccion_perfil}>
        <fieldset>
          <legend>Selecione um perfil para listar os modulos</legend>

          <div className={styles.inputPerfil}>
            <InputSelect
              name="idperfil"
              options={lstPerfil}
              handleOnChange={handleChangeModulo}
            />
          </div>

          <div className={styles.seccion_btn_perfil_modulos}>
            <Buttons
              text="Adicionar Modulo ao perfil"
              onClick={handleValidAddModulo}
            />
          </div>
        </fieldset>
      </div>

      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-50 uppercase bg-gray-800 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Modulo(s)
              </th>
              <th scope="col" className="px-6 py-3">
                {" "}
                #
              </th>
            </tr>
          </thead>
          <tbody>
            {lstPerfilModulos.map(perfMod => (
              <tr
                key={perfMod.id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <td className="px-6">{perfMod.desc_modulo}</td>
                <td
                  className={styles.column_del}
                  onClick={() => handleDelete(perfMod)}
                >
                  <i className="fa-solid fa-trash"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {modalModulos && (
        <div className={styles.form_modal}>
          <div className={styles.head_form_modal}>
            <h1>Adicionar modulo</h1>
            <i
              className="fa-sharp fa-solid fa-xmark"
              onClick={() => setModalModulos(false)}
            ></i>
          </div>

          <div className={styles.containe_modal}>
            <InputSelect
              name="idmodulo"
              text="Selecione um modulo"
              options={modulosSis}
              handleOnChange={handleChangeModulo}
            />
            <Buttons text="Adicionar" onClick={handleAddModulo} />

            <div className={styles.msg_form_modal}>
              <span>{retornoPost}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
