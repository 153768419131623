import React from 'react';
import style from './siape.module.css'
import { InputText } from '../../components/Inputs';
import { useState } from 'react';
import { useEffect } from 'react';
import { Buttons } from '../../components/Button';
import {SiapePdf} from '../../Reports/Siape';
import { FormModal } from '../../components/FormModal';

const DetalhesSiape = ({dados}) => {
    const [consignado, setConsignado]=useState([])
    const [cartao, setCartao] = useState([])
    const [bancos, setBancos] = useState([])
    const [bcoCartao, setBcoCartao] = useState([])
    const [frmPrint, setFrmPrint] = useState(false)

    useEffect(()=>{ 
        let emprConsignado=[]  
        let emprCartao = []  
        let banco = []
        let bcoCto = []

        dados.resultado?.map((emprestimos)=>{            
         
            if(emprestimos.DescricaoTipoEmprestimo === 'CONSIGNADO'){
                banco.push(emprestimos.BancoEmprestimo)

                emprConsignado.push({
                    banco:emprestimos.BancoEmprestimo,
                    tipoemprestimo:emprestimos.DescricaoTipoEmprestimo,
                    contrato:emprestimos.NumeroContrato,
                    parcela:emprestimos.ValorParcela,
                    prazo:emprestimos.Prazo,
                    finaldesconto:emprestimos.CompetenciaFinalDesconto,
                    saldo:emprestimos.SaldoDevedor,
                    taxa:emprestimos.Taxa
                })
            }
            
            if(emprestimos.DescricaoTipoEmprestimo === 'CARTÃO'){
                bcoCto.push(emprestimos.BancoEmprestimo)

                emprCartao.push({
                    BancoEmprestimo:emprestimos.BancoEmprestimo,
                    DescricaoTipoEmprestimo:emprestimos.DescricaoTipoEmprestimo,
                    NumeroContrato:emprestimos.NumeroContrato,
                    SituacaoCartao:emprestimos.SituacaoCartao,
                    ValorReservadoCartao:emprestimos.ValorReservadoCartao,                   
                })
            }
                  
        })

        const BcoEmprestimo = banco.filter((valor, indice, self)=>{           
            return self.indexOf(valor)===indice;
        });

        const BcoCC = bcoCto.filter((valor, indice, self)=>{           
            return self.indexOf(valor)===indice;
        });

        setBancos(BcoEmprestimo)
        setBcoCartao(BcoCC)

        setConsignado(emprConsignado)
        setCartao( emprCartao)
        
       
    },[])
   
    const handlePrint = () =>{
       // ReactPDF.render(  <SiapePdf /> )
       setFrmPrint(true)
    }

  return (
    <section className={style.container_detalhes_siape}>
        <div>

          { frmPrint &&  <FormModal 
                closeForm={()=>setFrmPrint(false)}
            form={<SiapePdf data ={dados}/>}
           />}
                               
           
            <Buttons 
                text="Imprimir"
                onClick={()=>handlePrint()}
            />
        </div>

        <fieldset className={style.section_data}>
            <legend>Dados Pessoais</legend>

            <InputText 
                text="Matricula"
                value={dados.matricula_siape}
            />

            <InputText 
                text="CPF"
                value={dados.cpf}
            />

            <InputText 
                text="Nome"
                value={dados.resultado[0].Nome}
            />

            <InputText 
                text="Sexo"
                value={dados.resultado[0].Sexo}
            />   
  
            <InputText 
                text="Data de nascimento"
                value={new Date( dados.resultado[0].DataNascimento).toLocaleDateString('pt-BR') }
            />

            <InputText 
                text="Situação da matrícula"
                value={dados.resultado[0].SituacaoBeneficio}
            />

            <InputText 
                text="RG"
                value={dados.resultado[0].RG}
            />

            <InputText 
                text="Nome da mãe"
                value={dados.resultado[0].NomeMae}
            /> 


            <InputText 
                text="Regime jurídico"
                value={dados.resultado[0].DescricaoRegimeJuridico}
            /> 
            <InputText 
                text="Orgão"
                value={dados.resultado[0].CodigoOrgao +'-'+dados.resultado[0].DescricaoOrgao}
            /> 
            <InputText 
                text="Salário bruto"
                value={dados.resultado[0].SalarioBruto}
            /> 
            <InputText 
                text="Total de descontos"
                value={dados.resultado[0].TotalDescontos}
            />  
            <InputText 
                text="Salário líquido"
                value={dados.resultado[0].SalarioLiquido}
            />
            <InputText 
                text="Competência"
                value={dados.resultado[0].Competencia}
            />
            <InputText 
                text="Data de atualização"
                value={new Date( dados.resultado[0].DataAtualizacao).toLocaleDateString('pt-BR') }
            />       
        </fieldset>
    
        <fieldset className={style.section_data}>
            <legend>Endereço do beneficiário</legend>

            <InputText 
                text="Endereço"
                value={dados.matricula_siape}
            />
            <InputText 
                text="Cidade/UF"
                value={dados.resultado[0].Cidade +'/'+ dados.resultado[0].UF}
            />
            <InputText 
                text="CEP"
                value={dados.matricula_siape}
            />
        </fieldset>

        <fieldset className={style.section_data}>
            <legend>Contatos pessoais</legend>

            <InputText 
                text="Contato 1 (Telefone)"
                value={dados.resultado[0].Telefone1}
            />
            <InputText 
                text="Contato 2 (Telefone)"
                value={dados.resultado[0].Telefone2}
            />
            <InputText 
                text="Contato 3 (Email)"
                value={dados.resultado[0].Email1}
            />
            <InputText 
                text="Contato 4 (Email)"
                value={dados.resultado[0].Email2}
            /> 

        </fieldset>
      
        <fieldset className={style.section_data}>
            <legend>Dados bancários</legend>

            <InputText 
                text="Banco"
                value={dados.resultado[0].Banco}
            />
            <InputText 
                text="Agência"
                value={dados.resultado[0].NumeroAgencia}
            />
            <InputText 
                text="Número da conta corrente"
                value={dados.resultado[0].NumeroContaCorrente}
            />
          

        </fieldset>

        <fieldset className={style.section_data}>
            <legend>Margens</legend>
            
            <fieldset className={style.section_margem}>
                <legend><h2>Margem de 35%</h2></legend>
                
                <InputText 
                    text="Margem para empréstimo"
                    value={dados.resultado[0].MargemEmprestimo}
                />
              
            </fieldset>
           
            <fieldset className={style.section_margem}>
                <legend><h2>Margem de 5%</h2></legend>
                
                <InputText 
                    text="Margem do cartão"
                    value={dados.resultado[0].MargemCartao}
                />
                               
            </fieldset>

            <fieldset className={style.section_margem}>
                <legend> <h2>Cartão Benefício 5%</h2></legend>
                <InputText 
                    text="Margem do cartão"
                    value={dados.resultado[0].MargemCartaoBeneficio}
                />                             
            </fieldset>

        </fieldset>

        <fieldset className={style.section_emprestimos} >
            <legend>Empréstimos</legend>
            
            { bancos && bancos.map((bco, index)=>(

               

                <fieldset className={style.section_emprestimos}>
                    <legend><h4>{ bco }</h4></legend> 
                    <table>
                               <thead>
                                   <th>Contrato</th>
                                   <th>Parcela</th>
                                   <th>Prazo remanescente</th>
                                   <th>Fim desconto</th>
                                   <th>Saldo</th>
                                   <th>Taxa</th>
                               </thead>
                    {
                    consignado.map(((empr, index)=>(                                                
                       bco===empr.banco?
                       
                          
                               <tbody>
                                   <tr>
                                       <td>{empr.contrato}</td>
                                       <td>{empr.parcela}</td>
                                       <td>{empr.prazo}</td>
                                       <td>{empr.finaldesconto}</td>
                                       <td>{empr.saldo}</td>
                                       <td>{empr.taxa}</td>
                                   </tr>
               
                               </tbody>
                                                                                                         
                      :""
                    
                      ))) 
                    }
                 </table>     
                </fieldset>
            ))
            }

        </fieldset>  

        <fieldset className={style.section_emprestimos}>
            <legend>Reserva de cartão</legend>

            { bcoCartao && bcoCartao.map((bcocc, index)=>(

               
                <fieldset className={style.section_emprestimos}>
                    <legend><h4>{ bcocc }</h4></legend> 
                <table>
                            <thead>
                                <th>Contrato</th>
                                <th>Situação</th>
                                <th>Parcela</th>                                
                            </thead>
                    { cartao.map(((cto, index)=>( 
                      
                      bcocc ===cto.BancoEmprestimo?
                    
                       

                            <tbody>
                                <tr>
                                    <td>{cto.NumeroContrato}</td>
                                    <td>{cto.SituacaoCartao}</td>
                                    <td>{cto.ValorReservadoCartao}</td>                                    
                                </tr>

                            </tbody>

                                                         
                    
                    
                    :""
                    
                    )))
                       
                    }
                </table>
                </fieldset>
                ))
                }           

        </fieldset> 
    </section>
  );
}

export default DetalhesSiape;
