import { useEffect, useState } from "react"
import { v4 } from "uuid"
import { Alert } from "../../components/AlertDialog"
import { Buttons } from "../../components/Button"
import { FormModal } from "../../components/FormModal"
import { InputSelect, InputText } from "../../components/Inputs"
import { Loading } from "../../components/Loading"
import Paginacao from "../../components/Paginacao"
import { ApiSolicitacaoSenha } from "../../functions/SolicitacaoSenha"
import styles from "./AtualizarSenha.module.css"

export const AtualizarSenha = () => {
  const api = new ApiSolicitacaoSenha()

  const [onAlertDialog, setOnAlertDialog] = useState({
    visibled: false,
    message: "",
  })
  const [visibled, setVisibled] = useState(false)
  const [onAlert, setOnAlert] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [showLoading, setShowLoading] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 10,
  })

  const [solicSenha, setSolicSenha] = useState({
    cpf: "",
    observacao: "",
    senha: "",
  })
  const [listarSolicSenhas, setListarSolicSenhas] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [title, setTitle] = useState("Nova Solicitação")
  const [number, setNumber] = useState(0)
  const [situacoes, setSituacoes] = useState([])
  const [campoSenha, setCampoSenha] = useState(false)
  const [openModalFiltro, setOpenModalFiltro] = useState(false)
  const [filterValue, setFilterValue] = useState({
    dataIni: "",
    dataFim: "",
    idretorno: 0,
    idusuario: 0,
  })

  const [usuariosSolicitacoes, setUsuariosSolicitacoes] = useState({
    id: 0,
    descricao: "",
  })

  useEffect(() => {
    ;(async () => {
      try {
        const lstSituacoes = await api.listarSituacaoSolicitacoes(6)
        setSituacoes(lstSituacoes.data)
      } catch (error) {
        console.error(error)
        setOnAlert({
          visibled: true,
          message: error.response?.data.message,
        })
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const retorno = await api.listarSolicitacoes(filterValue, currentPage)

        setUsuariosSolicitacoes(retorno.data.additionalData)
        setListarSolicSenhas(retorno.data.items)
        setPagination(retorno.data.pagination)

        setVisibled(false)
      } catch (error) {
        console.error(error)
        setOnAlert({
          visibled: true,
          message: error.response?.data.message,
        })
      }
    })()
  }, [currentPage, number])

  const handlePageChange = newPage => {
    setVisibled(true)
    setCurrentPage(newPage)
  }

  const handleChange = e => {
    switch (Number(e.target.value)) {
      case 1:
      case 2:
      case 7:
        setCampoSenha(true)
        break

      default:
        setCampoSenha(false)
        break
    }
    setSolicSenha({ ...solicSenha, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    try {
      console.log(solicSenha)

      if (solicSenha.idretorno === "") {
        return setOnAlert({
          visibled: true,
          message: `Informe uma situação valida`,
        })
      }
      if (
        Number(solicSenha.idretorno) === 7 ||
        Number(solicSenha.idretorno) === 1
      ) {
        return setOnAlert({
          visibled: true,
          message: `Não e possível finalizar solicitação com a situação atual`,
        })
      }

      if (Number(solicSenha.idretorno) === 6 && solicSenha.senha === "") {
        return setOnAlert({
          visibled: true,
          message: `Não e possível finalizar solicitação com a senha em branco`,
        })
      }

      const retor = await api.alterar(solicSenha)
      setNumber(number + 1)
      setOpenModal(false)
    } catch (error) {
      console.error(error)
      setOnAlert({
        visibled: true,
        message: error.response.data.message,
      })
    }
  }

  const handleEditSolicitacao = async data => {
    setTitle(`Editar Solicitação - CPF: ${data.cpf}`)
    setSolicSenha({
      id: data.id,
      cpf: data.cpf,
      idretorno: data.idretorno,
      observacao: data.observacao,
      senha: data.senha,
    })
    setOpenModal(true)
  }

  const handleExport = async () => {
    try {
      const resp = await api.exportarSolicitacoesCsv(filterValue)
      console.log(resp)
      const utf8Bom = "\uFEFF"
      const csvData = utf8Bom + resp.data

      const contentDisposition = resp.headers["content-disposition"]
      const fileName = `${v4()}.csv`

      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" })
      const url = window.URL.createObjectURL(blob)

      const a = document.createElement("a")
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      a.remove()

      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error(error)
      return setOnAlert({
        visibled: true,
        message: `Não e possível realizar a exportação`,
      })
    }
  }

  const handleFiltrarSolicitacoes = valueForm => {
    if (valueForm) {
      setFilterValue({
        dataIni: "",
        dataFim: "",
        idretorno: 0,
      })
    } else {
      setNumber(number + 1)
    }

    setOpenModalFiltro(valueForm)
  }

  return (
    <div className={styles.dcontainerBeneficio}>
      {onAlert.visibled && (
        <Alert text={onAlert.message} onDialog={() => setOnAlert({})} />
      )}

      {visibled && <Loading />}
      <div
        style={{
          margin: "10px 5px",
          display: "flex",
        }}
      >
        <div
          style={{
            marginRight: 10,
          }}
        >
          <Buttons text="Exportar" onClick={() => handleExport()} />
        </div>
        <div>
          <Buttons
            text="Filtrar"
            onClick={() => handleFiltrarSolicitacoes(true)}
          />
        </div>
      </div>

      <div className={styles.table_beneficio}>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-50 uppercase bg-gray-800 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <td scope="col" className="p-4">
                CPF
              </td>
              <td scope="col" className="p-4">
                Retorno
              </td>
              <td scope="col" className="p-4">
                Solicitante
              </td>
              <td scope="col" className="p-4">
                Senha
              </td>
              <td scope="col" className="p-4">
                Data da Solicitação
              </td>
              <td scope="col" className="p-4">
                Data da Atualização
              </td>
              <td scope="col" className="p-4">
                #
              </td>
            </tr>
          </thead>
          <tbody>
            {listarSolicSenhas &&
              listarSolicSenhas.map((senha, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-3 py-2">{senha.cpf}</td>
                  <td className="px-3 py-2">
                    {senha.SituacaoSolicSenha.descricao}
                  </td>
                  <td className="px-3 py-2">{senha.Login.name}</td>
                  <td className="px-3 py-2">{senha.senha}</td>
                  <td className="px-3 py-2">
                    {new Date(senha.createdAt).toLocaleString()}
                  </td>
                  <td className="px-3 py-2">
                    {senha.updatedAt !== senha.createdAt
                      ? new Date(senha.updatedAt).toLocaleString()
                      : ""}
                  </td>
                  <td className="px-3 py-2">
                    <a
                      href="#"
                      onClick={() => handleEditSolicitacao(senha)}
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {openModal && (
        <FormModal
          title={title}
          form={
            <div>
              <InputSelect
                name="idretorno"
                text={"Situação"}
                options={situacoes}
                onValue={solicSenha.idretorno}
                handleOnChange={handleChange}
              />
              <InputText
                text="CPF"
                name="cpf"
                placeholder="Informe o número de Cpf"
                value={solicSenha.cpf}
                handleonChange={handleChange}
              />
              <InputText
                name="senha"
                text="Senha"
                value={solicSenha.senha}
                placeholder="Informe a senha"
                handleonChange={handleChange}
                disabled={campoSenha}
              />
              <InputText
                name="observacao"
                text="Observação"
                value={solicSenha.observacao}
                handleonChange={handleChange}
                placeholder="Observação"
              />
              <div style={{ paddingTop: 10 }}>
                <Buttons
                  text="Salvar"
                  onClick={() => {
                    handleSubmit()
                  }}
                />
              </div>
            </div>
          }
          closeForm={() => setOpenModal(false)}
        />
      )}

      {openModalFiltro && (
        <FormModal
          title="Filtrar"
          form={
            <div>
              <div style={{ display: "flex" }}>
                {/*<div style={{ marginRight: 10 }}>
                  <InputText
                    name="dataIni"
                    handleonChange={e =>
                      setFilterValue({
                        ...filterValue,
                        dataIni: e.target.value,
                      })
                    }
                    text="Data Inicial"
                    type="Date"
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <InputText
                    name="dataFim"
                    handleonChange={e =>
                      setFilterValue({
                        ...filterValue,
                        dataFim: e.target.value,
                      })
                    }
                    text="Data Fim"
                    type="Date"
                  />
                </div>*/}

                <div style={{ marginRight: 10 }}>
                  <InputSelect
                    name="idretorno"
                    text={"Situação"}
                    options={situacoes}
                    handleOnChange={e =>
                      setFilterValue({
                        ...filterValue,
                        idretorno: Number(e.target.value),
                      })
                    }
                  />
                </div>
                <fieldset>
                  <legend>Selecione o solicitante</legend>
                  <div>
                    <InputSelect
                      name="idretorno"
                      options={usuariosSolicitacoes}
                      handleOnChange={e =>
                        setFilterValue({
                          ...filterValue,
                          idusuario: Number(e.target.value),
                        })
                      }
                    />
                  </div>
                </fieldset>
              </div>

              <div style={{ paddingTop: 10 }}>
                <Buttons
                  text="Filtrar"
                  onClick={() => {
                    handleFiltrarSolicitacoes(false)
                  }}
                />
              </div>
            </div>
          }
          closeForm={() => setOpenModalFiltro(false)}
        />
      )}
      <Paginacao pagination={pagination} onPageChange={handlePageChange} />
    </div>
  )
}
