import React from "react";
import {createRoot} from 'react-dom/client'
import "./index.css";
import App from './App';
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@material-tailwind/react";

const queryClient = new QueryClient()

createRoot( document.getElementById('root')).render(
    <React.StrictMode>
        <ThemeProvider>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </ThemeProvider>
    </React.StrictMode>,
   
);
