import { useEffect, useState } from "react"
import { Buttons } from "../../components/Button"
import { InputText } from "../../components/Inputs"
import { Loading } from "../../components/Loading"
import { deleteModulos, getModulos, postModulos } from "../../functions/modulos_sistema"
import styles from "./ModulosSistema.module.css"

export const ModulosSistema =()=>{
    const [lstModulos, setLstModulos]= useState([])
    const [loading,setLoading]=useState(false)
    const [modalModulos, setModalModulos] = useState(false)
    const [number, setNumber]=useState(0)
    const [modulo, setModulo]=useState({"id":0,"desc_modulo":""})

    useEffect(()=>{
        setLoading(true)
        getModulos().then(resp=>{
           
            setLstModulos(resp.data.modulos.rows)
            setLoading(false)

        }).catch(erro=>{
            setLoading(false)
            console.log(erro)
        })
    },[number])  
    
    const handleNovoModulo = ()=>{
        setModulo({})
        setModalModulos(true)
    }

    const handleModuloSubmit = async (e)=>{
        e.preventDefault();
        console.log(modulo)
        await postModulos(modulo).then(resp=>{
      
            setNumber(number + 1)
            setModalModulos(false)
            setModulo({})

        }).catch(erro=>{
            console.log(erro)
        })
        

    }

    const handleEditModulo = (modulo)=>{
     
        setModulo({id:modulo.id, desc_modulo:modulo.desc_modulo})
        setModalModulos(true)
    }

    const handleExclModulo = async (id)=>{
        deleteModulos(id).then(resp=>{
            
            setNumber(number + 1)
        }).catch(erro=>{
            console.log(erro)
        })
    }

    const handleonChangeModulo = (e)=>{        
        setModulo({...modulo,[e.target.name]:e.target.value})
    }


return(

    <div className={styles.container_modulos_sistema}>
        {loading && <Loading /> }
        <div className={styles.seccion_table_modulos}>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Descrição</th>
                        <th>#</th>
                        <th>#</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        lstModulos.map(mod=>(
                            <tr key={mod.id}>
                                <td>{mod.id}</td>
                                <td>{mod.desc_modulo}</td>
                                
                                <td className={styles.column_del}  onClick={()=>handleExclModulo(mod.id)}>
                                    <i className="fa-solid fa-trash"></i>
                                </td> 
                                <td className={styles.column_edit} onClick={()=>handleEditModulo(mod)}>
                                    <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>

        <div className={styles.seccion_btn_novo}>
            <Buttons 
                text="Novo modulo"
                onClick={handleNovoModulo}
            />
        </div>

        {modalModulos && <div className={styles.form_modal}>

            <div className={styles.head_form_modal}>
                <h1>Novo modulo</h1>
                <i className="fa-sharp fa-solid fa-xmark" onClick={()=>setModalModulos(false)}></i>
            </div>
            <div className={styles.containe_modal}>
                
                <form onSubmit={handleModuloSubmit}>
                    <InputText 
                        text="Nome do modulo"
                        value={modulo.desc_modulo}
                        name="desc_modulo"
                        handleonChange={(e)=>handleonChangeModulo(e)}
                        required={true}
                        
                    />

                    <Buttons 
                        text="Salvar"
                    />
                </form>
                
            </div>
        </div>}

    </div>

)

}