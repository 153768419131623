import { api } from "../services/api"

export const baixarPdfServidor = async file => {
  api.defaults.headers.tiposervico = 1

  let options = {
    headers: {
      Accept: "application/pdf",
    },
    params: {
      id_consulta: file.id,
    },
    responseType: "arraybuffer",
  }
  return api.get(`/api/baixar-pdf`, options)
}

export const baixarPdfExtratoOnline = async (pNumeroBeneficio, pVersao = 2) => {
  api.defaults.headers.tiposervico = 1

  let options = {
    headers: {
      Accept: "application/pdf",
    },
    params: {
      pNumeroBeneficio,
      pVersao,
    },
    responseType: "arraybuffer",
  }

  return api.get(`/api/extrato/online/pdf`, options)
}

export const getSolicitacoesExtratoPdf = async page => {
  const options = {
    params: {
      page,
    },
  }

  return await api.get("/api/extrato/online/listar", options)
}

export const ConsultarExtratoOnline = async ({
  beneficio,
  idExtratoOff = 0,
}) => {
  api.defaults.headers.tiposervico = 1

  let options = {
    params: {
      pNumeroBeneficio: beneficio,
      idExtratoOff,
    },
  }

  return await api.get("/api/extrato/online", options)
}

export const RefazerConsultaOnline = async (beneficio, id) => {
  api.defaults.headers.tiposervico = 1

  let options = {
    params: {
      beneficio,
      id,
    },
  }

  return await api.get("/api/extrato/refazer-consulta-online", options)
}
