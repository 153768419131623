import { api } from "../services/api";

  export const ListarOfflineIn100 = async(page)=>{

   // api.defaults.headers.tiposervico = tiposervico;
  
    let options ={
      params:{
        page
      }
    }
  
    return await api.get("/api/extrato/offline/listar",options )
    
   }
   
   export const ApiConsultaOffline = async({origem=5, cpf="", beneficio})=>{
    //origem=5, cpf, beneficio

    api.defaults.headers.origemsolicitacao = parseInt(origem);
  
    let options ={
      params:{     
        cpf, 
        beneficio :beneficio     
      }
    }
  
    
    return await api.get("/api/extrato/offline", options )
    
   }
  
