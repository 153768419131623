import { useEffect, useState } from "react"
import { Alert } from "../../components/AlertDialog"
import { Buttons } from "../../components/Button"
import { FormModal } from "../../components/FormModal"
import { InputSelect, InputText } from "../../components/Inputs"
import { Loading } from "../../components/Loading"
import Paginacao from "../../components/Paginacao"
import { ApiSolicitacaoSenha } from "../../functions/SolicitacaoSenha"
import styles from "./SolicitacaoSenha.module.css"

/*const situacoes = [
  { descricao: "EM ANDAMENTO", id: 1 },
  { descricao: "CPF NAO PODE SER ATENDIDO POR ESTA CENTRAL", id: 2 },
  { descricao: "CPF INVALIDO", id: 3 },
  { descricao: "VERIFICACAO DE DUAS ETAPAS", id: 4 },
  { descricao: "ERRO NA RECEITA FEDERAL", id: 5 },
  { descricao: "SOLICITACAO REALIZADA COM SUCESSO", id: 6 },
]*/

export const SolicitacaoSenha = () => {
  const api = new ApiSolicitacaoSenha()

  const [onAlertDialog, setOnAlertDialog] = useState({
    visibled: false,
    message: "",
  })
  const [visibled, setVisibled] = useState(false)
  const [onAlert, setOnAlert] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [showLoading, setShowLoading] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 10,
  })

  const [solicSenha, setSolicSenha] = useState({
    cpf: "",
    observacao: "",
    senha: "",
  })
  const [listarSolicSenhas, setListarSolicSenhas] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [title, setTitle] = useState("Nova Solicitação")
  const [number, setNumber] = useState(0)
  const [inputEnabled, setInputEnabled] = useState(true)
  const [situacoes, setSituacoes] = useState([])
  useEffect(() => {
    ;(async () => {
      try {
        const retorno = await api.listarSolicitacoes({}, currentPage)
        const lstSituacoes = await api.listarSituacaoSolicitacoes(null)

        setSituacoes(lstSituacoes.data)
        setListarSolicSenhas(retorno.data.items)
        setPagination(retorno.data.pagination)

        setVisibled(false)
      } catch (error) {
        console.error(error)
        setOnAlert({
          visibled: true,
          message: error.response?.data.message || error.message,
        })
      }
    })()
  }, [currentPage, number])

  const handlePageChange = newPage => {
    setVisibled(true)
    setCurrentPage(newPage)
  }

  const handleChange = e => {
    setSolicSenha({ ...solicSenha, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    try {
      if (solicSenha.idretorno === 6) {
        return setOnAlert({
          visibled: true,
          message:
            "Selecione uma situação diferente de 'Solicitação Realizada com Sucesso'",
        })
      }

      if (!solicSenha.id) {
        const retorno = await api.solicitar({
          cpf: solicSenha.cpf,
          idretorno: 1,
        })
      } else {
        const retor = await api.alterar(solicSenha)
      }

      setNumber(number + 1)
      setOpenModal(false)
    } catch (error) {
      console.error(error)
      setOnAlert({
        visibled: true,
        message: error.response.data.message,
      })
    }
  }

  const handleEditSolicitacao = async data => {
    if (data.idretorno !== 6) {
      return setOnAlert({
        visibled: true,
        message:
          "Não e possível editar solicitações com situação diferente de 'Realizada com Sucesso'",
      })
    }

    setTitle(`Editar Solicitação - CPF: ${data.cpf}`)
    setSolicSenha({
      id: data.id,
      cpf: data.cpf,
      idretorno: data.idretorno,
      observacao: data.observacao,
      senha: data.senha,
    })
    setInputEnabled(true)
    setOpenModal(true)
  }

  const handleNew = e => {
    setTitle("Nova Solicitação")
    setSolicSenha({
      idretorno: 1,
    })
    setInputEnabled(false)
    setOpenModal(true)
  }

  return (
    <div className={styles.dcontainerBeneficio}>
      {onAlert.visibled && (
        <Alert text={onAlert.message} onDialog={() => setOnAlert({})} />
      )}

      {visibled && <Loading />}

      <div
        style={{
          margin: "10px 5px",
          display: "flex",
        }}
      >
        <div
          style={{
            marginRight: 10,
          }}
        >
          <Buttons text="Novo" onClick={() => handleNew()} />
        </div>
      </div>

      <div className={styles.table_beneficio}>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-50 uppercase bg-gray-800 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <td scope="col" className="p-4">
                CPF
              </td>
              <td scope="col" className="p-4">
                Retorno
              </td>
              <td scope="col" className="p-4">
                Senha
              </td>
              <td scope="col" className="p-4">
                Data da Solicitação
              </td>
              <td scope="col" className="p-4">
                Data da Atualização
              </td>
              <td scope="col" className="p-4">
                #
              </td>
            </tr>
          </thead>
          <tbody>
            {listarSolicSenhas &&
              listarSolicSenhas.map((senha, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-3 py-2">{senha.cpf}</td>
                  <td className="px-3 py-2">
                    {senha.SituacaoSolicSenha.descricao}
                  </td>
                  <td className="px-3 py-2">{senha.senha}</td>
                  <td className="px-3 py-2">
                    {new Date(senha.createdAt).toLocaleString()}
                  </td>
                  <td className="px-3 py-2">
                    {senha.updatedAt !== senha.createdAt
                      ? new Date(senha.updatedAt).toLocaleString()
                      : ""}
                  </td>
                  <td className="px-3 py-2">
                    <a
                      href="#"
                      onClick={() => handleEditSolicitacao(senha)}
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {openModal && (
        <FormModal
          title={title}
          form={
            <div>
              {inputEnabled && (
                <InputSelect
                  name="idretorno"
                  text={"Situação"}
                  options={situacoes}
                  onValue={solicSenha.idretorno}
                  handleOnChange={handleChange}
                />
              )}
              <InputText
                text="CPF"
                name="cpf"
                placeholder="Informe o número de Cpf"
                value={solicSenha.cpf}
                handleonChange={handleChange}
              />
              {inputEnabled && (
                <InputText
                  name="senha"
                  text="Senha"
                  value={solicSenha.senha}
                  placeholder="Informe a senha"
                  handleonChange={handleChange}
                  required
                />
              )}
              <InputText
                name="observacao"
                text="Observação"
                value={solicSenha.observacao}
                handleonChange={handleChange}
                placeholder="Observação"
              />

              <div style={{ paddingTop: 10 }}>
                <Buttons
                  text="Salvar"
                  onClick={() => {
                    handleSubmit()
                  }}
                />
              </div>
            </div>
          }
          closeForm={() => setOpenModal(false)}
        />
      )}

      <Paginacao pagination={pagination} onPageChange={handlePageChange} />
    </div>
  )
}
