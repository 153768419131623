import {
  Document,
  Font,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer"
import React from "react"
import benefi from "../../assets/images/ri_1.png"
import qrAutentica from "../../assets/images/ri_14.jpeg"
import finanImg from "../../assets/images/ri_2.png"
import inssImg from "../../assets/images/ri_5.png"
//import {responsePdf} from './JsonExtrato'
import fontHelvetica from "../../assets/fonte/helvetica-bold.otf"
import { columnsCartoes, columnsEmprestimos } from "./ColumsEmprestimosCartoes"
import { css } from "./css"

Font.register({
  family: "Helvetica",
  src: fontHelvetica,
})

const styles = StyleSheet.create(css)

export const MyPDFComponent = async data => {
  const responsePdf = data

  let contratosEmprestimo = []
  let contratosRMC = []
  let contratosRCC = []

  let valorEmprestimo = 0
  let margemUtilizadaRmc = 0
  let margemUtilizadaRcc = 0
  //let margemConsignavel = 0;
  let margemConsigCartao = 0
  let margemExtrapolada = 0

  /*switch (Number(responsePdf.dados.especie.codigo)) {
    case 87,88:{
     // margemConsignavel =  (responsePdf.dados.valorBase * 0.30).toFixed(2);
      margemConsigCartao = (responsePdf.dados.valorBase * 0.10).toFixed(2) 
    }      
      break;
  
    default:
   //   margemConsignavel =  (responsePdf.dados.valorBase * 0.35).toFixed(2);
      margemConsigCartao = (responsePdf.dados.valorBase * 0.05).toFixed(2) 
  }*/

  switch (Number(responsePdf.dados.especie.codigo)) {
    case (87, 88):
      {
        // margemConsignavel =  (responsePdf.dados.valorBase * 0.30).toFixed(2);
        margemConsigCartao = (responsePdf.dados.valorBase * 0.1).toFixed(2)
      }
      break

    default:
      //   margemConsignavel =  (responsePdf.dados.valorBase * 0.35).toFixed(2);
      margemConsigCartao = (responsePdf.dados.valorBase * 0.05).toFixed(2)
  }

  let widthEmprestimo = 0
  let widthCartoesC = 0

  columnsEmprestimos.forEach(i => {
    widthEmprestimo += +i.width.replace("in", "")
  })

  columnsCartoes.forEach(i => {
    widthCartoesC += +i.width.replace("in", "")
  })

  if (
    !Array.isArray(responsePdf.contratosEmprestimo) &&
    responsePdf.contratosEmprestimo !== null
  ) {
    contratosEmprestimo.push(responsePdf.contratosEmprestimo)
  } else {
    contratosEmprestimo = responsePdf.contratosEmprestimo
  }

  if (
    !Array.isArray(responsePdf.contratosRMC) &&
    responsePdf.contratosRMC !== null
  ) {
    contratosRMC.push(responsePdf.contratosRMC)
  } else {
    contratosRMC = responsePdf.contratosRMC
  }

  if (
    !Array.isArray(responsePdf.contratosRCC) &&
    responsePdf.contratosRCC !== null
  ) {
    contratosRCC.push(responsePdf.contratosRCC)
  } else {
    contratosRCC = responsePdf.contratosRCC
  }

  contratosEmprestimo?.map(emprestimo => {
    if (emprestimo) {
      valorEmprestimo += emprestimo.valorParcela
    }
  })

  contratosRMC?.map(rmc => {
    if (rmc) {
      margemUtilizadaRmc += rmc.valorReservado
    }
  })

  contratosRCC?.map(rcc => {
    if (rcc) {
      margemUtilizadaRcc += rcc.valorReservado
    }
  })

  margemExtrapolada =
    Number(responsePdf.dados.margemConsignavel) < Number(valorEmprestimo)
      ? Number(responsePdf.dados.margemConsignavel) - Number(valorEmprestimo)
      : 0

  const PdfDataTags = (
    <Document>
      <Page size="A3" orientation="portrait" style={styles.page}>
        <View style={styles.viewTopPdf} fixed>
          <Text style={styles.topPdf}>Instituto Nacional do Seguro Social</Text>
        </View>

        <View style={styles.bodyPdf}>
          <View style={styles.title}>
            <Text style={styles.titleH3}>HISTÓRICO DE</Text>
            <Text style={styles.titleH2}>EMPRÉSTIMO CONSIGNADO</Text>
          </View>

          <View style={styles.lineWidth} />

          <View style={styles.boxInfo}>
            <Text
              style={{
                fontSize: "11pt",
                textAlign: "center",
                paddingBottom: "5px",
              }}
            >
              {responsePdf.dados.nome}
            </Text>

            <View style={styles.bodyBoxInfo}>
              <View style={styles.headBoxInfo}>
                <View>
                  <Image
                    style={{
                      display: "flex",
                      width: "0.56in",
                      height: "0.56in",
                      marginRight: "10px",
                    }}
                    src={benefi}
                  />
                </View>
                <View>
                  <Text>Benefício</Text>
                </View>
              </View>

              {/** Benefício */}
              <View style={styles.containerBody}>
                <Text
                  style={{
                    fontSize: "11pt",
                    color: "#1f43d8",
                    fontWeight: "ultrabold",
                    marginBottom: "10px",
                  }}
                >
                  {responsePdf.dados.especie.descricao}
                </Text>

                <View style={styles.boxInfoBeneficio}>
                  <View style={styles.columnInfoBenef1}>
                    <Text style={styles.fontSizeBoxInfo}>
                      N° Benefício: {responsePdf.dados.beneficio}
                    </Text>
                    <Text style={styles.fontSizeBoxInfo}>
                      Situação: {responsePdf.dados.situacao}
                    </Text>
                    <Text style={styles.fontSizeBoxInfo}>
                      Pago em: {responsePdf.dados.banco.codigo} -{" "}
                      {responsePdf.dados.banco.descricao}
                    </Text>
                    <Text style={styles.fontSizeBoxInfo}>
                      Meio: {responsePdf.dados.meioPagamento}
                    </Text>
                    <Text style={styles.fontSizeBoxInfo}>
                      Agência: {responsePdf.dados.agencia}
                    </Text>
                    <Text style={styles.fontSizeBoxInfo}>
                      Conta Corrente: {responsePdf.dados.conta}
                    </Text>
                  </View>

                  <View style={styles.columnInfoBenef2}>
                    <Text style={styles.fontSizeBoxInfo}>
                      {responsePdf.dados.possuiProcurador
                        ? "Possui procurador"
                        : "Não possui procurador"}
                    </Text>
                    <Text style={styles.fontSizeBoxInfo}>
                      {responsePdf.dados.possuiRepresentante
                        ? "Possui representante legal"
                        : "Não possui representante legal"}{" "}
                    </Text>
                    <Text style={styles.fontSizeBoxInfo}>
                      {responsePdf.dados.pensaoAlimenticia
                        ? "Pensão alimentícia"
                        : "Não é pensão alimentícia"}{" "}
                    </Text>
                    <Text style={styles.fontSizeBoxInfo}>
                      {responsePdf.dados.bloqueioEmprestismo
                        ? "Bloqueado para empréstimo"
                        : "Liberado para empréstimo"}{" "}
                    </Text>
                    <Text style={styles.fontSizeBoxInfo}>
                      {responsePdf.dados.elegivelEmprestimo
                        ? "Elegível para empréstimos"
                        : "Não elegível para empréstimos"}{" "}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            {/*Quantitativo de Empréstimos por Situação*/}
            <View style={styles.bodyBoxInfo}>
              <View style={styles.headBoxInfo}>
                <View>
                  <Image
                    style={{
                      display: "flex",
                      width: "0.56in",
                      height: "0.56in",
                      marginRight: "10px",
                    }}
                    src={finanImg}
                  />
                </View>
                <View>
                  <Text>Quantitativo de Empréstimos por Situação</Text>
                </View>
              </View>

              <View style={styles.containerBody}>
                <View style={styles.tableMargemEmpr}>
                  <View
                    style={{
                      width: "210px",
                      fontSize: "10px",
                      borderTop: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    <View
                      style={{
                        borderLeft: "1px solid black",
                        backgroundColor: "#dcdcdc",
                      }}
                    >
                      <Text
                        style={{
                          height: "20px",
                          padding: "3px",
                          fontSize: "10px",
                          borderBottom: "1px solid black",
                          backgroundColor: "#dcdcdc",
                        }}
                      >
                        SITUAÇÃO
                      </Text>
                      <Text
                        style={{
                          height: "20px",
                          fontSize: "11pt",
                          padding: "3px 0 3px 5px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        ATIVOS
                      </Text>
                      <Text
                        style={{
                          height: "20px",
                          fontSize: "11pt",
                          padding: "3px 0 3px 5px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        SUSPENSOS
                      </Text>
                      <Text
                        style={{
                          height: "20px",
                          fontSize: "11pt",
                          padding: "3px 0 3px 5px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        RESERVADOS PORTABILIDADE
                      </Text>
                      <Text
                        style={{
                          height: "20px",
                          fontSize: "11pt",
                          padding: "3px 0 3px 5px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        RESERVADOS REFINANCIAMENTO
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      border: "1px solid black",
                    }}
                  >
                    <View
                      style={{
                        width: "143px",
                        fontSize: "10px",
                        textAlign: "center",
                        borderRight: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          height: "20px",
                          padding: "3px",
                          fontSize: "10px",
                          borderBottom: "1px solid black",
                          backgroundColor: "#dcdcdc",
                        }}
                      >
                        QUANTIDADE
                      </Text>
                      <Text
                        style={{
                          height: "20px",
                          padding: "3px",
                          fontSize: "10px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        {responsePdf.dados.emprestimosAtivos}
                      </Text>
                      <Text
                        style={{
                          height: "20px",
                          padding: "3px",
                          fontSize: "10px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        {responsePdf.dados.emprestimosSuspenso}
                      </Text>
                      <Text
                        style={{
                          height: "20px",
                          padding: "3px",
                          fontSize: "10px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        {responsePdf.dados.emprestimosPortabilidade}
                      </Text>
                      <Text
                        style={{
                          height: "20px",
                          padding: "3px",
                          fontSize: "10px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        {responsePdf.dados.emprestimosRefinanciamento}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            {/*Margem para Empréstimo/Cartão e Resumo Financeiro*/}
            <View style={styles.bodyBoxInfo}>
              <View style={styles.headBoxInfo}>
                <View>
                  <Image
                    style={{
                      display: "flex",
                      width: "0.56in",
                      height: "0.56in",
                      marginRight: "10px",
                    }}
                    src={finanImg}
                  />
                </View>
                <View>
                  <Text>Margem para Empréstimo/Cartão e Resumo Financeiro</Text>
                </View>
              </View>

              <View style={styles.containerBody}>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginBottom: "20px",
                  }}
                >
                  <View
                    style={{
                      width: "60%",
                      border: "2px solid black",
                      margin: "0 auto",
                    }}
                  >
                    <Text
                      style={{
                        height: "20px",
                        fontSize: "11pt",
                        padding: "3px 0 3px 5px",
                        fontWeight: "bold",
                        borderBottom: "1px solid black",
                        textAlign: "center",
                        backgroundColor: "#dcdcdc",
                      }}
                    >
                      VALORES DO BENEFÍCIO
                    </Text>

                    <View style={[styles.tableMargemEmpr]}>
                      <View
                        style={{
                          width: "80%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          backgroundColor: "#dcdcdc",
                        }}
                      >
                        <Text
                          style={{
                            height: "20px",
                            fontSize: "11pt",
                            padding: "3px 0 3px 5px",
                            borderBottom: "1px solid black",
                          }}
                        >
                          BASE DE CÁLCULO
                        </Text>
                        <Text
                          style={{
                            height: "20px",
                            fontSize: "11pt",
                            padding: "3px 0 3px 5px",
                            borderBottom: "1px solid black",
                          }}
                        >
                          MÁXIMO DE COMPROMETIMENTO PERMITIDO{" "}
                        </Text>
                        <Text
                          style={{
                            height: "20px",
                            fontSize: "11pt",
                            padding: "3px 0 3px 5px",
                            borderBottom: "1px solid black",
                          }}
                        >
                          TOTAL COMPROMETIDO
                        </Text>
                        <Text
                          style={{
                            height: "20px",
                            fontSize: "11pt",
                            padding: "3px 0 3px 5px",
                          }}
                        >
                          MARGEM EXTRAPOLADA***
                        </Text>
                      </View>

                      <View
                        style={{
                          width: "20%",
                          fontSize: "10px",
                          textAlign: "center",
                        }}
                      >
                        <Text
                          style={{
                            height: "20px",
                            padding: "3px",
                            fontSize: "10px",
                            borderBottom: "1px solid black",
                          }}
                        >
                          {responsePdf.dados.valorBase.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}{" "}
                        </Text>
                        <Text
                          style={{
                            height: "20px",
                            padding: "3px",
                            fontSize: "10px",
                            borderBottom: "1px solid black",
                          }}
                        >
                          {(
                            Number(responsePdf.dados.margemConsignavel) +
                            Number(responsePdf.dados.margemDisponivelRCC) +
                            Number(responsePdf.dados.margemDisponivelRMC)
                          ).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </Text>
                        <Text
                          style={{
                            height: "20px",
                            padding: "3px",
                            fontSize: "10px",
                            borderBottom: "1px solid black",
                          }}
                        >
                          {(
                            Number(valorEmprestimo) +
                            Number(margemUtilizadaRmc) +
                            Number(margemUtilizadaRcc)
                          ).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </Text>
                        <Text
                          style={{
                            height: "20px",
                            padding: "3px",
                            fontSize: "10px",
                          }}
                        >
                          0
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ width: "100%", marginBottom: "20px" }}>
                  <View
                    style={{
                      border: "2px solid black",
                      width: "80%",
                      margin: "0 auto",
                    }}
                  >
                    <Text
                      style={{
                        height: "20px",
                        fontSize: "11pt",
                        padding: "3px 0 3px 5px",
                        fontWeight: "bold",
                        borderBottom: "1px solid black",
                        textAlign: "center",
                        backgroundColor: "#dcdcdc",
                      }}
                    >
                      VALORES POR MODALIDADE
                    </Text>
                    <View style={styles.tableMargemEmpr}>
                      <View
                        style={{
                          width: "210px",
                          fontSize: "10px",
                          borderTop: "1px solid white",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <Text
                          style={{
                            height: "20px",
                            fontSize: "11pt",
                            padding: "3px",
                            borderBottom: "1px solid black",
                            backgroundColor: "#dcdcdc",
                          }}
                        ></Text>

                        <View
                          style={{
                            borderLeft: "1px solid black",
                            backgroundColor: "#dcdcdc",
                          }}
                        >
                          <Text
                            style={{
                              height: "20px",
                              fontSize: "11pt",
                              padding: "3px 0 3px 5px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            MARGEM CONSIGNÁVEL*{" "}
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              fontSize: "11pt",
                              padding: "3px 0 3px 5px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            MARGEM UTILIZADA
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              fontSize: "11pt",
                              padding: "3px 0 3px 5px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            MARGEM RESERVADA**{" "}
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              fontSize: "11pt",
                              padding: "3px 0 3px 5px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            MARGEM DISPONÍVEL
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              fontSize: "11pt",
                              padding: "3px 0 3px 5px",
                            }}
                          >
                            MARGEM EXTRAPOLADA**
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          border: "1px solid black",
                        }}
                      >
                        <View
                          style={{
                            width: "143px",
                            fontSize: "10px",
                            textAlign: "center",
                            borderRight: "1px solid black",
                          }}
                        >
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                              backgroundColor: "#dcdcdc",
                            }}
                          >
                            EMPRÉSTIMOS
                          </Text>

                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            {Number(
                              responsePdf.dados.margemConsignavel,
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}{" "}
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            {valorEmprestimo.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            R$ 0,00
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            {Number(
                              responsePdf.dados.margemDisponivelEmprestimo,
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                            }}
                          >
                            {Number(margemExtrapolada).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </Text>
                        </View>

                        <View
                          style={{
                            width: "143px",
                            fontSize: "10px",
                            textAlign: "center",
                            borderRight: "1px solid black",
                          }}
                        >
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                              backgroundColor: "#dcdcdc",
                            }}
                          >
                            RMC
                          </Text>

                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            {Number(margemConsigCartao).toLocaleString(
                              "pt-BR",
                              {
                                style: "currency",
                                currency: "BRL",
                              },
                            )}
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            {Number(margemUtilizadaRmc).toLocaleString(
                              "pt-BR",
                              { style: "currency", currency: "BRL" },
                            )}{" "}
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            R$ 0,00
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            {Number(
                              responsePdf.dados.margemDisponivelRMC,
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}{" "}
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                            }}
                          >
                            R$ 0,00
                          </Text>
                        </View>

                        <View
                          style={{
                            width: "143px",
                            fontSize: "10px",
                            textAlign: "center",
                          }}
                        >
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                              backgroundColor: "#dcdcdc",
                            }}
                          >
                            RCC
                          </Text>

                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            {Number(margemConsigCartao).toLocaleString(
                              "pt-BR",
                              {
                                style: "currency",
                                currency: "BRL",
                              },
                            )}
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            {Number(margemUtilizadaRcc).toLocaleString(
                              "pt-BR",
                              { style: "currency", currency: "BRL" },
                            )}{" "}
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            R$ 0,00
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            {Number(
                              responsePdf.dados.margemDisponivelRCC,
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </Text>
                          <Text
                            style={{
                              height: "20px",
                              padding: "3px",
                              fontSize: "10px",
                            }}
                          >
                            R$ 0,00
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ width: "83%", margin: "0 auto" }}>
                  <Text style={{ fontSize: "10pt", margin: "5px 0 5px 0" }}>
                    * Para benefícios das espécies, 18, 87 e 88 a margem
                    consignável representa 30% da base de cálculo para
                    empréstimos e 5% para cartão, podendo optar por somente uma
                    das modalidades RMC ou RCC. Para as demais espécies, a
                    margem consignável atual representa 35% da base de cálculo
                    para empréstimos, 10% para cartão, sendo 5% para RMC e 5%
                    para RCC
                  </Text>

                  <Text style={{ fontSize: "10pt" }}>
                    ** O valor da margem reservada está incluído no valor da
                    margem utilizada. *** A margem extrapolada representa o
                    valor que excedeu a margem disponível da modalidade ou o
                    máximo de comprometimento do benefício, que pode ocorrer em
                    situações específicas como a redução da renda do benefício
                    ou a alteração legal da margem consignável de empréstimos e
                    cartões.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        {/**EMPRÉSTIMOS BANCÁRIOS */}
        <View style={styles.bodyPdf} break>
          <Text
            style={{
              fontSize: "11pt",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            EMPRÉSTIMOS BANCÁRIOS
          </Text>

          <View style={[styles.table, { width: widthEmprestimo + "in" }]}>
            <View style={[styles.tableCol, { backgroundColor: "#dcdcdc" }]}>
              <Text
                style={[
                  {
                    fontSize: "12pt",
                    padding: "10px 0 10px 20px",
                    color: "gray",
                    textAlign: "left",
                  },
                ]}
              >
                CONTRATOS ATIVOS E SUSPENSOS*
              </Text>
            </View>

            <View style={[styles.tableRow, { backgroundColor: "#dcdcdc" }]}>
              <View
                style={[
                  styles.tableCol,
                  { width: columnsEmprestimos[0].width },
                ]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsEmprestimos[0].name}
                </Text>
              </View>

              <View
                style={[
                  styles.tableCol,
                  { width: columnsEmprestimos[1].width },
                ]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsEmprestimos[1].name}
                </Text>
              </View>

              <View
                style={[
                  styles.tableCol,
                  { width: columnsEmprestimos[2].width },
                ]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsEmprestimos[2].name}
                </Text>
              </View>

              <View
                style={[
                  styles.tableCol,
                  { width: columnsEmprestimos[3].width },
                ]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsEmprestimos[3].name}
                </Text>
              </View>

              <View
                style={[
                  styles.tableCol,
                  { width: columnsEmprestimos[4].width },
                ]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsEmprestimos[4].name}
                </Text>
              </View>

              <View>
                <View
                  style={[styles.tableCol, { borderBottom: "1px solid black" }]}
                >
                  <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                    COMPETÊNCIA
                  </Text>
                </View>

                <View
                  style={[
                    { display: "table", flexDirection: "row", height: "30px" },
                  ]}
                >
                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsEmprestimos[5].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsEmprestimos[5].name}
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsEmprestimos[6].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsEmprestimos[6].name}
                    </Text>
                  </View>
                </View>
              </View>

              <View
                style={[
                  styles.tableCol,
                  { width: columnsEmprestimos[7].width },
                ]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsEmprestimos[7].name}
                </Text>
              </View>

              <View>
                <View
                  style={[styles.tableCol, { borderBottom: "1px solid black" }]}
                >
                  <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                    VALOR
                  </Text>
                </View>

                <View
                  style={{
                    display: "table",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    height: "30px",
                  }}
                >
                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsEmprestimos[8].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsEmprestimos[8].name}
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsEmprestimos[9].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsEmprestimos[9].name}
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsEmprestimos[10].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsEmprestimos[10].name}
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsEmprestimos[11].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsEmprestimos[11].name}
                    </Text>
                  </View>
                </View>
              </View>

              <View>
                <View style={[styles.tableCol]}>
                  <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                    DATA
                  </Text>
                </View>

                <View
                  style={{
                    display: "table",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    height: "30px",
                  }}
                >
                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsEmprestimos[12].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsEmprestimos[12].name}
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsEmprestimos[13].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsEmprestimos[13].name}
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsEmprestimos[14].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsEmprestimos[14].name}
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsEmprestimos[15].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsEmprestimos[15].name}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            {contratosEmprestimo?.map((emprestimo, index) => (
              <View style={[styles.tableRow]} key={index}>
                <View
                  style={[
                    styles.tableCol,
                    {
                      width: columnsEmprestimos[0].width,
                      display: "flex",
                      flexWrap: "wrap",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {emprestimo?.contrato}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[1].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {emprestimo?.banco.codigo} - {emprestimo?.banco.descricao}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[2].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {emprestimo?.situacao}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[3].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    Averbação nova
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[4].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {emprestimo?.dataInclusao}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[5].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {emprestimo?.competenciaInicioDesconto.substring(4) +
                      "/" +
                      emprestimo?.competenciaInicioDesconto.substring(0, 4)}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[6].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {emprestimo?.competenciaFimDesconto.substring(4) +
                      "/" +
                      emprestimo?.competenciaFimDesconto.substring(0, 4)}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[7].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {emprestimo?.quantidadeParcelas}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[8].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {emprestimo?.valorParcela.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[9].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    ---
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[10].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {emprestimo?.valorEmprestado.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[11].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {emprestimo?.valorLiberado.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[12].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    ---
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[13].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    ---
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[14].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    ---
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    { width: columnsEmprestimos[15].width },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    ---
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <Text
            style={{ fontSize: "8pt", fontFamily: "Helvetica", padding: 5 }}
          >
            Contratos que comprometem a margem consignável.
          </Text>
        </View>

        {/**CARTÃO DE CRÉDITO - RMC*/}
        <View style={styles.bodyPdf} break>
          <Text style={{ fontSize: "11pt", paddingBottom: "10px" }}>
            CARTÃO DE CRÉDITO
          </Text>

          <View style={[styles.table, { width: widthCartoesC + "in" }]}>
            <View style={styles.tableCol}>
              <Text
                style={[
                  styles.tableCell,
                  {
                    fontSize: "8pt",
                    fontWeight: "bold",
                    padding: "5px 0 5px 0",
                  },
                ]}
              >
                CARTÃO DE CRÉDITO - RMC
              </Text>

              <View style={{ borderTop: "1px solid black" }}>
                <Text
                  style={[
                    {
                      fontSize: "12pt",
                      padding: "10px 0 10px 20px",
                      color: "#929292",
                      textAlign: "left",
                      backgroundColor: "#dcdcdc",
                    },
                  ]}
                >
                  CONTRATOS ATIVOS E SUSPENSOS*
                </Text>
              </View>
            </View>

            <View style={[styles.tableRow, { backgroundColor: "#dcdcdc" }]}>
              <View
                style={[styles.tableCol, { width: columnsCartoes[0].width }]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsCartoes[0].name}
                </Text>{" "}
                {/*CONTRATO*/}
              </View>

              <View
                style={[styles.tableCol, { width: columnsCartoes[1].width }]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsCartoes[1].name}
                </Text>
                {/*BANCO*/}
              </View>

              <View
                style={[styles.tableCol, { width: columnsCartoes[2].width }]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsCartoes[2].name}
                </Text>
                {/*SITUACAO**/}
              </View>

              <View
                style={[styles.tableCol, { width: columnsCartoes[3].width }]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsCartoes[3].name}
                </Text>{" "}
                {/*ORIGEM DA AVERBAÇÃO*/}
              </View>

              <View
                style={[styles.tableCol, { width: columnsCartoes[4].width }]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsCartoes[4].name}
                </Text>
                {/*DATA INCLUSÃO*/}
              </View>

              <View>
                <View style={[styles.tableCol]}>
                  <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                    VALOR
                  </Text>
                </View>

                <View
                  style={{
                    display: "table",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    height: "30px",
                  }}
                >
                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsCartoes[5].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsCartoes[5].name}
                    </Text>{" "}
                    {/*LIMITE DE CARTAO*/}
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsCartoes[6].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsCartoes[6].name}
                    </Text>{" "}
                    {/*RESERVADO*/}
                  </View>
                </View>
              </View>

              <View>
                <View style={[styles.tableCol]}>
                  <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                    DATA
                  </Text>
                </View>

                <View
                  style={{
                    display: "table",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    height: "30px",
                  }}
                >
                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsCartoes[7].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsCartoes[7].name}
                    </Text>
                    {/*SUSPENSAO BANCO*/}
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsCartoes[8].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsCartoes[8].name}
                    </Text>
                    {/*SUSPENSAO INSS*/}
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsCartoes[9].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsCartoes[9].name}
                    </Text>
                    {/*REATIVACAO BANCO*/}
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsCartoes[10].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsCartoes[10].name}
                    </Text>
                    {/*REATIVACAO INSS*/}
                  </View>
                </View>
              </View>
            </View>

            {contratosRMC?.map((rmc, index) => (
              <View style={[styles.tableRow]} key={index}>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[0].width }]}
                >
                  <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                    {rmc?.contrato}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[1].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {rmc?.banco.codigo} - {rmc?.banco.descricao}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[2].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {rmc?.situacao}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[3].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    Averbação nova
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[4].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {rmc?.dataInclusao}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[5].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {rmc?.valorLimiteCartao.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[6].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {rmc?.valorReservado.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[7].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    ---
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[8].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    ---
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[9].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    ---
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[10].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    ---
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <Text
            style={{ fontSize: "8pt", fontFamily: "Helvetica", padding: 5 }}
          >
            Contratos que comprometem a margem consignável.
          </Text>
        </View>

        {/**CARTÃO DE CRÉDITO - RCC*/}
        <View style={styles.bodyPdf}>
          <View style={[styles.table, { width: widthCartoesC + "in" }]}>
            <View style={styles.tableCol}>
              <Text
                style={[
                  styles.tableCell,
                  {
                    fontSize: "8pt",
                    fontWeight: "bold",
                    padding: "5px 0 5px 0",
                  },
                ]}
              >
                CARTÃO DE CRÉDITO - RCC
              </Text>

              <View style={{ borderTop: "1px solid black" }}>
                <Text
                  style={[
                    {
                      fontSize: "12pt",
                      padding: "10px 0 10px 20px",
                      color: "#929292",
                      textAlign: "left",
                      backgroundColor: "#dcdcdc",
                    },
                  ]}
                >
                  CONTRATOS ATIVOS E SUSPENSOS*
                </Text>
              </View>
            </View>

            <View style={[styles.tableRow, { backgroundColor: "#dcdcdc" }]}>
              <View
                style={[styles.tableCol, { width: columnsCartoes[0].width }]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsCartoes[0].name}
                </Text>{" "}
                {/*CONTRATO*/}
              </View>

              <View
                style={[styles.tableCol, { width: columnsCartoes[1].width }]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsCartoes[1].name}
                </Text>
                {/*BANCO*/}
              </View>

              <View
                style={[styles.tableCol, { width: columnsCartoes[2].width }]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsCartoes[2].name}
                </Text>
                {/*SITUACAO**/}
              </View>

              <View
                style={[styles.tableCol, { width: columnsCartoes[3].width }]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsCartoes[3].name}
                </Text>{" "}
                {/*ORIGEM DA AVERBAÇÃO*/}
              </View>

              <View
                style={[styles.tableCol, { width: columnsCartoes[4].width }]}
              >
                <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                  {columnsCartoes[4].name}
                </Text>
                {/*DATA INCLUSÃO*/}
              </View>

              <View>
                <View style={[styles.tableCol]}>
                  <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                    VALOR
                  </Text>
                </View>

                <View
                  style={{
                    display: "table",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    height: "30px",
                  }}
                >
                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsCartoes[5].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsCartoes[5].name}
                    </Text>{" "}
                    {/*LIMITE DE CARTAO*/}
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsCartoes[6].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsCartoes[6].name}
                    </Text>{" "}
                    {/*RESERVADO*/}
                  </View>{" "}
                  ,height:'30px'
                </View>
              </View>

              <View>
                <View style={[styles.tableCol]}>
                  <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                    DATA
                  </Text>
                </View>

                <View
                  style={{
                    display: "table",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    height: "30px",
                  }}
                >
                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsCartoes[7].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsCartoes[7].name}
                    </Text>
                    {/*SUSPENSAO BANCO*/}
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsCartoes[8].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsCartoes[8].name}
                    </Text>
                    {/*SUSPENSAO INSS*/}
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsCartoes[9].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsCartoes[9].name}
                    </Text>
                    {/*REATIVACAO BANCO*/}
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      { width: columnsCartoes[10].width, height: "30px" },
                    ]}
                  >
                    <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                      {columnsCartoes[10].name}
                    </Text>
                    {/*REATIVACAO INSS*/}
                  </View>
                </View>
              </View>
            </View>

            {contratosRCC?.map((rcc, index) => (
              <View style={[styles.tableRow]} key={index}>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[0].width }]}
                >
                  <Text style={[styles.tableCell, { fontSize: "6pt" }]}>
                    {rcc?.contrato}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[1].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {rcc?.banco.codigo} - {rcc?.banco.descricao}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[2].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {rcc?.situacao}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[3].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    Averbação nova
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[4].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {rcc?.dataInclusao}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[5].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {rcc?.valorLimiteCartao.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[6].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    {rcc?.valorReservado.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[7].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    ---
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[8].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    ---
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[9].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    ---
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { width: columnsCartoes[10].width }]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { fontSize: "6pt", padding: "4px" },
                    ]}
                  >
                    ---
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <Text
            style={{ fontSize: "8pt", fontFamily: "Helvetica", padding: 5 }}
          >
            Contratos que comprometem a margem consignável.
          </Text>
        </View>

        {/**Rodapé */}
        <View style={styles.footer} fixed>
          <View style={styles.lineWidth} />

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Image
                src={qrAutentica}
                style={{ width: "1.21in", height: "1.21in" }}
              />

              <View>
                <Text style={{ fontSize: "10pt" }}>
                  Você pode conferir a autenticidade do documento em
                </Text>

                <Link src="https://meu.inss.gov.br/central/#/autenticidade">
                  <Text style={{ fontSize: "10pt" }}>
                    https://meu.inss.gov.br/central/#/autenticidade
                  </Text>
                </Link>

                <Text style={{ fontSize: "10pt" }}>
                  com o código 230713LUB85Z1FTU5NTU0X
                </Text>

                <Text style={{ fontSize: "10pt", marginTop: "10px" }}>
                  {new Date().toLocaleString()}
                </Text>
              </View>
            </View>

            <View>
              <Image
                src={inssImg}
                style={{ width: "1.25in", padding: "5px" }}
              />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
  //const [instance, updateInstance] = usePDF({document: PdfDataTags})
  //return await instance.url

  const blob = await pdf(PdfDataTags).toBlob()

  const url = window.URL.createObjectURL(blob)
  window.open(url, "_blank")
}
