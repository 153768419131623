import React, { useContext } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { AuthContext, AuthProvider } from "../context/auth"

import Dashboard from "../components/Dashboard"
import { AtualizarSenha } from "../pages/AtualizarSenha"
import Login from "../pages/CadastroLogin"
import { CadastroUsuario } from "../pages/CadastroUsuario"
import { ConfiguracaoSistema } from "../pages/Configuracao"
import { ManutencaoCredito } from "../pages/Credito"
import { DesbloqueioLiberar } from "../pages/DesbloqueioLiberar"
import { DesbloqueioSolicitar } from "../pages/DesbloqueioSolicitar"
import { ExtratoOnline } from "../pages/ExtratoOnline"
import { Fornecedor } from "../pages/Fornecedor"
import HomePage from "../pages/HomePage"
import { In100 } from "../pages/In100"
import LoginPage from "../pages/LoginPage"
import { ManutencaoMenu } from "../pages/ManutencaoMenu"
import { ManutencaoServicos } from "../pages/ManutencaoServicos"
import { ModulosSistema } from "../pages/ModulosSistema"
import { PerfilModulos } from "../pages/PerfilModulos"
import { PerfilUsuario } from "../pages/PerfilUsuario"
import { RelatorioConsultas } from "../pages/RelatorioConsultas"
import { RelatorioTransacoes } from "../pages/RelatorioTransacoes"
import Siape from "../pages/Siape"
import { SolicitacaoSenha } from "../pages/SolicitacaoSenha"

export default function AppRoutes() {
  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext)

    if (loading) {
      return <div className="loading"> Carregando...</div>
    }

    if (!authenticated) {
      return <Navigate to="/" />
    }

    return children
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route exact path="/" element={<LoginPage />} />
          <Route
            exact
            path="/home"
            element={
              <Private>
                <HomePage display={true} page={<Dashboard />} title="" />
              </Private>
            }
          />

          <Route
            exact
            path="/beneficio"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<DesbloqueioSolicitar />}
                  title="Desbloqueio Solicitar"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/desbloqueio"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<DesbloqueioLiberar />}
                  title="Desbloqueio Liberar"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/cad-login"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<Login />}
                  title="Cadastro de Usuários"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/extratopdf"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<ExtratoOnline />}
                  title="Extrato Online"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/perfilusuario"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<PerfilUsuario />}
                  title="Perfil de Usúario"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/credito"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<ManutencaoCredito />}
                  title="Manutenção de Crédito"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/extratoconsultas"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<RelatorioConsultas />}
                  title="Relatorio de utilização"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/in100"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<In100 />}
                  title="Consultar Serviços"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/manutencaousuario"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<CadastroUsuario />}
                  title="Manutenção de usúarios"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/manutencaoperfilmodulos"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<PerfilModulos />}
                  title="Manutenção de Perfil/Modulos"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/modulos"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<ModulosSistema />}
                  title="Cadastro de modulos do sistema"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/admin/menu"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<ManutencaoMenu />}
                  title="Manutenção menu do sistema"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/admin/configuracao"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<ConfiguracaoSistema />}
                  title="Configuração do sistema"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/listar_transacoes"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<RelatorioTransacoes />}
                  title="Transações do Sistema"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/manutencao-servicos"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<ManutencaoServicos />}
                  title="Cadastro/Edição de Serviços"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/extrato-siape"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<Siape />}
                  title="Extrato offline Siape"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/fornecedor"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<Fornecedor />}
                  title="Listar Fornecedores"
                />
              </Private>
            }
          />

          <Route
            exact
            path="/solicitacao-senha"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<SolicitacaoSenha />}
                  title="Solicitar Senha"
                />
              </Private>
            }
          />
          <Route
            exact
            path="/atualizar-senha"
            element={
              <Private>
                <HomePage
                  display={true}
                  page={<AtualizarSenha />}
                  title="Atualizar Senha"
                />
              </Private>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}
