export const IIn100 = {
    NumeroSolicitacao:"",
    CPF: "",
    NumeroBeneficio: "",
    Situacao: "",
    ErrorID: "", 
    BeneficiarioID: "",
    Cpf: "",
    Nome: "",
    DataNascimento: "",
    EmprestimoBloqueado: "",
    EmprestimoElegivel: "",
    DIB: "",
    BeneficioID: "",
    MargemConsignavel: "",
    RMCAtivo: "",
    UFBeneficio: "",
    MeioPagamentoID: "",
    InstituicaoFinanceiraID: "",
    NomeAgencia: "",
    NumeroAgencia: "",
    NumeroContaCorrente: "",
    DataAtualizacao: "",
    MensagemServidor: "",
    DDB: "",
    RequisicaoID: "",        
    OrigemBancoID: "",
    MargemConsignavelCartao: "",
    QtdEmprestimosAtivosSuspensos: "",
    PossuiRepresentanteLegal: "",
    PossuiProcurador: "",
    ValorLimiteCartao: "",
    ValorLimiteCartaoBeneficio: "",
    ValorMargemDisponivelCartaoBeneficio: "",
    CpfRepresentanteLegal: "",
    NomeRepresentanteLegal: "",
    Idade: "" 
}

