import { api } from "../services/api";

export const extrato_offline_siape = async ( pCpf, pMatricula ) =>{

    api.defaults.headers.tiposervico = 9;

    const options = {
        params:{
            pCpf,
            pMatricula
        }
    }

    return await api.get('/api/siape/extrato/offline',options)

}

export const getConsultasSiape = async(offset, filters)=>{

    const options = {
        params:{
            filters,
            offset
        }
    }

    return await api.get('/api/siape/listarconsultas',options)
}