import { Buttons } from "../Button"
import styles from "./Dialog.module.css"

export function AlertDialog(props) {
  return (
    <div className={styles.modal_mensage}>
      <div className={styles.box_mensage}>
        <div className={styles.dialog}>
          <span>{props.text}</span>

          <div className={styles.buttons}>
            <Buttons onClick={() => props.onDialog(true)} text="Sim" />

            <Buttons onClick={() => props.onDialog(false)} text="Não" />
          </div>
        </div>
      </div>
    </div>
  )
}

export function Alert(props) {
  return (
    <div className={styles.modal_mensage}>
      <div className={styles.box_mensage}>
        <div className={styles.dialog}>
          <span>{props.text}</span>
          <div className={styles.buttons}>
            <Buttons onClick={() => props.onDialog(false)} text="OK" />
          </div>
        </div>
      </div>
    </div>
  )
}
