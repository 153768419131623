import styles from "./Table.module.css"

export const Table = ({headers=[], body})=>{
   
    return(
        <div>
            <table>
                    <thead>
                        <tr>
                            {headers.map(dt=>(
                                <th>{dt}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                           {body}   
                    </tbody>
                </table>
        </div>
    )
}