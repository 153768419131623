// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePage_body_home__RrkX6 {
  width: 100%;
  background-color: #fafbfe;
  display: flex;
  justify-content: space-between;
}

.HomePage_body_left__MOD9g {
  width: 25%;
}

.HomePage_body_right__RN\\+mg {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .HomePage_body_left__MOD9g {
    display: none;
  }

  .HomePage_body_right__RN\\+mg {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/HomePage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,aAAa;EACb,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".body_home {\r\n  width: 100%;\r\n  background-color: #fafbfe;\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.body_left {\r\n  width: 25%;\r\n}\r\n\r\n.body_right {\r\n  width: 75%;\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  flex-direction: column;\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .body_left {\r\n    display: none;\r\n  }\r\n\r\n  .body_right {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body_home": `HomePage_body_home__RrkX6`,
	"body_left": `HomePage_body_left__MOD9g`,
	"body_right": `HomePage_body_right__RN+mg`
};
export default ___CSS_LOADER_EXPORT___;
