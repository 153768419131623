import { api } from "../services/api";

/*export const getObterIn100 = async(tiposervico, pNumeroRequisicao, NumeroBeneficio,cpf)=>{
    api.defaults.headers.tiposervico = tiposervico;
  
    const options ={
      params:{      
        pNumeroRequisicao,
        NumeroBeneficio,
        cpf
      }
    }
  
    return await api.get("/api/inss/IN100/obter", options )
    
}*/
  
export const getObterRequisicao = async( paramsSolicIn100 )=>{
  
  console.log(paramsSolicIn100)
    api.defaults.headers.tiposervico = paramsSolicIn100.tiposervico;
  
    const options ={
      params:{      
        pCpf : paramsSolicIn100.cpf,
        pNumeroBeneficio : paramsSolicIn100.beneficio,
        idConsultaOffline : paramsSolicIn100.idConsultaOff,
        pCpfRepresentante : paramsSolicIn100.cpfRepresentanteLegal
      }
    }
  
    return await api.get("/api/extrato/in100", options )
    
}

export const getStatusIn100 = async()=>{

  return await api.get("/api/status-in100" )
}

export const refazerConsulta = async({id, cpfR="" })=>{
  const options ={
      idIn100:id,
      cpfR     
    }
  

  return await api.put("/api/in100/refazer_consulta", options )
  
}