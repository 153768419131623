import { useEffect, useState } from "react"
import { Buttons } from "../../components/Button"
import { InputSelect, InputText } from "../../components/Inputs"
import { Loading } from "../../components/Loading"
import { deleteMenu, getAllMenu, salvarMenu } from "../../functions/menu"
import { getModulos } from "../../functions/modulos_sistema"
import styles from "./ManutencaoMenu.module.css"
import Paginacao from "../../components/Paginacao"
export const ManutencaoMenu = () => {
  const [loading, setLoading] = useState(false)
  const [lstMenu, setLstMenu] = useState([])
  const [lstModulos, setLstModulos] = useState([])
  const [menu, setMenu] = useState({
    codsequencia: 0,
    desc_modulo: "",
    descricao: "",
    id: 0,
    idmodulo: 0,
    pagina: "",
    rota: "",
  })

  const [modalMenu, setModalMenu] = useState(false)
  const [number, setNumber] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 10,
  })

  useEffect(() => {
    setLoading(true)
    getAllMenu(currentPage)
      .then(resp => {
        setLstMenu(resp.data.items)
      })
      .catch(erro => {
        console.log(erro)
        setLoading(false)
      })

    getModulos()
      .then(resp => {
        let modulos = []
        resp.data.items.map(mod => {
          modulos.push({ id: mod.id, descricao: mod.desc_modulo })
        })

        setLoading(false)
        setLstModulos(modulos)
      })
      .catch(erro => {
        console.log(erro)
        setLoading(false)
      })
  }, [number, currentPage])

  const handleMenuSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    await salvarMenu(menu)
      .then(res => {
        console.log(res.data)
        setNumber(number + 1)
        setModalMenu(false)
        setLoading(false)
      })
      .catch(erro => {
        console.log(erro)
        setLoading(false)
      })
  }

  const handleonChangeMenu = e => {
    setMenu({ ...menu, [e.target.name]: e.target.value })
  }

  const handleEditMenu = men => {
    setMenu(men)
    setModalMenu(true)
  }

  const handleExclMenu = async id => {
    setLoading(true)
    await deleteMenu(id)
      .then(res => {
        console.log(res.data)
        setNumber(number + 1)
        setLoading(false)
      })
      .catch(erro => {
        console.log(erro)
        setLoading(false)
      })
  }

  const handleValidNovoMenu = () => {
    setMenu({})
    setModalMenu(true)
  }
  const handlePageChange = newPage => {
    setLoading(true)
    setCurrentPage(newPage)
  }

  return (
    <div className={styles.container_manut_menu}>
      {loading && <Loading />}
      <div className={styles.seccion_table_menu}>
        <table>
          <thead>
            <tr>
              <th>Sequência</th>
              <th>Descrição</th>
              <th>Pagina</th>
              <th>Rota</th>
              <th>Modulo</th>
              <th>#</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {lstMenu.map(menu => (
              <tr key={menu.id}>
                <td>{menu.codsequencia}</td>
                <td>{menu.descricao}</td>
                <td>{menu.pagina}</td>
                <td>{menu.rota}</td>
                <td>{menu.desc_modulo}</td>

                <td
                  className={styles.column_del}
                  onClick={() => handleExclMenu(menu.id)}
                >
                  <i className="fa-solid fa-trash"></i>
                </td>
                <td
                  className={styles.column_edit}
                  onClick={() => handleEditMenu(menu)}
                >
                  <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.seccion_btn_novo}>
        <Buttons text="Criar novo item" onClick={handleValidNovoMenu} />
      </div>

      {modalMenu && (
        <div className={styles.form_modal}>
          <div className={styles.containe_modal}>
            <div className={styles.head_form_modal}>
              <h1>Menu</h1>
              <i
                className="fa-sharp fa-solid fa-xmark"
                onClick={() => setModalMenu(false)}
              ></i>
            </div>

            <form onSubmit={handleMenuSubmit}>
              <InputText
                text="Sequência"
                type="number"
                value={menu.codsequencia}
                name="codsequencia"
                handleonChange={handleonChangeMenu}
                required={true}
              />

              <InputText
                text="Descrição"
                value={menu.descricao}
                name="descricao"
                handleonChange={handleonChangeMenu}
                required={true}
              />

              <InputText
                text="Página"
                value={menu.pagina}
                name="pagina"
                handleonChange={handleonChangeMenu}
                required={true}
              />

              <InputText
                text="Rota"
                value={menu.rota}
                name="rota"
                handleonChange={handleonChangeMenu}
                required={true}
              />

              <InputSelect
                text="Modulo"
                name="idmodulo"
                onValue={menu.idmodulo}
                options={lstModulos}
                handleOnChange={handleonChangeMenu}
              />

              <Buttons text="Salvar" />
            </form>
          </div>
        </div>
      )}
      <Paginacao pagination={pagination} onPageChange={handlePageChange} />
    </div>
  )
}
