import { api } from "../services/api";


 export const getConfigPeriodoAtend = async ()=>{

    return api.get("/admin/config")
  }
  
  export const putConfigPeriodoAtend = async (data)=>{
    
    return api.put("/admin/config", data)
  }
  