import styles from "./FormModal.module.css"

export const FormModal = ({ title, form, closeForm }) => {
  return (
    <div>
      <div className={styles.form_modal}>
        <div className={styles.containe_modal}>
          <div className={styles.head_form_modal}>
            <h2 class="mb-4 text-xl font-bold text-gray-900 dark:text-white">
              {title && title.toLocaleUpperCase()}
            </h2>
            <i className="fa-sharp fa-solid fa-xmark" onClick={closeForm}></i>
          </div>

          <div className={styles.container_form}>{form}</div>
        </div>
      </div>
    </div>
  )
}
