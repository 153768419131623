import React from "react"

import { Menu } from "../../components/Menu"
import { NavBar } from "../../components/NavBar"
import styles from "./HomePage.module.css"

const HomePage = props => {
  return (
    <div className={`sidebar-container`}>
      {/*   <NavBar /> */}

      <div className={styles.body_home}>
        <div className={styles.body_left}>
          <Menu />
        </div>

        <section className={styles.body_right}>
          <NavBar />
          <div
            style={{
              padding: 10,
            }}
          >
            {props.page}
          </div>
        </section>

        {/*<div>
          {props.display ? (
            <Modal title={props.title}>{props.page}</Modal>
          ) : null}
        </div>*/}
      </div>
    </div>
  )
}
export default HomePage
