import axios from "axios";

const user = JSON.parse( localStorage.getItem('user') )
export const api = axios.create({
    baseURL:process.env.REACT_APP_URL_API,
    headers:{
        Authorization: `Bearer: ${user && user.token}`,
        'Content-Type': 'application/json',
    },
})



