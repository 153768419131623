// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.siape_container_siape__AKvKh{
    width: 100%;
    margin: 10px;
}

.siape_cabecalho__RenLG{    
    padding: 5px;
    display: flex;
    align-items: center;
    border: 1px solid grey;
    margin-bottom: 10px;
}

.siape_btnDetalheSiape__Faz5w{
    cursor: pointer;    
}

.siape_container_detalhes_siape__k57Pn{
    width: 85vw;
}

.siape_section_data__18rer{
    width: 100%;
    border: 1px solid grey;
    padding: 10px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;

    @media (max-width:768px) {
        grid-template-columns: 1fr;       
    }
}

.siape_section_data__18rer, .siape_section_emprestimos__q7RIZ  legend{
    color: rgb(80, 80, 80);
}

.siape_section_margem__Lk2-u{
    border: 1px solid grey;
    padding: 10px;
}
.siape_section_emprestimos__q7RIZ{
    border: 1px solid grey;
    padding: 5px; 
    margin-top: 10px;
}


table thead{
    text-align: left;
}

table thead th{
    padding: 10px 5px 10px 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Siape/siape.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,kCAAkC;IAClC,cAAc;;IAEd;QACI,0BAA0B;IAC9B;AACJ;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;IACtB,aAAa;AACjB;AACA;IACI,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;;AAGA;IACI,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".container_siape{\r\n    width: 100%;\r\n    margin: 10px;\r\n}\r\n\r\n.cabecalho{    \r\n    padding: 5px;\r\n    display: flex;\r\n    align-items: center;\r\n    border: 1px solid grey;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.btnDetalheSiape{\r\n    cursor: pointer;    \r\n}\r\n\r\n.container_detalhes_siape{\r\n    width: 85vw;\r\n}\r\n\r\n.section_data{\r\n    width: 100%;\r\n    border: 1px solid grey;\r\n    padding: 10px;\r\n    margin-top: 10px;\r\n    display: grid;\r\n    grid-template-columns: 1fr 1fr 1fr;\r\n    grid-gap: 10px;\r\n\r\n    @media (max-width:768px) {\r\n        grid-template-columns: 1fr;       \r\n    }\r\n}\r\n\r\n.section_data, .section_emprestimos  legend{\r\n    color: rgb(80, 80, 80);\r\n}\r\n\r\n.section_margem{\r\n    border: 1px solid grey;\r\n    padding: 10px;\r\n}\r\n.section_emprestimos{\r\n    border: 1px solid grey;\r\n    padding: 5px; \r\n    margin-top: 10px;\r\n}\r\n\r\n\r\ntable thead{\r\n    text-align: left;\r\n}\r\n\r\ntable thead th{\r\n    padding: 10px 5px 10px 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_siape": `siape_container_siape__AKvKh`,
	"cabecalho": `siape_cabecalho__RenLG`,
	"btnDetalheSiape": `siape_btnDetalheSiape__Faz5w`,
	"container_detalhes_siape": `siape_container_detalhes_siape__k57Pn`,
	"section_data": `siape_section_data__18rer`,
	"section_emprestimos": `siape_section_emprestimos__q7RIZ`,
	"section_margem": `siape_section_margem__Lk2-u`
};
export default ___CSS_LOADER_EXPORT___;
