import styles from "./Pagination.module.css"
import { Buttons } from "../Button";

const MAX_ITENS = 9
const MAX_LEFT = (MAX_ITENS - 1) /2

export const Pagination = ({limit, total, offset, setOffset} )=>{
    
    const current = offset ? offset / limit + 1 : 1
    const pages = Math.ceil(total / limit)
    const first = Math.max( current - MAX_LEFT, 1)


    return(
        
        <div className={styles.container_pagination}>
          <ul className={styles.paginations}>
              {Array.from({ length: Math.min( MAX_ITENS, pages) })
              .map((_, index )=>index + first)
              .map( (page) =>(
                  <li key={page}>
                                  
                   <Buttons                         
                        onClick={()=> setOffset((page - 1) * limit)}                        
                        text= {page}
                   />                    
                                                                         
                   </li>
              ))}
          </ul>
        </div>
        
    )
}