import React from 'react';
import {InputText} from '../../components/Inputs'
import { Buttons} from '../../components/Button'
import styles from './siape.module.css'
import { extrato_offline_siape, getConsultasSiape } from '../../functions/consulta_offline_siape';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {Loading} from '../../components/Loading'
import {Alert} from '../../components/AlertDialog'
import {Pagination} from '../../components/Pagination'
import {FormModal} from '../../components/FormModal'
import DetalhesSiape from './detalhes_siape';

const Siape = () => {
        
    const [cpf, setCpf]=useState()
    const [offset, setOffset] = useState(0)
    const [pgDetSiape, setPgDetSiape]= useState(false)
    const [siape, setSiape]=useState({})

    const {data, isLoading, error, refetch} = useQuery(['siape',offset], async()=>{
        const {data} = await getConsultasSiape(offset, {});        
        return data  
    })
    
    const mutation = useMutation({
        mutationFn:async (cpf)=>{
            return await extrato_offline_siape(cpf, "").then((response)=>response.data);
        },
        onSuccess: (data) =>{
            refetch();
        },
        onError:(error)=>{
            console.log(error)
        }
    })

    const detalhes_siape = (dados)=>{
        setSiape(dados)
        
        setPgDetSiape(true)
        
    }

    return (
               
        <section className={styles.container_siape}>   

        {isLoading && <Loading />}
        {mutation.isLoading && <Loading />}
        {error && <Alert text={error.response.data.mensagem} onDialog={false} />}

        <fieldset className={styles.cabecalho}>
            <legend>Parêmentros de pesquisa</legend>

            <InputText 
                type="search"            
                text="CPF"
                handleonChange={(e)=>setCpf(e.target.value)}
            />

            <Buttons 
                text= {"Buscar"}
                onClick ={()=> mutation.mutate(cpf)}
            />

        </fieldset>


        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Data/Hora</th>
                    <th>Matrícula</th>
                    <th>Nome</th>
                    <th>Órgão</th>
                    <th>...</th>
                </tr>
            </thead>    
            <tbody>
                {
                   data && data.records.map((matricula)=>(
                    <tr key={matricula.id}>
                        <td>{matricula.id}</td>
                        <td>{new Date( matricula.datahora).toLocaleString('pt-BR') }</td>
                        <td>{matricula.matricula_siape}</td>
                        <td>{matricula.resultado[0].Nome}</td>
                        <td> {matricula.resultado[0].CodigoOrgao} - {matricula.resultado[0].DescricaoOrgao}</td>
                        <td>
                            <div 
                                title='Detalhes da consulta'
                                className={styles.btnDetalheSiape}
                                onClick={()=> detalhes_siape(matricula)}
                            >
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </div>                            
                        </td>
                    </tr>
                   ))
                }
            </tbody>
        </table>

       { pgDetSiape && <FormModal 
            title={"Detalhes Siape"}
            form={<DetalhesSiape dados={siape} />}
            closeForm={()=>setPgDetSiape(false)}
        />}

        

        {data &&

        <Pagination 
            limit ={3}
            total ={ data.rows }
            offset = { offset }
            setOffset ={ setOffset }  
        />}

        </section>
        
  );
}

export default Siape;
