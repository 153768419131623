import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logoPrev from "../../assets/images/logo_previdencia.png"

pdfMake.vfs = pdfFonts.pdfMake.vsf;

function comprovantePDF(dadosComprovante){
        

    let dataHora = "";
    if( dadosComprovante.datahora.indexOf("T") > 0 ){
        dataHora =  new Date(  dadosComprovante.datahora.replace("T"," ").replace("Z","") ).toLocaleString("pt-br")
    }else{
        dataHora = dadosComprovante.datahora 
    }   

    const reportTitle = [
      
        {
            
            height: 150,
            alignment:'justify',
            margin: [10, 10, 10, 10],
            layout:"border",
            columns:[
                {
                    image:logoPrev,
                    width: 100,                                       
                },              
                {
              	
                    stack: [
                            {text:'PROTOCOLO DE REQUERIMENTO', fontSize: 9, alignment :'center' , color:"#359bd6", bold:true},
                            {
                                text:dadosComprovante.protocolo,
                                fontSize: 20,
                                alignment :'center',
                                bold:true,
                                
                            },
                            {
                                text:"Data de Entrada do Requerimento:  "+dataHora,
                                fontSize: 8, alignment :'center'
                            },
                            { text:"Central de Serviços - Internet", fontSize: 8, alignment :'center'}
                    ],
                
                },
                
                
                
                
            ]
        }

    ];

  

    const details1 = [
      
        { text: '\n\n\n\nCOMPROVANTE DO PROTOCOLO DE REQUERIMENTO', fontSize: 10 , alignment:'center', color:"#359bd6", bold:true},
        { text:"Requerente", fontSize: 9, color:"#359bd6", bold:true},
        { text: dadosComprovante.requerente.toUpperCase(), fontSize: 18, lineHeight:1, bold:true },
        { text:"Serviço", fontSize: 9, color:"#359bd6", bold:true},
        { text:"Bloquear/Desbloquear Benefício para Empréstimo Consignado", fontSize: 11, lineHeight:3},
        {   
            alignment:"center",
            columns:[
                {                       
                    stack:[
                        {text:"Unidade de Protocolo",fontSize:9 , color:"#359bd6", bold:true},
                        {text:dadosComprovante.unidade,fontSize:15}
                    ]
                    
                },
                {
                    stack:[
                    {text:"Protocolo realizado em ",fontSize:9, color:"#359bd6", bold:true},
                    {text:dataHora, fontSize:15}
                    ]
                }
            ]
        }        
    ]


    const detail2 = [
        {text:"\n\n\n"}, 
        {
           
            columns:[
                   
                {                
                    stack:[   
                        {text:"Dados do Requerente", fontSize:9, color:"#359bd6", bold:true},
                        {text:"Nome: "+ dadosComprovante.requerente},
                        {text:"\nCPF: "+ dadosComprovante.cpf},
                        {text:"\nCampos Adicionais", fontSize:9, bold:true,color:"#359bd6" },
                        {text:"N.B: "+ dadosComprovante.beneficio}
                    ]
                },
                {
                    stack:[
                        { text:"Requerente aceita acompanhar o andamento do processo pelo Meu INSS, Central 135 ou e-mail: SIM \n\n", fontSize:10, bold:true },
                        { text:"1) Deseja cadastrar Procurador ou Representante Legal para este pedido?: A) Não, eu sou o(a) titular \n\n",fontSize:9 },
                        { text:"2) Marque a seguir a opção que deseja:: B) Desbloqueio para empréstimo", fontSize:9 }
                    ]
                }
            ]
        }
    ]

    const detail3 =[
        { text:"\n\n\n\nInformações Adicionais", fontSize:9, color:"#359bd6", bold:true },
        { text:"Informações Gerais:", fontSize:10, bold:true },
        { text:"Caso sua solicitação envolva valores financeiros, seus direitos estão garantidos desde a data do pedido.", fontSize:10  },
        {text:'\n'},
        {
            ul:[
                {text: 'Clique na opção "Consultar Pedidos"', fontSize:10},
                {text: 'Localize seu processo na página', fontSize:10},
                {text: 'Clique em "Detalhar" ', fontSize:10},
                {text: 'Telefone 135: De segunda a sábado, de 7h às 22h', fontSize:10},
            ]
        },
        {text:"\n\nCom a ativação do Bloqueio, não poderei mais realizar empréstimos nem contratar RMC/cartão de crédito que gerem descontos em meu beneficio", fontSize:10, }
    ]

    function Rodape(currentPage, pageCount){
        return [
            {
                text:`Com a ativação do Bloqueio, não poderei mais realizar empréstimos nem contratar RMC/cartão de crédito que gerem descontos em mebenefício.
                Concordo que os empréstimos já realizados continuarão sendo descontados.
                Para realizar novos empréstimos, devo pedir o Desbloqueio do meu benefício`,
                alignment :'center',
                fontSize: 9,                
                margin: [0, 10, 20, 0]
            }  
        ]
    };


    const docDefinition = {
        pageSize: 'A4',
        pageMargins: [20, 10, 20, 10],
        //header:[reportTitle],

        content:[reportTitle, details1, detail2, detail3 ],
        //footer:Rodape
    }


      

    pdfMake.createPdf(docDefinition).download(dadosComprovante.beneficio);

}

export default comprovantePDF;