// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManutencaoServicos_container_manut_servs__RrFNh{
    width: 100%;
    padding: 10px;
}

.ManutencaoServicos_campos_linha__hw\\+xV{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ManutencaoServicos_btnEdit__CM7jN{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/ManutencaoServicos/ManutencaoServicos.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".container_manut_servs{\r\n    width: 100%;\r\n    padding: 10px;\r\n}\r\n\r\n.campos_linha{\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: space-between;\r\n}\r\n\r\n.btnEdit{\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_manut_servs": `ManutencaoServicos_container_manut_servs__RrFNh`,
	"campos_linha": `ManutencaoServicos_campos_linha__hw+xV`,
	"btnEdit": `ManutencaoServicos_btnEdit__CM7jN`
};
export default ___CSS_LOADER_EXPORT___;
