import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getMenu } from "../../functions/menu"
import Logo from "../Logo"
import styles from "./Menu.module.css"

export const Menu = ({ onMenuItemClick }) => {
  const [itensMenu, setItensMenu] = useState([])

  useEffect(() => {
    ;(async () => {
      await getMenu()
        .then(resp => {
          setItensMenu(resp.data.items)
        })
        .catch(error => {
          console.error("Menu: ", error)
          return (
            error.response?.data.message && "Erro ao se conectar com servidor."
          )
        })
    })()
  }, [])

  return (
    <div>
      <nav className={styles.container_menu}>
        <div className={styles.img_logo}>
          <Logo w="30%" />
        </div>

        <menu>
          <Link
            className={styles.menuItem}
            key={0}
            to="/home"
            onClick={onMenuItemClick}
          >
            Home
          </Link>

          {itensMenu &&
            itensMenu.map(itenmenu => (
              <Link
                className={styles.menuItem}
                key={itenmenu.id}
                to={itenmenu.rota}
                onClick={onMenuItemClick}
              >
                {itenmenu.descricao}
              </Link>
            ))}
        </menu>
      </nav>
    </div>
  )
}
