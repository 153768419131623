import { useContext, useState } from "react"
import { Alert } from "../../components/AlertDialog"
import { Buttons } from "../../components/Button"
import { InputText } from "../../components/Inputs"
import { Loading } from "../../components/Loading"
import Logo from "../../components/Logo"
import { AuthContext } from "../../context/auth"
import { IAlert } from "../../interfaces/Alert"
import styles from "./Login.module.css"
export const LoginPage = () => {
  const { loginUser, loading } = useContext(AuthContext)

  const [user, setUser] = useState("")
  const [password, setpassword] = useState("")
  const [onReturn, setOnReturn] = useState("")
  const [onLoading, setOnLoading] = useState(false)
  const [alert, setAlert] = useState(IAlert)
  const handleSubmit = async e => {
    e.preventDefault()

    setOnLoading(true)
    try {
      const login = await loginUser(user, password)

      setOnLoading(false)
      if (login) {
        setAlert({
          visibled: true,
          message: login.message,
        })
        //setOnReturn(login.message ? login.message : login)
      } else {
        setAlert({
          visibled: true,
          message: "Não foi possivel realizar login",
        })
        //setOnReturn("Não foi possivel realizar login")
      }
    } catch (error) {
      console.error("login", error)
      setOnLoading(false)
      setAlert({
        visibled: true,
        message: `Não foi possivel realizar login.\n ${error.message} `,
      })
      //setOnReturn("Não foi possivel realizar login")
    }
  }

  return (
    <div className={styles.login}>
      {alert.visibled && (
        <Alert text={alert.message} onDialog={() => setAlert({})} />
      )}
      {onLoading && <Loading />}
      <div className={styles.form_container}>
        <div className={styles.header_form}>
          <Logo w="20%" />
        </div>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.form_input}>
            <InputText
              text="Usuário"
              name="user"
              value={user}
              type="text"
              placeholder="Digite o nome de usuário..."
              required={true}
              handleonChange={e => setUser(e.target.value)}
            />

            <InputText
              text="Senha"
              name="password"
              value={password}
              type="password"
              required={true}
              placeholder="Digite uma senha valida..."
              handleonChange={e => setpassword(e.target.value)}
            />

            <div className={styles.btn_login}>
              <Buttons text="Entrar" />
            </div>
          </div>
        </form>

        {onReturn && (
          <div className={styles.retorno}>
            <p>{onReturn}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default LoginPage
