export const FormatDateTime = (date, format)=> {

   const dia = new Date(date).getDate()
   const mes = new Date(date).getMonth() + 1
   const ano = new Date(date).getUTCFullYear()
   const hora = new Date(date).getHours()
   const min = new Date(date).getMinutes()
   const sec = new Date(date).getSeconds()
   
    switch (format) {
    case "dd/mm/yyyy":
        return `${dia}/${mes}/${ano}`
        
    
    case "dd/mm/yyyy hh:mm:ss":
        return `${ String(dia).padStart(2, '0') }/${String(mes).padStart(2, '0')}/${ano} ${hora}:${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`    
       

    case "yyyy-mm-dd hh:mm:ss":
        return `${ano}-${String(mes).padStart(2, '0')}-${String(dia).padStart(2, '0')} ${hora}:${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`    
          

    case "yyyymmddhhmmss":
        return `${ano}${String(mes).padStart(2, '0')}${String(dia).padStart(2, '0')}${hora}${String(min).padStart(2, '0')}${String(sec).padStart(2, '0')}`    
       
    default:
        return date
   }    
}
