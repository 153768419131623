import { api } from "../services/api"

export const getModulos = async () => {
  //api.defaults.headers.tiposervico = tpservico

  return await api.get("/modulos/listar")
}

export const postModulos = async modulo => {
  if (modulo.id > 0) {
    return await api.put("/modulos/alterar", modulo)
  } else {
    return await api.post("/modulos/cadastrar", modulo)
  }
}

export const deleteModulos = async id => {
  const options = {
    params: {
      id,
    },
  }

  return await api.delete("/modulos/deletar", options)
}

export const getPerfilModulos = async id => {
  let options = {
    params: {
      id,
    },
  }

  return api.get("/perfil/perfilmodulos", options)
}

export const delPerfilModulos = async (idperfil, idmodulo) => {
  let options = {
    params: {
      idperfil,
      idmodulo,
    },
  }

  return api.delete("/perfil/delPerfilmodulos", options)
}

export const postPerfilModulos = async dados => {
  return api.post("/perfil/addPerfilmodulos", dados)
}
