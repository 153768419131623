// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Paginacao_paginacao__1ReOC {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
}

.Paginacao_paginacao__1ReOC button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.Paginacao_paginacao__1ReOC button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.Paginacao_paginacao__1ReOC .Paginacao_active__F\\+vUa {
  font-weight: bold;
  background-color: #161616;
  color: #fff;
  border-color: #535353;
}

.Paginacao_paginacao__1ReOC .Paginacao_info__X\\+htK {
  margin-left: 20px;
}

@media screen and (max-width: 700px) {
  .Paginacao_paginacao__1ReOC {
    width: 100%;
  }

  .Paginacao_table_logins__oo3qa table thead {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Paginacao/Paginacao.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,sBAAsB;EACtB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,WAAW;EACb;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".paginacao {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  margin-top: 10px;\n}\n\n.paginacao button {\n  margin: 0 5px;\n  padding: 5px 10px;\n  border: 1px solid #ccc;\n  background-color: #fff;\n  cursor: pointer;\n}\n\n.paginacao button:disabled {\n  cursor: not-allowed;\n  opacity: 0.5;\n}\n\n.paginacao .active {\n  font-weight: bold;\n  background-color: #161616;\n  color: #fff;\n  border-color: #535353;\n}\n\n.paginacao .info {\n  margin-left: 20px;\n}\n\n@media screen and (max-width: 700px) {\n  .paginacao {\n    width: 100%;\n  }\n\n  .table_logins table thead {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginacao": `Paginacao_paginacao__1ReOC`,
	"active": `Paginacao_active__F+vUa`,
	"info": `Paginacao_info__X+htK`,
	"table_logins": `Paginacao_table_logins__oo3qa`
};
export default ___CSS_LOADER_EXPORT___;
