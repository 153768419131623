// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dialog_modal_mensage__7vvKb {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  background-color: rgba(0, 0, 0, 0.5);
}

.Dialog_box_mensage__T-88x {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 10px;
  min-width: 30%;
  min-height: 30%;
  max-height: 50%;
  overflow-y: auto;
}

.Dialog_dialog__EVgt1 {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Dialog_dialog__EVgt1 span {
  font-size: 1.1rem;
  padding: 10px;
  text-align: center;
}

.Dialog_buttons__9WL9t {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-around;
}

.Dialog_buttons__9WL9t Button {
  margin: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AlertDialog/Dialog.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,aAAa;EACb,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,uBAAuB;EACvB,aAAa;EACb,cAAc;EACd,eAAe;EACf,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,YAAY;AACd","sourcesContent":[".modal_mensage {\r\n  width: 100vw;\r\n  height: 100vh;\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  z-index: 1002;\r\n  background-color: rgba(0, 0, 0, 0.5);\r\n}\r\n\r\n.box_mensage {\r\n  position: absolute;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  border-radius: 5px;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  background-color: white;\r\n  padding: 10px;\r\n  min-width: 30%;\r\n  min-height: 30%;\r\n  max-height: 50%;\r\n  overflow-y: auto;\r\n}\r\n\r\n.dialog {\r\n  flex-direction: column;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.dialog span {\r\n  font-size: 1.1rem;\r\n  padding: 10px;\r\n  text-align: center;\r\n}\r\n\r\n.buttons {\r\n  width: 100%;\r\n  padding: 10px;\r\n  display: flex;\r\n  justify-content: space-around;\r\n}\r\n\r\n.buttons Button {\r\n  margin: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_mensage": `Dialog_modal_mensage__7vvKb`,
	"box_mensage": `Dialog_box_mensage__T-88x`,
	"dialog": `Dialog_dialog__EVgt1`,
	"buttons": `Dialog_buttons__9WL9t`
};
export default ___CSS_LOADER_EXPORT___;
