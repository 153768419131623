import React from 'react';
import { Page, Text, View, Link, Document, StyleSheet, Image,Font, PDFDownloadLink, PDFViewer, usePDF, pdf } from '@react-pdf/renderer'
import benefi from '../../assets/images/ri_1.png'
import finanImg from '../../assets/images/ri_2.png'
import inssImg from '../../assets/images/ri_5.png'
import qrAutentica from '../../assets/images/ri_14.jpeg'
import logoMConsulta from '../../assets/images/logo-mconsulta250x250.png'
//import {responsePdf} from './JsonExtrato'
import fontHelvetica from '../../assets/fonte/helvetica-bold.otf'
import { columnsEmprestimos, columnsCartoes } from './ColumsEmprestimosCartoes';
import { css } from './css';

Font.register({
  family: 'Helvetica',
  src: fontHelvetica,
});


const styles = StyleSheet.create(css)


export const ExtratoIn100Pdf = async (data) => {

  const responsePdf = data;

 

  const PdfDataTags = (
   <Document>  
      <Page size='A3' orientation='portrait' style={styles.page}>
          
          {/*<View style={styles.viewTopPdf} fixed>          
            <Text style={styles.topPdf}>Instituto Nacional do Seguro Social</Text>        
            </View>*/}
     
          <View style={styles.bodyPdf}>

          <View style={styles.title}>
            <Image style={{display:'flex', width:'10%', marginRight:'10px', alt:"Logo M-Consultas"}} src={logoMConsulta}/> 
            <Text style={styles.titleH3}>EXTRATO IN100</Text>           
          </View>

          <View style={styles.lineWidth} />
            
          <View style={styles.boxInfo}>
          

              <View style={styles.bodyBoxInfo}>

                  <View style={styles.headBoxInfo}>
                    <View>
                      <Image style={{display:'flex', width:'0.56in',height:'0.56in', marginRight:'10px'}} src={benefi}/>                                   
                    </View> 
                    <View>
                      <Text >Dados Pessoais</Text>
                    </View>
                  </View>

                {/** Benefício */}
                <View style={styles.containerBody}>
                <View style={styles.boxInfoBeneficio}>
                    <View style={styles.columnInfoBenef1}>
                      <Text style={styles.fontSizeBoxInfo}>N° Benefício: {responsePdf.cadastro.beneficio}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Nome: {responsePdf.cadastro.nome}</Text>
                      <Text style={styles.fontSizeBoxInfo}>CPF: {responsePdf.cadastro.cpf} </Text>
                      <Text style={styles.fontSizeBoxInfo}>Data de Nascimento: {responsePdf.cadastro.dataNascimento}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Idade: {responsePdf.cadastro?.idade} </Text>
                      <Text style={styles.fontSizeBoxInfo}>UF: {responsePdf.cadastro?.uf} </Text>                      
                      <Text style={styles.fontSizeBoxInfo}>Situação: {responsePdf.cadastro.situacaoBeneficio}</Text>
                      <Text style={styles.fontSizeBoxInfo}>DIB: </Text>
                      <Text style={styles.fontSizeBoxInfo}>DDB: {responsePdf.cadastro.ddb}</Text>
       
                    </View>

                    <View style={styles.columnInfoBenef2}>
                      <Text style={styles.fontSizeBoxInfo}>{ responsePdf.possuiProcurador}</Text>
                      <Text style={styles.fontSizeBoxInfo}>{ responsePdf.possuiRepresentante} </Text>
                      <Text style={styles.fontSizeBoxInfo}>{ responsePdf.pensaoAlimenticia} </Text>
                      <Text style={styles.fontSizeBoxInfo}>{ responsePdf.bloqueioEmprestimo} </Text>
                      <Text style={styles.fontSizeBoxInfo}>{ responsePdf.elegivelEmprestimo} </Text>
                    </View>
                  </View>                   
                </View>
                                

              </View>    

              <View style={styles.bodyBoxInfo}>

                  <View style={styles.headBoxInfo}>
                    <View>
                      <Image style={{display:'flex', width:'0.56in',height:'0.56in', marginRight:'10px'}} src={benefi}/>                                   
                    </View> 
                    <View>
                      <Text >Dados Representante Legal</Text>
                    </View>
                  </View>

                {/** Benefício */}
                <View style={styles.containerBody}>
                <View style={styles.boxInfoBeneficio}>
                    <View style={styles.columnInfoBenef1}>                      
                      <Text style={styles.fontSizeBoxInfo}>Nome: {responsePdf.cadastro.nomeRepresentante}</Text>
                      <Text style={styles.fontSizeBoxInfo}>CPF: {responsePdf.cadastro.cpfRepresentante} </Text>
                      
                    </View>
                    
                  </View>                   
                </View>
                                

              </View>                      

              <View style={styles.bodyBoxInfo} >

              <View style={styles.headBoxInfo}>
                    <View>
                      <Image style={{display:'flex', width:'0.56in',height:'0.56in', marginRight:'10px'}} src={finanImg}/>                                   
                    </View> 
                    <View>
                      <Text>DADOS BANCÁRIOS</Text>
                    </View>
                  </View>

                <View style={styles.containerBody}>
                    <View style={styles.boxInfoBeneficio}>
                    <View style={styles.columnInfoBenef1}>
                      <Text style={styles.fontSizeBoxInfo}>Número Agência: {responsePdf.dadosBancarios.agencia}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Nome Agência: {responsePdf.dadosBancarios.nomeBanco}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Número Conta Corrente: {responsePdf.dadosBancarios.conta}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Meio Pagamento: {responsePdf.dadosBancarios.meioPagamento}</Text>
                      <Text style={styles.fontSizeBoxInfo}>UF Conta: {responsePdf.dadosBancarios.ufConta}</Text>
                    </View>

                    </View>
                </View>
              </View>

                <View style={styles.bodyBoxInfo} >

                <View style={styles.headBoxInfo}>
                    <View>
                      <Image style={{display:'flex', width:'0.56in',height:'0.56in', marginRight:'10px'}} src={finanImg}/>                                   
                    </View> 
                    <View>
                      <Text>MARGENS | Qtd Emprestimos ativos/suspensos - {responsePdf.margem.quantidadeEmprestimo}</Text>
                    </View>
                </View>

                <View style={styles.containerBody}>
                    <View style={styles.boxInfoBeneficio}>
                    <View style={styles.columnInfoBenef1}>

                      <Text style={styles.fontSizeBoxInfo}>Margem Consignável: {responsePdf.margem?.margemDisponivelEmprestimo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                      <Text style={styles.fontSizeBoxInfo}>Margem Consignável Cartão: {responsePdf.margem?.margemDisponivelCartao.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text> 
                      <Text style={styles.fontSizeBoxInfo}>Margem Disponivel Cartão Beneficio: {responsePdf.margem?.margemCartaoBeneficio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>                      
                      <Text style={styles.fontSizeBoxInfo}>Valor Limite RMC: {responsePdf.margem?.valorLimiteRMC.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }</Text>
                      <Text style={styles.fontSizeBoxInfo}>Valor Limite RCC: {responsePdf.margem?.valorLimiteRCC.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                         
                    </View>

                    </View>
                </View>
              </View>       
          </View>
          
          </View>

      


          {/**Rodapé */}
          <View style={styles.footer} fixed>
      
            <View style={styles.lineWidth} />

              <View style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignContent:'center',}}>
                  
                  <View style={{display:'flex', flexDirection:'row',alignItems:'center'}}>                   

                  </View> 

                  <View style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                    <View>                    
                      
                      <Text style={{fontSize:'10pt', marginTop:'10px'}}>{new Date().toLocaleString() }</Text>

                    </View>

                  </View> 
               
            </View>

            
                
          </View>
          
      </Page> 
  </Document> 
  
  ) 
  //const [instance, updateInstance] = usePDF({document: PdfDataTags})
  //return await instance.url

  const blob =  await pdf(PdfDataTags).toBlob()

  const url = window.URL.createObjectURL(blob)
  window.open(url, '_blank')
};


