import { IUsuario } from "../interfaces/Usuario";

export class Usuario {
    constructor(IUsuario){
        this.active=IUsuario.active
        this.name=IUsuario.name
        this.user_name = IUsuario.user_name
        this.password=IUsuario.password
        this.idperfil=IUsuario.idperfil
        this.cpf=IUsuario.cpf
        this.email=IUsuario.email
        this.telefone=IUsuario.telefone
        this.usuarioAPi=IUsuario.usuarioAPi
        this.chaveApi=IUsuario.chaveApi
    }
}