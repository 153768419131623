import { useState } from "react"
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useQueryClient,
} from "react-query"
import { getListarCreditos } from "../../functions/manutencao_credito"
import { IAlert } from "../../interfaces/Alert"
import { Alert } from "../AlertDialog"
import styles from "./Dashboard.module.css"

const queryClient = new QueryClient()

export default function Dashboard() {
  return (
    <QueryClientProvider client={queryClient}>
      <DataDashboard />
    </QueryClientProvider>
  )
}

function DataDashboard() {
  const [lstCredito, setLstCredito] = useState([])
  const [onAlert, setOnAlert] = useState(IAlert)
  const [erroDashboard, setErroDashboard] = useState("")
  const queryClient = useQueryClient()

  const { isLoading, error, data } = useQuery(
    "repoData",
    async () => {
      const response = await getListarCreditos(1)
      setErroDashboard("")
      setLstCredito(response?.data.items)
    },
    {
      retry: 3,
      refetchOnWindowFocus: true,
      refetchInterval: 10000,
    },
  )

  if (isLoading) return "Loading..."
  if (error) {
    console.error(error)
    queryClient.invalidateQueries("repoData")
    return (
      <div>
        <p className="font-black text-2xl text-red-600">
          {error.response?.data.message}
        </p>
      </div>
    )
  }

  return (
    <div>
      {onAlert.visibled && (
        <Alert text={onAlert.message} onDialog={() => setOnAlert({})} />
      )}
      <div className={styles.titulo_dashboard}>
        <h2>Dashboard</h2>
        <span>Ultima atualização: {new Date().toLocaleString("pt-br")}</span>
      </div>

      <div className={styles.section_cards}>
        {lstCredito &&
          lstCredito.map(credito => (
            <div className={styles.card} key={credito.id}>
              <div className={styles.header_card}>
                <h2>{credito.desctpservico}</h2>
              </div>

              <div className={styles.body_card}>
                <p>Ultima recarga : {credito.qtdecreditos}</p>
                <p>Qtde utilizada : {credito.qtdeutilizada}</p>
                <p>Qtde restante : {credito.qtderestante}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
