import styles from "./PerfilUsuario.module.css"
import { useEffect, useState } from "react"

import { Buttons } from "../../components/Button"
import { InputText } from "../../components/Inputs"

import {
  getPerfil,
  postPerfil,
  delPerfil,
} from "../../functions/perfil_sistema"
import { getModulos } from "../../functions/modulos_sistema"

import { AlertDialog } from "../../components/AlertDialog"
import { FormModal } from "../../components/FormModal"
import Paginacao from "../../components/Paginacao"

export const PerfilUsuario = () => {
  const [perfil, setPerfil] = useState({ id: 0, descricao: "" })
  const [lstPerfil, setLstPerfil] = useState([])
  const [modalPerfil, setModalPerfil] = useState(false)
  const [dialogDelete, setDialogDelete] = useState({ visible: false, text: "" })
  const [idPerfil, setIdPerfil] = useState(0)
  const [number, setNumber] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 10,
  })

  useEffect(() => {
    getPerfil(currentPage).then(resp => {
      setLstPerfil(resp.data.items)
      setPagination(resp.data.pagination)
    })
  }, [number, currentPage])

  const handleSubmit = async e => {
    e.preventDefault()

    await postPerfil(perfil)
      .then(resp => {
        setModalPerfil(false)
        setNumber(number + 1)
      })
      .catch(erro => {
        console.log(erro)
      })
  }

  const handleDialogDelete = async value => {
    if (value) {
      await delPerfil(idPerfil)
        .then(resp => {
          setNumber(number + 1)
        })
        .catch(erro => {
          console.log(erro.esponse)
        })
    }

    setDialogDelete({ visible: false, text: "" })
  }

  const handlePerfil = e => {
    setPerfil({ ...perfil, [e.target.name]: e.target.value })
  }

  const handleDelete = async _id => {
    setIdPerfil(_id)

    setDialogDelete({
      visible: true,
      text: "Deseja realmente excluir este registro?",
    })
  }

  const handleEdit = async perf => {
    setPerfil(perf)
    setModalPerfil(true)
  }

  const handleNovo = () => {
    setPerfil({})
    setModalPerfil(true)
  }

  const handlePageChange = newPage => {
    //setVisibled(true)
    setCurrentPage(newPage)
  }

  return (
    <div className={styles.container_perfil}>
      {dialogDelete.visible && (
        <AlertDialog text={dialogDelete.text} onDialog={handleDialogDelete} />
      )}

      {modalPerfil && (
        <FormModal
          title="Cadastrar novo perfil"
          closeForm={() => setModalPerfil(false)}
          form={
            <form onSubmit={handleSubmit}>
              <div className={styles.dados_perfil}>
                <InputText
                  name="descricao"
                  text="Perfil"
                  required={true}
                  value={perfil.descricao}
                  handleonChange={handlePerfil}
                  placeholder="Informe um nome para o perfil..."
                />
              </div>

              <div>
                <Buttons text="Salvar" />
              </div>
            </form>
          }
        />
      )}

      <div className={styles.modulos_sistema}>
        <div className={styles.table_perfil}>
          <table>
            <thead>
              <tr>
                <th>Descrição</th>
                <th>...</th>
              </tr>
            </thead>
            <tbody>
              {lstPerfil.map(_perfil => (
                <tr key={_perfil.id}>
                  <td>{_perfil.descricao}</td>

                  <td className={styles.section_btn}>
                    <i
                      className="fa-solid fa-trash"
                      onClick={() => handleDelete(_perfil.id)}
                    ></i>
                    <i
                      className="fa-sharp fa-solid fa-pen-to-square"
                      onClick={() => handleEdit(_perfil)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className={styles.footer_page_profile}>
        <Buttons text="Novo Perfil" onClick={handleNovo} />
      </div>
      <Paginacao pagination={pagination} onPageChange={handlePageChange} />
    </div>
  )
}
