
import { api } from "../services/api";

export const createSession = async (user, password)=>{
   return api.post('/auth',{user, password})
}

export const getLogins = async (page ,active, filter) =>{   

    let options = {
        params:{
        page,
        active,
        filter
        }
    }
    return api.get("/login/listar",options)
}
  
export const updateLogin = async (data) =>{

    return api.put("/login/alterar", data)
}

export const deleteLogin = async (id) =>{

    return api.delete(`/login/deletar/${id}`)
}

export const postLogin = async (login)=>{   
   return api.post("/login/cadastrar", login);
}

export const postUsuario = async (usuario) =>{
    
    if(usuario.id > 0){
      return api.put("/usuario",usuario)
    }else{
      
      return api.post("/usuario",usuario)
    }
  }
  
export const getUsuarios = async (usuario) =>{
    
    return api.get("/usuario")
  
}
  
export const putPassword = async (userPwd)=>{
  
    return api.put("/login/alterarsenha",userPwd)
}

export const getlistAll_logins = async (filter = {})=>{

    const options = {
        params:{
            filter
        }
    }

    return await api.get("/login/listar", options )
}  

export const GerarChaveApi = async (usuario)=>{

    const options = {
        
        usuario
        
    }

    return await api.post("/login/autenticarApi", usuario )
} 

