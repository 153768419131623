export const IFornecedor = {  
    id:0,  
    descricao: "",
    cnpj: "",
    logradouro: "",
    numero: "",
    complemento: "",
    cep: "",
    cidade: "",
    estado: "",
    urlapi:"",
    apikey: "",
    ativo: false,
}