import React, { Component } from 'react';
import { Page, Text, View, Document, StyleSheet,PDFViewer } from '@react-pdf/renderer'
import { InputText } from '../../components/Inputs';

const styles = StyleSheet.create({
    page:{
        flexDirection:'row',
     
    },
    section:{
        margin:10,
        padding:10,
        flexGrow:1
    },

    section_emprestimos:{
        color: 'rgb(80, 80, 80)'
    },
    section_data: {
        color: 'rgb(80, 80, 80)'
    },
    
    section_margem:{
        border: '1px solid grey',
        padding: '10px'
    },
    section_emprestimos:{
        border: '1px solid grey',
        padding: '5px', 
        marginTopp: '10px'
    }    

})

export const SiapePdf = (data) =>{

    const dados = data.data;

    return ( 
        <div>

        <PDFViewer width="500" height="600">

            <Document>
                <Page size="A4" style={styles.page}>

                    <View style={styles.section}>

                        <fieldset className={styles.section_data}>
                            <Text>Dados Pessoais</Text>

                            <Text>Matricula : {dados.matricula_siape}</Text>
                            <Text>CPF : {dados.cpf}</Text>
                            
     
                        </fieldset>
                               
                    </View>

                    <View style={styles.section}>
                        <Text>Conteudo Sessão 2</Text>
                    </View>

                </Page>
            </Document>
        </PDFViewer>                
        </div>
           
    );

}
