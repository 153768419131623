// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormModal_form_modal__gOS7Z {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #3f3f3fd8;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.FormModal_head_form_modal__KooGF {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.FormModal_containe_modal__sLZQH {
  min-width: 20%;
  min-height: 20%;
  max-width: 90%;
  max-height: 90%;
  background-color: white;
  padding: 10px;
}
.FormModal_container_form__evS0T {
  overflow: auto;
  width: 100%;
  height: 90%;
}

.FormModal_head_form_modal__KooGF i {
  cursor: pointer;
  padding: 10px;
  font-size: 1.5rem;
}

.FormModal_head_form_modal__KooGF i:hover {
  background-color: #3f3f3f;
  color: white;
}

@media screen and (max-width: 700px) {
  .FormModal_containe_modal__sLZQH {
    width: 90%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/FormModal/FormModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,uBAAuB;EACvB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,cAAc;EACd,eAAe;EACf,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,cAAc;EACd,WAAW;EACX,WAAW;AACb;;AAEA;EACE,eAAe;EACf,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".form_modal {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100vw;\r\n  height: 100vh;\r\n  background-color: #3f3f3fd8;\r\n  z-index: 1000;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-direction: column;\r\n}\r\n\r\n.head_form_modal {\r\n  width: 100%;\r\n  background-color: white;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.containe_modal {\r\n  min-width: 20%;\r\n  min-height: 20%;\r\n  max-width: 90%;\r\n  max-height: 90%;\r\n  background-color: white;\r\n  padding: 10px;\r\n}\r\n.container_form {\r\n  overflow: auto;\r\n  width: 100%;\r\n  height: 90%;\r\n}\r\n\r\n.head_form_modal i {\r\n  cursor: pointer;\r\n  padding: 10px;\r\n  font-size: 1.5rem;\r\n}\r\n\r\n.head_form_modal i:hover {\r\n  background-color: #3f3f3f;\r\n  color: white;\r\n}\r\n\r\n@media screen and (max-width: 700px) {\r\n  .containe_modal {\r\n    width: 90%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_modal": `FormModal_form_modal__gOS7Z`,
	"head_form_modal": `FormModal_head_form_modal__KooGF`,
	"containe_modal": `FormModal_containe_modal__sLZQH`,
	"container_form": `FormModal_container_form__evS0T`
};
export default ___CSS_LOADER_EXPORT___;
