import { useEffect, useState } from "react"
import Moment from "react-moment"
import { useMutation, useQuery } from "react-query"
import { Alert, AlertDialog } from "../../components/AlertDialog"
import { Buttons } from "../../components/Button"
import { InputText } from "../../components/Inputs"
import { Loading } from "../../components/Loading"
import Paginacao from "../../components/Paginacao"
import { MyPDFComponent } from "../../components/PdfExtratoOnline/ReactToPdf"
import {
  ConsultarExtratoOnline,
  RefazerConsultaOnline,
  getSolicitacoesExtratoPdf,
} from "../../functions/consulta_online"
import styles from "./ExtratoOnline.module.css"

export const ExtratoOnline = () => {
  const [numBeneficio, setNumBeneficio] = useState("")
  const [listExtratosPdf, setListExtratosPdf] = useState([])
  const [onAlert, setOnAlert] = useState({})
  const [dialogOnline, setDialogOnline] = useState({})
  const [consulta, setConsulta] = useState({})
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 10,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    setShowLoading(true)
  }, [])

  const { isLoading, error, data, refetch, isFetching } = useQuery(
    ["repoData", currentPage],
    async () => {
      const response = await getSolicitacoesExtratoPdf(currentPage)
      return response?.data
    },
    {
      retry: 3,
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
      onSuccess: data => {
        setListExtratosPdf(data.items)
        setPagination(data.pagination)
        setShowLoading(false)
      },
      onError: () => {
        console.error(error)
        setShowLoading(false)
      },
    },
  )

  const createMutation = useMutation(ConsultarExtratoOnline, {
    onSuccess: response => {
      refetch()
      //setShowLoading(false)
    },
    onError: error => {
      setShowLoading(false)
      setOnAlert({
        visibled: true,
        message: error.response?.data.message || "Ocorreu um erro desconhecido",
      })
    },
  })

  const updateMutation = useMutation(RefazerConsultaOnline, {
    onSuccess: () => {
      //setShowLoading(false)
      refetch()
    },
    onError: error => {
      setShowLoading(false)
      console.error(error)
    },
  })

  const handleNumBeneficio = () => {
    setShowLoading(true)
    createMutation.mutate({ beneficio: numBeneficio })

    setNumBeneficio("")
  }

  const handlePageChange = newPage => {
    setShowLoading(true)
    setCurrentPage(newPage)
  }

  const handleSearchPdfOnline = async extratoOn => {
    setShowLoading(true)

    if (
      extratoOn.filePdf === null &&
      (extratoOn.jsonResult === null ||
        Object.keys(extratoOn.jsonResult).length === 0)
    ) {
      setShowLoading(false)
      return setOnAlert({
        visibled: true,
        message: "Arquivo não existe no servidor",
      })
    }

    if (
      extratoOn.filePdf != null &&
      (extratoOn.jsonResult === null ||
        Object.keys(extratoOn.jsonResult).length > 0)
    ) {
      // await handleDownloadFilePdf(extratoOn)
    } else if (
      extratoOn.filePdf === null &&
      (extratoOn.jsonResult != null ||
        Object.keys(extratoOn.jsonResult).length > 0)
    ) {
      //const json = extratoOn.id_fornecedor === 1 ? converter_json(extratoOn.jsonResult) : extratoOn.jsonResult;
      await MyPDFComponent(extratoOn.jsonResult)
    }

    setShowLoading(false)
  }

  const RefazerConsulta = async d => {
    if (d) {
      updateMutation.mutate({
        beneficio: consulta.beneficio,
        id: consulta.idExtrato,
      })
    }
    setDialogOnline({})
  }

  return (
    <div className={styles.container_extratoOnline}>
      {(isLoading || showLoading) && <Loading />}
      {onAlert.visibled && (
        <Alert text={onAlert.message} onDialog={() => setOnAlert({})} />
      )}
      {dialogOnline.visibled && (
        <AlertDialog text={dialogOnline.message} onDialog={RefazerConsulta} />
      )}
      <div className={styles.container_inputs}>
        <InputText
          type="number"
          required={true}
          text="Número Beneficio"
          value={numBeneficio}
          handleonChange={e => setNumBeneficio(e.target.value)}
        />
        <div className={styles.btn_buscar}>
          <Buttons text="Buscar" onClick={handleNumBeneficio} />
        </div>
      </div>
      <div className={styles.table_extrato}>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-50 uppercase bg-gray-800 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="p-4">
                Beneficio
              </th>
              <th scope="col" className="p-4">
                Data
              </th>
              <th scope="col" className="p-4">
                Serviço
              </th>
              <th scope="col" className="p-4">
                Usuário
              </th>
              <th scope="col" className="p-4" style={{ textAlign: "center" }}>
                Arquivo
              </th>
            </tr>
          </thead>
          <tbody>
            {listExtratosPdf &&
              listExtratosPdf.map((consulta, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-3 py-2">{consulta.beneficio}</td>
                  <td className="px-3 py-2">
                    <Moment
                      date={consulta.datahora}
                      format="DD/MM/YYYY HH:MM:ss"
                    />
                  </td>
                  <td className="px-3 py-2">{consulta.descricao}</td>
                  <td className="px-3 py-2">{consulta.name}</td>
                  <td className={styles.tdPdf}>
                    {parseInt(consulta.statusConsulta) === 0 ||
                    parseInt(consulta.statusConsulta) === 1 ||
                    parseInt(consulta.statusConsulta) === 4 ? (
                      <button
                        title={consulta.descStatusServ}
                        className={styles.btn_solicitar}
                      >
                        <i className="fa-solid fa-clock"></i>
                      </button>
                    ) : parseInt(consulta.statusConsulta) === 2 ? (
                      <button
                        title={`${consulta.descStatusServ} clique para imprimir`}
                        className={styles.btn_imprimir}
                        onClick={() => handleSearchPdfOnline(consulta)}
                      >
                        <i
                          className="fa-sharp fa-solid fa-file-pdf"
                          title="Baixar pdf"
                        ></i>
                      </button>
                    ) : parseInt(consulta.statusConsulta) === 3 ? (
                      <button
                        title={`${consulta.descStatusServ} clique para refazer a consulta`}
                        className={styles.btn_erro}
                        onClick={() => {
                          setDialogOnline({
                            visibled: true,
                            message: "Refazer Consulta",
                          })
                          setConsulta(consulta)
                        }}
                      >
                        <i className="fa-solid fa-triangle-exclamation"></i>
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Paginacao pagination={pagination} onPageChange={handlePageChange} />
    </div>
  )
}
