import { api } from "../services/api"

export const postBeneficio = async beneficio => {
  api.defaults.headers.tiposervico = 2

  return api.post("/desbloqueio-solicitar/cadastrar", beneficio)
}

export const getBeneficios = async (page, search) => {
  const options = {
    params: {
      page,
      search,
    },
  }

  return api.get(`/desbloqueio-solicitar/listar`, options)
}

export const searchBeneficio = async (search, page = 1) => {
  let options = {
    params: {
      search,
      page,
    },
  }
  return api.get(`/desbloqueio-solicitar/consultar`, options)
}

export const revisar = async data => {
  return api.put("/desbloqueio-solicitar/revisar", data)
}
