import { api } from "../services/api"

export const liberar = async (dados, tpserv) => {
  api.defaults.headers.tiposervico = 3

  return api.put(`/desbloqueio-liberar/liberar`, dados, {
    headers: { tiposervico: 3 },
  })
}

export const cancelarSolicitacao = async (dados, tpserv) => {
  api.defaults.headers.tiposervico = 3

  return api.put(`/desbloqueio-liberar/cancelar`, dados)
}

export const getBeneficiosDesbloqueio = async (
  page,
  filtro,
  exportarXlsx = 0,
) => {
  let options = {
    params: {
      page,
      filtro,
      exportarXlsx,
    },
  }
  return api.get(`/desbloqueio-liberar/listar`, options)
}
