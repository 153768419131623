// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table{
    width: 100%;      
    border-spacing: 0;     
}

table thead {    
    background-color: rgb(46, 46, 46);
    color: white;
}

table thead tr th {    
    font-size: 1.1rem;
    padding: 8px 0 8px 0;  
    text-align: left;
}

table tbody tr td {    
    font-size: 0.9rem;
    padding: 5px 0 5px 0; 
    border-bottom: 1px solid gray;     
    
}

table tbody tr:hover {    
    background-color: #161616;
    cursor: unset;
    color: white;
}

`, "",{"version":3,"sources":["webpack://./src/components/Table/Table.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,iCAAiC;IACjC,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,6BAA6B;;AAEjC;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,YAAY;AAChB","sourcesContent":["table{\r\n    width: 100%;      \r\n    border-spacing: 0;     \r\n}\r\n\r\ntable thead {    \r\n    background-color: rgb(46, 46, 46);\r\n    color: white;\r\n}\r\n\r\ntable thead tr th {    \r\n    font-size: 1.1rem;\r\n    padding: 8px 0 8px 0;  \r\n    text-align: left;\r\n}\r\n\r\ntable tbody tr td {    \r\n    font-size: 0.9rem;\r\n    padding: 5px 0 5px 0; \r\n    border-bottom: 1px solid gray;     \r\n    \r\n}\r\n\r\ntable tbody tr:hover {    \r\n    background-color: #161616;\r\n    cursor: unset;\r\n    color: white;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
