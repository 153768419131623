export const IUsuario = {  
    active:false,
    name:"",
    user_name:"",
    password:"",
    idperfil:"",
    cpf:"",
    email:"",
    telefone:"",
    usuarioAPi:false,
    chaveApi:""
}