import { useState } from 'react'
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query'

import styles from './Fornecedor.module.css'
import {Listar, Salvar} from '../../functions/fornecedor'
import {Loading} from '../../components/Loading'
import {Alert} from '../../components/AlertDialog'
import {FormModal} from '../../components/FormModal'
import {InputCheckBox, InputSelect, InputText} from '../../components/Inputs'
import { Estados } from '../../utils/Estados'
import { IFornecedor } from '../../interfaces/Fornecedor'
import { Buttons } from '../../components/Button'
import { IAlert } from '../../interfaces/Alert'
import { FormFornecedor } from './formFornecedor'

const Filter = {
    ativo:1,
    all:0
}

const optionsFilter = [
    {id:0, descricao:'Inativo'},
    {id:1, descricao:'Ativo'},
    {id:2, descricao:'Todos'}
]

export const Fornecedor = ()=>{
    const [alert, setAlert] = useState(IAlert)
    const [modalForn, setModaForn] = useState(false)
    const [fornecedor, setFornecedor] = useState(IFornecedor)
    const [filtro, setFiltro]=useState(Filter)

    const {data, isLoading, error } = useQuery(['fornecedor',filtro], async()=>{
        const {data} = await Listar(filtro);     
        return data  
    })
   
   
    const handleEdit = (fornce)=>{       
        setModaForn(true)
        setFornecedor(fornce)
    }

    const handleChange = (e)=>{        
        const { value } = e.target
        
        switch (parseInt(value)) {
            case 0:
              Filter.all = 0;
              Filter.ativo = 0;
              break;
            case 1:
                Filter.all = 0;
                Filter.ativo = 1;    
                break;
            case 2:
                Filter.all = 1; 
                Filter.ativo = 0;                               
              break;
            default:                
                Filter.all = 0;  
                Filter.ativo = 1;
          }

        setFiltro(Filter)
    }

    return (        
        <div className={styles.pageFornecedorList}>

           { isLoading && <Loading /> }         

           {alert.visible && <Alert text={alert.text} onDialog={()=>setAlert({})} />}
            <div className='h-11 p-2 flex content-end justify-between'>
                <div>
                    <Buttons 
                        text="Novo"
                        onClick={()=>{
                            setModaForn(true) 
                            setFornecedor({})}
                        }
                    />
                </div>

                <div>
                    <InputSelect 
                        handleOnChange={ (e)=>handleChange(e)}
                        options={optionsFilter}

                    />
                </div>
            </div>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-3">

                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-50 uppercase bg-gray-800 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="p-4">Descrição</th>
                            <th scope="col" className="p-4">CNPJ</th>
                            <th scope="col" className="p-4">Situação</th>
                            <th scope="col" className="p-4">...</th>                      
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            data && data.fornecedores.map((fornecedor)=>(
                                <tr key={fornecedor.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="px-3 py-2">{fornecedor.descricao}</td>
                                    <td className="px-3 py-2">{fornecedor.cnpj}</td>
                                    <td className="px-3 py-2">{fornecedor.ativo?"Ativo":"Inativo"}</td>
                                    <td className={styles.btnEdit} onClick={()=>handleEdit(fornecedor)} >
                                        <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                                    </td>                                 
                                </tr>
                            ))                                 
                        }
                    </tbody>
                </table>    
            </div>


                {modalForn &&
                    <FormModal 
                        title={"Cadastro Fornecedor"}
                        closeForm={()=>setModaForn(false)}
                        form={
                        <FormFornecedor 
                            fornecedorh={fornecedor} />                     
                        }
                    />
                }        
            
        </div>
    )
}