import React, { useEffect, useState } from "react";
import styles from './CadUsuario.module.css'

import {InputText,InputCheckBox,InputSelect} from '../../components/Inputs'
import { Buttons } from "../../components/Button";
import { getUsuarios, postUsuario, getLogins } from '../../functions/login';
import {FormModal} from "../../components/FormModal";
import {Alert} from "../../components/AlertDialog"


export const CadastroUsuario = ()=> {

    const [lstUsers, setLstUsers] = useState([])
    const [modalUser, setModalUser] = useState(false)
    const [modalLogins, setModalLogins] = useState(false)
    const [number,setNumber]=useState(0)
    const [lstLogins,setLstLogins] =useState([])
    const [usuario, setUsuario] = useState({
        id:0,
        nome:"",
        tipo_doc:0,
        documento:"",
        email:"",
        id_login:0,
        ativo:false
    })
    const [dialog, setDialog] = useState({visibled:false, message:""})

    const [dataLogin, setDataLogin]=useState({id:0, descricao:""})


    useEffect(()=>{
        (
            async()=>{
                await getUsuarios().then(res=>{  
                                                   
                    setLstUsers(res.data.usuarios)
                }).catch(error=>{
                    console.log(error)
                })
            }
        )()
    },[number])

    useEffect(()=>{
        getLogins(1,true,"").then(response=>{
                console.log(response.data.dados)
            let data = response.data.dados;
            let rows = []

            for(let i=0;i < data.length; i++){
                rows.push({
                    id:data[i].id,
                    name:data[i].name,
                    user_name:data[i].user_name
                })
                
            }
       
            setLstLogins(rows)
        })
        
    },[])

    const handleOnChange = (e)=>{   
    
       setUsuario({...usuario, [e.target.name]: e.target.name === "ativo"?e.target.checked: e.target.value  })
    }
    
    const handleSubmit = async(e)=>{
        e.preventDefault()
        
        await postUsuario(usuario).then(response=>{

            setUsuario({})
            setNumber(number + 1 )
            setModalUser(false)

        }).catch(error=>{
            
            console.error(error)
            setDialog({
                visibled:true,
                message:error.response.data.mensagem
            })
     
        })
        
    }

    const handleNewUser = ()=>{
        setUsuario({})
        setModalUser(true)
    }

    const handleEditUser = (u)=>{
        setUsuario(u)
        setModalUser(u)
    }

    const hanleSelectLogin = (log)=>{
       
        setDataLogin({
            id:log.id,
            descricao:log.user_name
        })
        setUsuario({...usuario,"id_login":log.id})
        setModalLogins(false)

    }
    
    return (
    <div className={styles.container_usuario}>
      
        <div className={styles.section_table_usuarios}>
            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Documento</th>
                        <th>E-mail</th>
                        <th>Ativo</th>
                        <th>...</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        lstUsers.map(_user=>(
                            <tr key={_user.id}>
                                <td>{_user.nome}</td>
                                <td>{_user.documento}</td>
                                <td>{_user.email}</td>
                                <td>                                                                       
                                    { _user.ativo ? <div className={styles.active} />:<div className={styles.inactive} />}                                    
                                </td>
                                <td className={styles.btn_edit} onClick={()=>handleEditUser(_user)}>                               
                                    <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>    
        
        <Buttons 
            text="Novo usuário"
            onClick={handleNewUser}
        />

        {modalUser && <FormModal 
            closeForm={()=>setModalUser(false)}
            title="Manutenção de usuários"
            
            form={
                <form onSubmit={handleSubmit} id="frm-usuario">

                 
                    <InputCheckBox 
                        text="Ativo"                    
                        name="ativo"                    
                        value={usuario.ativo}
                        handleonChange={handleOnChange}                                        
                    /> 


                    <InputText 
                        text="Nome"                    
                        name="nome"                    
                        value={usuario.nome}
                        handleonChange={handleOnChange}                        
                        placeholder="Digite o nome do cliente..."
                        required={true}
                    />
                 

                    <div className={styles.row}>
                        <InputSelect 
                            text="Tipo documento"
                            name="tipo_doc"
                            options={[{id:1,descricao:"CPF"}]}
                            handleOnChange={handleOnChange}
                            onValue={usuario.tipo_doc}
                            required={true}
                        />

                        <InputText                    
                            text="Documento"
                            type="number"
                            name="documento"                    
                            value={usuario.documento}
                            handleonChange={handleOnChange}
                            placeholder="Digite um cpf valido..."
                            required={true}
                        />
                    </div>


                    <InputText 
                        text="E-Mail"
                        type="email"
                        name="email"                    
                        value={usuario.email}
                        handleonChange={handleOnChange}
                        placeholder="Digite um e-mail valido..."
                        required={true}
                    />  
                    
                    <div className={styles.select_login} onClick={()=>setModalLogins(true)}>
                        <fieldset>
                            <legend>Login Sistema</legend>
                        
                            <InputText                             
                                name="id_login"
                                value={dataLogin.descricao}
                                readOnly = {true}
                                
                            /> 
                        </fieldset>
                        
                        
                    </div>


                <div className={styles.container_buttons}>

                    <Buttons 
                        text="Salvar"
                        
                    />

                </div>
                </form>
            }
        />}

       
        { modalLogins &&
            <FormModal 
                title="Selecione o login do usuário"
                closeForm={()=>setModalLogins(false)}
                form={
                    <div className={styles.section_table_usuarios}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Login</th>
                                    
                                </tr>
                            </thead>
                            <tbody className={styles.body_logis}>
                                {
                                    lstLogins.map(logs=>(
                                        <tr key={logs.id} onClick={()=>hanleSelectLogin(logs)}>
                                            <td>{logs.name}</td>
                                            <td>{logs.user_name}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                }
            />
        }    

        {dialog.visibled && <Alert 
            text={dialog.message}
            onDialog={()=>setDialog({visibled:false, message:""})}
        />}


       
    </div>
    )
}

