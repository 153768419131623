import { useEffect, useState } from "react"
import { Buttons } from "../../components/Button"
import { InputSelect, InputText } from "../../components/Inputs"
import styles from "./Credito.module.css"

import { Loading } from "../../components/Loading"
import Paginacao from "../../components/Paginacao"
import { getlistAll_logins } from "../../functions/login"
import {
  listarCredito,
  postAddServCliente,
  postCredit,
} from "../../functions/manutencao_credito"
import { getListTipoServico } from "../../functions/servicos"
import { IAlert } from "../../interfaces/Alert"
import { ICredito } from "../../interfaces/Credito"

const IUsuario = {
  id: 0,
  descricao: "",
}

const IFilterCredito = {
  idusu_cli: "",
  tiposervico: "",
  usuario: "",
}

export const ManutencaoCredito = () => {
  const [msgRetono, setMsgRetono] = useState("")
  const [loading, setLoading] = useState(false)
  const [usuarios, setUsuarios] = useState([IUsuario])
  const [tiposervico, setTipoServico] = useState([])
  const [lstCredito, setLstCredito] = useState([])
  const [onVisibled, setOnVisibled] = useState(false)
  const [page, setPage] = useState(1)
  const [qPages, setQPages] = useState(0)
  const [number, setNumber] = useState(0)
  const [modalManutCredito, setModalManutCredito] = useState(false)
  const [modalExcluCredito, setModalExcluCredito] = useState(false)
  const [modalAddServ, setModalAddServ] = useState(false)
  const [valorServico, setValorServico] = useState(0)
  const [onAlertDialog, setOnAlertDialog] = useState(IAlert)
  const [credito, setCredito] = useState(ICredito)
  const [filtro, setFiltro] = useState(IFilterCredito)
  const [filterUsers, setFilterUsers] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 10,
  })

  useEffect(() => {
    setLoading(true)
    handleListarFiltrarDados()
  }, [currentPage, number, filtro.usuario])

  useEffect(() => {
    getlistAll_logins(filterUsers).then(resp => {
      let users = []
      resp.data.items.map(usu => {
        users.push({
          id: usu.id,
          descricao: `${usu.name} | ${usu.user_name}`,
        })
      })

      setUsuarios(users)
    })
  }, [filterUsers])

  useEffect(() => {
    getListTipoServico().then(resp => {
      setTipoServico(resp.data.items)
    })
  }, [usuarios])

  const handleSetarFiltro = e => {
    setFiltro({ ...filtro, [e.target.name]: e.target.value })
  }

  const handleListarFiltrarDados = async () => {
    await listarCredito(currentPage, filtro)
      .then(resp => {
        setLoading(false)

        setLstCredito(resp.data.items)
        setPagination(resp.data.pagination)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  const handleChange = e => {
    if (e.target.name === "tiposervico") {
      const serv = tiposervico.find(c => c.id === parseInt(e.target.value))
      setValorServico(serv.valorVenda)
    }

    if (e.target.name === "idusu_cli") {
    }

    console.log(e.target)
    setCredito({ ...credito, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    if (credito.qtdeultimarecarga === 0) {
      setMsgRetono(`Informe um valor maior que "0"`)
      return
    }

    await postCredit(credito, 2)
      .then(resp => {
        setNumber(number + 1)
        setLoading(false)
        setCredito({})
        setModalManutCredito(false)
        setPage(1)
      })
      .catch(erro => {
        setLoading(false)
        console.log(erro)
      })
  }

  const handleSubmitAddServ = async e => {
    e.preventDefault()
    setMsgRetono("")
    setLoading(true)

    setLoading(false)

    await postAddServCliente(credito)
      .then(resp => {
        if (resp.data.erro) {
          setLoading(false)
          setMsgRetono(resp.data.message)
          return
        }

        setCredito({})
        setNumber(number + 1)
        setModalAddServ(false)
        setLoading(false)
      })
      .catch(erro => {
        setLoading(false)
        setMsgRetono("Erro ao realizar transação")
        console.log(erro)
      })
  }

  const handleNovoCredito = cred => {
    setMsgRetono("")
    setCredito({})
    setModalAddServ(true)
  }

  const SelectCredit = credit => {
    setCredito(credit)
    setModalManutCredito(true)
    //handleNew_Back(true, false)
  }

  const handlePageChange = newPage => {
    setLoading(true)
    setCurrentPage(newPage)
  }

  const handleDeleteCredit = async cred => {
    setMsgRetono("")
    setCredito(cred)
    setModalExcluCredito(true)
  }

  const handleExcluCredito = async () => {
    if (parseInt(credito.qtdeultimarecarga) > credito.qtderestante) {
      setMsgRetono(
        `Quantidade de crédito informada (${credito.qtdeultimarecarga}) superior ao atual (${credito.qtderestante})`,
      )
    } else {
      console.log(credito)

      await postCredit(credito, 1)
        .then(resp => {
          setNumber(number + 1)
          setLoading(false)
          setCredito({})
          setModalExcluCredito(false)
          setPage(1)
        })
        .catch(erro => {
          setLoading(false)
          console.log(erro)
        })
    }
  }

  return (
    <div className={styles.CCCcontainer_credito}>
      {loading && <Loading text="Salvando dados, aguarde..." />}

      <div className={styles.list_container}>
        <fieldset className={styles.container_search}>
          <legend>Filtrar Crédito</legend>

          <div className={styles.search_inputs}>
            <InputText
              type="search"
              text="Consultar usuário"
              name="usuario"
              placeholder="Digite o nome de usuário "
              handleonChange={handleSetarFiltro}
            />

            <InputSelect
              name="tiposervico"
              text="Tipo de Serviço"
              options={tiposervico}
              handleOnChange={handleSetarFiltro}
            />
          </div>

          <div className={styles.btnNew}>
            <Buttons text="Pesquisar" onClick={handleListarFiltrarDados} />
          </div>

          <div className={styles.btnNew}>
            <Buttons
              onClick={handleNovoCredito}
              text="Adicionar Serviço ao cliente"
            />
          </div>
        </fieldset>

        <table>
          <thead>
            <tr>
              <th>Cliente</th>
              <th>Tipo de serviço</th>
              <th>Ultima recarga</th>
              <th>Qtde utilizada</th>
              <th>Qtde restante</th>
              <th>Valor unit.(R$)</th>
              <th>Vendedor</th>
              <th>#</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {lstCredito.map((cred, index) => (
              <tr key={cred.id}>
                <td>{cred.cliente}</td>
                <td>{cred.desctpservico}</td>
                <td>{cred.qtdecreditos}</td>
                <td>{cred.qtdeutilizada}</td>
                <td>{cred.qtderestante}</td>
                <td>
                  {cred.valorun.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>
                <td>{cred.vendedor}</td>

                <td
                  onClick={() => SelectCredit(cred)}
                  title="Adicionar crédito ao cliente"
                >
                  <i className="fa-solid fa-arrow-right-arrow-left"></i>
                </td>

                <td
                  onClick={() => handleDeleteCredit(cred)}
                  title="Remover crédito do cliente"
                >
                  <i className="fa-sharp fa-solid fa-xmark"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {modalManutCredito && (
          <div className={styles.form_modal}>
            <div className={styles.containe_modal}>
              <div className={styles.head_form_modal}>
                <h2>Adicionar Crédito</h2>
                <i
                  className="fa-sharp fa-solid fa-xmark"
                  onClick={() => setModalManutCredito(false)}
                ></i>
              </div>

              <div className={styles.form_manut_credito}>
                <form onSubmit={handleSubmit}>
                  <div className={styles.inputs_add_credito}>
                    <div className={styles.inputMD_30}>
                      <InputText
                        text="Informe a Qtde de crédito"
                        type="number"
                        name="qtdeultimarecarga"
                        required={true}
                        handleonChange={handleChange}
                      />
                    </div>

                    <div className={styles.inputMD_50}>
                      <InputText
                        text="Tipo de Serviço"
                        value={credito.desctpservico}
                        handleonChange={handleChange}
                        readOnly={true}
                      />
                    </div>
                  </div>

                  <div className={styles.inputs_add_credito}>
                    <div className={styles.inputMD_20}>
                      <InputText
                        text="Ultima recarga"
                        name="qtdecreditos"
                        value={credito.qtdecreditos}
                        handleonChange={handleChange}
                        readOnly={true}
                      />
                    </div>

                    <div className={styles.inputMD_20}>
                      <InputText
                        text="Crédito Restante"
                        name="qtderestante"
                        value={credito.qtderestante}
                        handleonChange={handleChange}
                        readOnly={true}
                      />
                    </div>

                    <div className={styles.inputMD_30}>
                      <InputText
                        text="Créditos Utilizados"
                        name="qtdeutilizada"
                        value={credito.qtdeutilizada}
                        handleonChange={handleChange}
                        readOnly={true}
                      />
                    </div>

                    <div className={styles.inputMD_20}>
                      <InputText
                        text="Valor Unitário"
                        name="valorun"
                        value={new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(credito.valorun)}
                        handleonChange={handleChange}
                        readOnly={true}
                      />
                    </div>
                  </div>

                  <div className={styles.button_add_credito}>
                    <Buttons text="Salvar" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {modalExcluCredito && (
          <div className={styles.form_modal}>
            <div className={styles.containe_modal}>
              <div className={styles.head_form_modal}>
                <h3>Excluir Crédito</h3>

                <i
                  className="fa-sharp fa-solid fa-xmark"
                  onClick={() => setModalExcluCredito(false)}
                ></i>
              </div>

              <div>
                <div className={styles.secction_painel_excl}>
                  <span> Ultimo valor inserido : {credito.qtdecreditos}</span>
                  <span> Saldo atual : {credito.qtderestante}</span>
                </div>

                <InputText
                  type="number"
                  name="qtdeultimarecarga"
                  text="Informe a quantidade de crédito que deseja excluir"
                  handleonChange={handleChange}
                  required={true}
                />
              </div>

              <div className={styles.secction_painel_excl_retorno}>
                <span>{msgRetono}</span>
              </div>

              <div>
                <Buttons text="Gravar" onClick={handleExcluCredito} />
              </div>
            </div>
          </div>
        )}

        {modalAddServ && (
          <div className={styles.form_modal}>
            <div className={styles.containe_modal}>
              <div className={styles.head_form_modal}>
                <h2>Adicionar Serviço ao cliente</h2>

                <i
                  className="fa-sharp fa-solid fa-xmark"
                  onClick={() => {
                    setValorServico(0)
                    setModalAddServ(false)
                  }}
                ></i>
              </div>

              <div className={styles.form_manut_credito}>
                <form onSubmit={handleSubmitAddServ}>
                  <fieldset
                    style={{
                      padding: "10px",
                      border: "1px solid grey",
                      marginBottom: "10px",
                    }}
                  >
                    <legend>Filtrar Cliente</legend>

                    <InputText
                      handleonChange={e => setFilterUsers(e.target.value)}
                      type="search"
                      placeholder="Digite para pesquisar.."
                    />

                    <InputSelect
                      options={usuarios}
                      name="idusu_cli"
                      text="Selecione o cliente"
                      handleOnChange={handleChange}
                      required={true}
                    />
                  </fieldset>

                  <InputSelect
                    name="tiposervico"
                    text="Tipo de Serviço"
                    options={tiposervico}
                    handleOnChange={handleChange}
                    required={true}
                  />

                  <div className={styles.inputMD_20}>
                    <InputText
                      text="Valor Unitário"
                      value={new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(valorServico)}
                    />
                  </div>

                  <div className={styles.secction_painel_excl_retorno}>
                    <span>{msgRetono}</span>
                  </div>

                  <div className={styles.button_add_credito}>
                    <Buttons text="Salvar" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        <Paginacao pagination={pagination} onPageChange={handlePageChange} />
      </div>
    </div>
  )
}
