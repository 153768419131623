import { useEffect, useState } from "react"
import { getlistAll_logins } from "../../functions/login"
import { getExtratoConsultas } from "../../functions/relatorio_consultas"
import { getListTipoServico } from "../../functions/servicos"

import { Buttons } from "../../components/Button"
import { InputSelect, InputText } from "../../components/Inputs"
import { Loading } from "../../components/Loading"
import Paginacao from "../../components/Paginacao"
import styles from "./RelatorioConsultas.module.css"

export const RelatorioConsultas = () => {
  const [loading, setLoading] = useState(false)
  const [lstCredito, setLstCredito] = useState([])
  const [lstServico, setLstServico] = useState([])
  const [lstClientes, setLstClientes] = useState([])
  const [dadosSintetico, setDadosSintetico] = useState([])
  const [filtro, setFiltro] = useState({
    dataFim: "",
    dataIni: "",
    idusu_cli: "",
    tiposervico: "",
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 10,
  })

  useEffect(() => {
    ;(async () => {
      await extrato()
    })()
  }, [currentPage])

  useEffect(() => {
    ;(async () => {
      await getListTipoServico().then(resp => {
        setLstServico(resp.data.items)
      })
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      await getlistAll_logins().then(resp => {
        const clientes = []

        resp.data.items.forEach(data => {
          clientes.push({
            id: data.id,
            descricao: data.name,
          })
        })
        setLstClientes(clientes)
      })
    })()
  }, [])

  const extrato = async () => {
    setLoading(true)
    await getExtratoConsultas(currentPage, filtro)
      .then(res => {
        setDadosSintetico(res.data.sintetico)

        setLstCredito(res.data.items)
        setPagination(res.data.pagination)
        setLoading(false)
      })
      .catch(erro => {
        setLoading(false)
        console.log(erro)
      })
  }

  const handlePageChange = newPage => {
    setLoading(true)
    setCurrentPage(newPage)
  }

  const handleSetarFiltro = e => {
    setFiltro({ ...filtro, [e.target.name]: e.target.value })
  }

  const handleFilter = async () => {
    await extrato()
  }

  return (
    <div className={styles.CCCcontainer_extrato}>
      {loading && <Loading />}
      <div className={styles.list_extrato}>
        <div className={styles.container_search}>
          <fieldset>
            <legend>Filtros para consulta</legend>

            <div className={styles.region_filters}>
              <div className={styles.filter_data}>
                <InputText
                  text="Data Inicial"
                  name="dataIni"
                  type="date"
                  handleonChange={handleSetarFiltro}
                />

                <InputText
                  text="Data Final"
                  name="dataFim"
                  type="date"
                  handleonChange={handleSetarFiltro}
                />
              </div>

              <InputSelect
                name="tiposervico"
                text="Tipo de Serviço"
                options={lstServico}
                handleOnChange={handleSetarFiltro}
              />

              <Buttons text="Pesquisar" onClick={handleFilter} />
            </div>
          </fieldset>
        </div>

        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Data</th>
              <th>Qtde</th>
              <th>Tipo de Serviço</th>
              <th>Observação</th>
            </tr>
          </thead>
          <tbody>
            {lstCredito &&
              lstCredito.map(cred => (
                <tr
                  key={cred.id}
                  className={
                    cred.tipotransacao === 2
                      ? styles.line_credit
                      : styles.line_debit
                  }
                  title={cred.tipotransacao === 2 ? "Crédito" : "Débito"}
                >
                  <td title={cred.tipotransacao === 2 ? "Crédito" : "Débito"}>
                    <b>{cred.tipotransacao === 2 ? "C" : "D"}</b>
                  </td>

                  <td>
                    {new Date(
                      cred.datahora.replace("T", " ").replace("Z", ""),
                    ).toLocaleDateString("pt-br", {})}
                  </td>

                  <td>{cred.qtde}</td>
                  <td>{cred.descricao}</td>
                  <td>{cred.observacao}</td>
                </tr>
              ))}
          </tbody>
        </table>

        <Paginacao pagination={pagination} onPageChange={handlePageChange} />
      </div>
    </div>
  )
}
