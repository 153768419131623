import { useEffect, useState } from "react"
import { Buttons } from "../../components/Button"
import { InputCheckBox, InputSelect, InputText } from "../../components/Inputs"

import {
  GerarChaveApi,
  getLogins,
  postLogin,
  putPassword,
  updateLogin,
} from "../../functions/login"
import { getPerfil } from "../../functions/perfil_sistema"

import { Usuario } from "../../classes/Usuario"
import { Alert } from "../../components/AlertDialog"
import { FormModal } from "../../components/FormModal"
import { Loading } from "../../components/Loading"
import Paginacao from "../../components/Paginacao"
import { IAlert } from "../../interfaces/Alert"
import { IUsuario } from "../../interfaces/Usuario"
import styles from "./CadastroLogin.module.css"

const Login = () => {
  const [update, setUpdate] = useState(0)
  const [listLogins, setListLogins] = useState([])
  const [isShowCad, setShowCad] = useState(false)
  const [visibled, setVisibled] = useState(false)
  const [inputVisibled, setInputVisibled] = useState(true)

  const [active, setActive] = useState(1)
  const [page, setPage] = useState(1)
  const [qPages, setQPages] = useState(0)
  const [filter, setFilter] = useState("")
  const [formAlterPwd, setFormAlterPwd] = useState(false)
  const [btnAlterPwd, setBtnAlterPwd] = useState(false)
  const [alert, setAlert] = useState(IAlert)
  const [userLogin, setUserLogin] = useState(new Usuario(IUsuario))
  const [listPerfil, setListPerfil] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 10,
  })

  useEffect(() => {
    ;(async () => {
      await getPerfil()
        .then(resp => {
          setListPerfil(resp.data.items)
        })
        .catch(err => {
          console.error(err)
        })
    })()
  })

  useEffect(() => {
    setVisibled(true)
    getLogins(currentPage, active, filter)
      .then(res => {
        setListLogins(res.data.items)
        setPagination(res.data.pagination)
        setVisibled(false)
      })
      .catch(err => {
        console.error(err)
        setVisibled(false)
      })
  }, [currentPage, active, filter, update])

  const Save = async dados => {
    setUpdate(0)
    if (dados.id === undefined) {
      await postLogin(dados)
        .then(resp => {
          setListLogins(prevLogin => [...prevLogin, resp.data.usuario])
          setAlert({
            visibled: true,
            message: resp?.data.message,
          })

          setVisibled(false)
          handleBack()
        })
        .catch(error => {
          console.log(error)
          setVisibled(false)
          setAlert({
            visibled: true,
            message: error.response?.data.message,
          })
        })
    } else {
      await updateLogin(dados)
        .then(resp => {
          setAlert({
            visibled: true,
            message: resp?.data.message,
          })
          handleBack()
          setVisibled(false)
        })
        .catch(error => {
          console.log(error)
          setVisibled(false)
          setAlert({
            visibled: true,
            message: error.response?.data.message,
          })
        })
    }
  }

  const handleChange = e => {
    console.log(e.target.name)
    if (e.target.name === "active" || e.target.name === "usuarioAPi") {
      setUserLogin({ ...userLogin, [e.target.name]: e.target.checked })
    } else {
      setUserLogin({ ...userLogin, [e.target.name]: e.target.value })
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setVisibled(true)
    Save(userLogin)
  }

  const handleEditLogin = _login => {
    setUserLogin(_login)
    setInputVisibled(false)
    setShowCad(true)
    setBtnAlterPwd(true)
  }

  const handleNovo = () => {
    setInputVisibled(true)
    setBtnAlterPwd(false)
    setUserLogin(new Usuario(IUsuario))
    setShowCad(true)
  }

  const handleBack = () => {
    setUserLogin({
      active: false,
      name: "",
      password: "",
      idperfil: "",
    })
    setInputVisibled(true)
    setShowCad(false)
    setUpdate(1)
    setBtnAlterPwd(false)
  }

  const handleAlert = (message, visibled) => {
    setAlert({
      message,
      visibled,
    })
  }

  const handleActive = e => {
    console.log(e.target.value)
    const value = e.target.value === "" ? -1 : e.target.value
    setActive(value)
  }

  const handlePageChange = newPage => {
    setVisibled(true)
    setCurrentPage(newPage)
  }

  const handleFilter = e => {
    setFilter(e.target.value)
  }

  const handleAlterarSenha = async () => {
    if (userLogin.password != userLogin.confirm_password) {
      return setAlert({
        visibled: true,
        mensagem: "Senhas não conferem, verifique...",
      })
    }

    await putPassword(userLogin)
      .then(response => {
        setFormAlterPwd(false)
        setShowCad(false)
      })
      .catch(error => {
        console.log(error)
        setAlert({
          visibled: true,
          message: "Erro ao alterar senha",
        })
      })
  }

  const handleFormAlterarSenha = e => {
    e.preventDefault()
    setFormAlterPwd(true)
  }

  const gerarApiKey = async (e, userName) => {
    e.preventDefault()

    const apikey = await GerarChaveApi(userLogin)
    setUserLogin({ ...userLogin, ["chaveApi"]: apikey.data.token })

    setAlert({
      visibled: true,
      message: apikey.data.message,
    })
  }

  return (
    <div className={styles.container_login}>
      {alert.visibled && <Alert text={alert.message} onDialog={handleAlert} />}
      {visibled && <Loading />}

      <div className={styles.table_logins}>
        <div className={styles.filter_container}>
          <div className={styles.inputtext}>
            <InputText
              type="search"
              placeholder="Digite o nome ou login para consultar..."
              handleonChange={handleFilter}
            />
          </div>
          <div className={styles.inputselect}>
            <InputSelect
              options={[
                { id: 1, descricao: "Ativo" },
                { id: 0, descricao: "Inativo" },
              ]}
              handleOnChange={handleActive}
              onValue={active}
            />
          </div>

          <div className={styles.btn_novo_cad_login}>
            <Buttons text="Novo usuário" onClick={handleNovo} />
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <td>Nome</td>
              <td>Login</td>
              <td>Email</td>
              <td>Telefone</td>
              <td>Ativo</td>
              <td>...</td>
            </tr>
          </thead>
          <tbody>
            {listLogins.map(log => (
              <tr key={log.id}>
                <td>{log.name}</td>
                <td>{log.user_name}</td>
                <td>{log.email}</td>
                <td>{log.telefone}</td>
                <td>
                  {
                    <div
                      className={
                        log.active === 1 ? styles.active : styles.inactive
                      }
                      title={log.active === 1 ? "Ativo" : "Inativo"}
                    ></div>
                  }
                </td>
                <td onClick={() => handleEditLogin(log)}>
                  <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Paginacao pagination={pagination} onPageChange={handlePageChange} />
      </div>

      {isShowCad && (
        <FormModal
          title={"Cadastrar usuário"}
          closeForm={() => setShowCad(false)}
          form={
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
              <form onSubmit={handleSubmit}>
                <div>
                  <InputCheckBox
                    text="Ativo"
                    name="active"
                    handleonChange={handleChange}
                    value={userLogin.active}
                  />
                  <fieldset className="grid gap-4 sm:grid-cols-3 sm:gap-2 mb-5 border-solid border-2 p-2">
                    <legend className="mb-2 ">Dados Usuário</legend>

                    <div className="sm:col-span-2">
                      <InputText
                        text="Nome Completo"
                        name="name"
                        required={true}
                        type="text"
                        value={userLogin.name}
                        placeholder="Digite o nome completo do cliente..."
                        handleonChange={handleChange}
                      />
                    </div>

                    <InputText
                      text="Cpf"
                      type="text"
                      name="cpf"
                      value={userLogin.cpf}
                      handleonChange={handleChange}
                      placeholder="Digite um cpf valido..."
                    />

                    <InputText
                      text="Telefone"
                      type="text"
                      name="telefone"
                      value={userLogin.telefone}
                      handleonChange={handleChange}
                      placeholder="Digite um Nº telefone valido..."
                    />
                    <div className="sm:col-span-2">
                      <InputText
                        text="E-Mail"
                        type="email"
                        name="email"
                        value={userLogin.email}
                        handleonChange={handleChange}
                        placeholder="Digite um e-mail valido..."
                      />
                    </div>
                  </fieldset>

                  <fieldset className="grid gap-4 sm:grid-cols-1 sm:gap-2 mb-5 border-solid border-2 p-2">
                    <legend>Usúario API </legend>
                    <InputCheckBox
                      text="Usúario API"
                      name="usuarioAPi"
                      handleonChange={handleChange}
                      value={userLogin.usuarioAPi}
                    />

                    {userLogin.usuarioAPi ? (
                      <div>
                        <label
                          for="chaveApi"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          API Key
                        </label>
                        <textarea
                          id="chaveApi"
                          name="chaveApi"
                          rows="3"
                          readOnly="true"
                          handleOnChange={handleChange}
                          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mb-2"
                          placeholder="Clique no botão [Gerar Chave Api] para criar sua chave"
                          value={userLogin.chaveApi}
                        ></textarea>

                        <Buttons
                          text="Gerar Chave Api"
                          onClick={e => gerarApiKey(e, userLogin.user_name)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </fieldset>

                  <fieldset className=" mb-5 border-solid border-2 p-2">
                    <legend>Login Sistema</legend>

                    <div>
                      <div className="grid gap-4 sm:grid-cols-2 sm:gap-2 mb-2">
                        <InputSelect
                          required={true}
                          name="idperfil"
                          text="Perfil de Acesso"
                          options={listPerfil}
                          handleOnChange={handleChange}
                          onValue={userLogin.idperfil}
                        />

                        <InputText
                          text="Login"
                          name="user_name"
                          required={true}
                          type="text"
                          placeholder="Digite um login..."
                          handleonChange={handleChange}
                          value={userLogin.user_name}
                        />
                      </div>

                      {inputVisibled && (
                        <div className="mb-2">
                          <InputText
                            text="Senha"
                            name="password"
                            required={true}
                            type="password"
                            placeholder="Digite uma senha..."
                            handleonChange={handleChange}
                          />
                        </div>
                      )}
                    </div>

                    {btnAlterPwd && (
                      <Buttons
                        text="Alterar Senha"
                        onClick={handleFormAlterarSenha}
                      />
                    )}
                  </fieldset>
                </div>

                <Buttons text="Salvar" />
              </form>

              {formAlterPwd && (
                <FormModal
                  title="Alterar Senha"
                  closeForm={() => setFormAlterPwd(false)}
                  form={
                    <div>
                      <InputText
                        text="Senha"
                        name="password"
                        required={true}
                        type="password"
                        placeholder="Digite uma senha..."
                        handleonChange={handleChange}
                      />

                      <InputText
                        text="Confirme a senha"
                        name="confirm_password"
                        required={true}
                        type="password"
                        placeholder="Confirma a senha..."
                        handleonChange={handleChange}
                      />

                      <Buttons text="Alterar" onClick={handleAlterarSenha} />
                    </div>
                  }
                />
              )}
            </div>
          }
        />
      )}
    </div>
  )
}

export default Login
