// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Fornecedor_pageFornecedorList__nzppD{
    width: 100%; 
    
}

.Fornecedor_formCadFornecedor__WfA52{
    width: 70vw;
}

.Fornecedor_btnEdit__n1NfI{
    cursor: pointer;
}
/*
.pageFornecedorList table{
    width: 100%;
    padding: 10px;
    border-collapse: collapse;
}

.pageFornecedorList table thead{
    background-color: black;
    color: white;
    text-align: left;
}

.pageFornecedorList table thead th{
    padding: 10px 10px 10px 10px;
}

.pageFornecedorList table tbody td {
    border-bottom: 1px solid gray;
    padding: 10px 10px 10px 10px;
}
*/

.Fornecedor_pageFornecedorList__nzppD table tbody tr:hover {
    background-color: #3f3f3f;
    color: white;
}

@media screen and (max-width:700px) {
    .Fornecedor_formCadFornecedor__WfA52{
        width: 100%;
    }  
}`, "",{"version":3,"sources":["webpack://./src/pages/Fornecedor/Fornecedor.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;AAEf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;AACA;;;;;;;;;;;;;;;;;;;;;CAqBC;;AAED;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".pageFornecedorList{\r\n    width: 100%; \r\n    \r\n}\r\n\r\n.formCadFornecedor{\r\n    width: 70vw;\r\n}\r\n\r\n.btnEdit{\r\n    cursor: pointer;\r\n}\r\n/*\r\n.pageFornecedorList table{\r\n    width: 100%;\r\n    padding: 10px;\r\n    border-collapse: collapse;\r\n}\r\n\r\n.pageFornecedorList table thead{\r\n    background-color: black;\r\n    color: white;\r\n    text-align: left;\r\n}\r\n\r\n.pageFornecedorList table thead th{\r\n    padding: 10px 10px 10px 10px;\r\n}\r\n\r\n.pageFornecedorList table tbody td {\r\n    border-bottom: 1px solid gray;\r\n    padding: 10px 10px 10px 10px;\r\n}\r\n*/\r\n\r\n.pageFornecedorList table tbody tr:hover {\r\n    background-color: #3f3f3f;\r\n    color: white;\r\n}\r\n\r\n@media screen and (max-width:700px) {\r\n    .formCadFornecedor{\r\n        width: 100%;\r\n    }  \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageFornecedorList": `Fornecedor_pageFornecedorList__nzppD`,
	"formCadFornecedor": `Fornecedor_formCadFornecedor__WfA52`,
	"btnEdit": `Fornecedor_btnEdit__n1NfI`
};
export default ___CSS_LOADER_EXPORT___;
